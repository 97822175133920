import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { GlobalDataService } from 'src/app/services/global-data.service';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.css']
})
export class RegisterModalComponent implements OnInit {
  modalRef: NgbModalRef;
  showBusiness: boolean;

  constructor(
    public globalData: GlobalDataService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
    this.showBusiness = false;
  }

  openModal(registerChoiceModal): void {
    this.modalRef = this.modalService.open(
      registerChoiceModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    );
  }

  closeModal() {
    this.showBusiness = false;
    this.modalRef.close();
  }

  goToUserRegister() {
    this.modalRef.close();
    this.router.navigate(['/register']);
  }
}
