import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() navigationDisabled: boolean;
  @Input() toggleMe: boolean;
  @Input() expandMe: boolean;
  @Output() expandMeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    public user: UserService
  ) { }

  ngOnInit() {
  }

  goToSection(path: string): void {
    if (!this.navigationDisabled) {
      if (this.toggleMe) {
        this.expandMe = false;
        this.expandMeChange.emit(this.expandMe);
      }
      this.router.navigate([path]);
    }
  }

  isCurrentSection(path: string): boolean {
    return this.router.url.includes(path);
  }

  hasBusiness(): boolean {
    return  this.user.getCurrentUser() &&
            (this.user.isSuperAdmin() ||
            this.user.getBusinessSubscription() !== undefined);
  }
}
