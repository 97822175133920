<ng-template #registerChoiceModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-container *ngIf="!showBusiness">
      <div class="content">
        <div class="text-center">
          <h5>Estás interesado en una cuenta...</h5>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="goToUserRegister()">
          PANA PERSONAL
        </button>
        <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="showBusiness = true">
          PANA CORPORATIVO
        </button>
      </div>
    </ng-container>
    <app-business-contact class="d-flex flex-column flex-grow-1" *ngIf="showBusiness" [(showMe)]="showBusiness"></app-business-contact>
  </div>
</ng-template>

<div class="btn btn-pana-gradient-dark w-100" (click)="openModal(registerChoiceModal)">
  <div>
    NO TENGO CUENTA
  </div>
</div>
