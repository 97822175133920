import { InvoiceStatus as InvoiceStatusConstants } from 'src/app/constants/invoice-status.constant';

export class InvoiceStatus {
  public static KNOWN_BY_USERS = {
    [InvoiceStatusConstants.CREATED]: 'Creada',
    [InvoiceStatusConstants.PENDING]: 'Pendiente - Sin Pago',
    [InvoiceStatusConstants.PARTIAL]: 'Pendiente - Pago Parcial',
    [InvoiceStatusConstants.COMPLETED]: 'Completada',
    [InvoiceStatusConstants.EXCEEDED]: 'Completada',
    [InvoiceStatusConstants.CANCELLED]: 'Cancelada',
  };
}
