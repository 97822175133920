<div class="m-0 px-3 px-md-5 d-flex flex-grow-1">

  <!-- Google places input -->
  <input
      id="appGooglePlaces"
      appGooglePlaces
      *ngIf="!pending && !showLoader"
      (placeChange)="onPlaceChange($event)"
      type="text"
      class="form-control my-md-3 mx-2 mx-md-5 input-pana-darker input-pana-busqueda clickable"
      placeholder="Dirección"
      (keydown.enter)="$event"
      [ngClass]="globalData.ltMd() ? 'google-places-input mb-12' : 'md-google-places-input'">

  <!-- Reference point input -->
  <input *ngIf="!pending && !showLoader" type="text" [(ngModel)]="reference" (input)="onReferenceChange($event.target.value)"
    placeholder="Punto de referencia" class="form-control mx-2 mx-md-5 input-pana-darker" [ngClass]="globalData.ltMd() ? 'reference-input mb-9' : 'md-reference-input mt-6'">

  <!-- Address card -->
  <div *ngIf="!pending && !showLoader" class="mx-2 mx-md-5 pana-card py-2 py-sm-3" [ngClass]="globalData.ltMd() ? 'reference-input mb-6' : 'md-reference-input mt-9'">
    <div class="row m-0  text-center px-2 px-sm-3">
      <div class="col-12 p-0 d-flex align-items-center justify-content-center">
        <h5 class="mb-0">
          Dirección
        </h5>
      </div>
      <p class="m-0 b-0 mt-1" *ngIf="googleAddress && googleAddress != ''">
        {{ googleAddress }}
      </p>
      <p class="m-0 b-0 mt-1" *ngIf="!googleAddress || googleAddress == ''">
          Cargando...
        </p>
    </div>
  </div>

  <!-- Service information element -->
  <div *ngIf="pending && status && clientInfo" [ngClass]="globalData.ltMd() ? 'pending-info-box mb-3 mx-2' : 'md-pending-info-box mb-3 mx-5'">
    <app-generic-info-modal [minimizable]="true" [modalTitle]="status" [infoCardText]="infoCardText" [modalText]="modalText">
    </app-generic-info-modal>
  </div>

  <!-- Google map -->
  <agm-map class="col-sm-12 agm-map m-0 p-0" *ngIf="lat" backgroundColor="#1A2028" [latitude]="lat" [longitude]="lng"
    [zoom]="zoom" [mapDraggable]="!pending" [streetViewControl]="false" (mapReady)="onMapReady($event)" [usePanning]="true"
    (mapClick)="onChooseLocation($event)" (idle)="onMapIdle($event)" id="service-map"
    [styles]="
    [
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#494f60' } ]
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry',
        stylers: [ {   color: '#c617dc' }]
      },
      {
        featureType: 'administrative.locality',
        elementType: 'geometry',
        stylers: [{  color: '#c617dc'} ]
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [  { color: '#f1f2f2' } ]
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [  { color: '#f1f2f2' } ]
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'labels.text.fill',
        stylers: [  { color: '#494f60'  } ]
      },
      {
        featureType: 'landscape.natural',
        elementType: 'labels.text.fill',
        stylers: [{  color: '#494f60'},
        {lightness: '20'}  ]
      },
      {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry',
        stylers: [ { color: '#89f78d'  },
        {lightness: '20'} ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [ { color: '#666c7f' } ]
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#666c7f' } ]
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [ { color: '#89f78d'  },
        {lightness: '20'} ]

      },
      {
        featureType: 'poi.sports_complex',
        elementType: 'geometry.fill',
        stylers: [ { color: '#89f78d' },
        {lightness: '20'} ]
      },
      {
        featureType: 'poi.medical',
        elementType: 'geometry.fill',
        stylers: [ { color: '#f1f2f2' } ]
      },
      {
        featureType: 'poi.business',
        elementType: 'geometry.fill',
        stylers: [ {color: '#89f78d' },
        {lightness: '20'}]
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [  { color: '#dcddde'  } ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [ {  color: '#494f60' } ]
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [ { color: '#dcddde' } ]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#494f60' } ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#999999' } ]
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#666c7f' } ]
      },
      {
        featureType: 'transit.station.airport',
        elementType: 'labels.icon',
        stylers: [ { color: '#666c7f' }]
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [ { color: '#9cddef' } ]
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [ { color: '#999999' } ]
      }
    ]">
    <div class="center-marker"></div>
  </agm-map>

  <!-- Center Location Button -->
  <div [ngClass]="globalData.ltMd() ? 'current-location' : 'md-current-location'">
    <button type="button" class="btn btn-pana-gradient btn-circular" (click)="centerMap()" *ngIf="!pending && !showLoader"
      matTooltip="Ubícame" matTooltipPosition="above">
      <img src="/assets/mylocation.svg" height="15" width="15" />
    </button>
  </div>
</div>
