import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { NavigationService } from '../../services/navigation.service';
import { LoaderService } from '../../services/loader.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { StringsConstant } from '../../constants/strings.constant';
var SetPasswordComponent = /** @class */ (function () {
    function SetPasswordComponent(globalData, formBuilder, api, navigationService, loaderService, router, user) {
        this.globalData = globalData;
        this.formBuilder = formBuilder;
        this.api = api;
        this.navigationService = navigationService;
        this.loaderService = loaderService;
        this.router = router;
        this.user = user;
        this.matcher = new CustomErrorStateMatcher();
    }
    SetPasswordComponent.prototype.ngOnInit = function () {
        var userProfile = this.user.getProfile();
        /* Redirect if user is fully registered */
        if (userProfile.status_label !== 'Pre-Registrado') {
            this.router.navigate(['/service']);
        }
        /* Password setting form creation */
        this.setPasswordForm = this.formBuilder.group({
            oldPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),
            newPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),
            passwordConfirmation: new FormControl('', [
                Validators.required
            ])
        }, {
            validator: this.mismatchingPasswords
        });
        /**
         * Checks if request comes directly from login section
         * and gets the temporal password for further use
         */
        if (this.navigationService.params) {
            this.tempPass = this.navigationService.params.tempPass;
        }
        /* Sets oldPassword value if the temporal password exists */
        if (this.tempPass) {
            this.setPasswordForm.patchValue({
                oldPassword: this.tempPass
            });
            this.setPasswordForm.updateValueAndValidity();
        }
        this.cleanMessages();
    };
    Object.defineProperty(SetPasswordComponent.prototype, "f", {
        /**
         * Form's controls getter
         * @returns { [key: string]: AbstractControl; } - Form's controls hash
         */
        get: function () { return this.setPasswordForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * Performs password setting request to PANA's API.
     * Requests user's profile data again if succeeds.
     */
    SetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.cleanMessages();
        var result = Object.assign({}, this.setPasswordForm.value);
        var requestBody = {
            old_password: result.oldPassword,
            password: result.newPassword
        };
        this.loaderService.showLoader(true);
        this.api.changePassword(requestBody).subscribe(function (data) {
            _this.setPasswordForm.reset();
            _this.success = true;
            _this.requestProfile();
        }, function (error) {
            if (error.status === 422) {
                _this.errorMessage = error.error.errors.old_password;
                _this.loaderService.showLoader(false);
            }
        });
    };
    /**
     * Cleans error and success messages
     */
    SetPasswordComponent.prototype.cleanMessages = function () {
        this.errorMessage = undefined;
        this.success = false;
    };
    SetPasswordComponent.prototype.logout = function () {
        this.user.logout();
        this.router.navigate(['/login']);
    };
    /**
     * Class' form validator: checks if both new password and its confirmation
     * are the same.
     * @param setPasswordForm - Password setting form instance
     * @returns Object - null if passwords match, 'missmatching-pass' error otherwise.
     */
    SetPasswordComponent.prototype.mismatchingPasswords = function (setPasswordForm) {
        var pass1 = setPasswordForm.controls.newPassword.value;
        var pass2 = setPasswordForm.controls.passwordConfirmation.value;
        return pass1 === pass2 ? null : { 'missmatching-pass': true };
    };
    /**
     * Performs profile request to PANA's API.
     * Sets profile when success.
     */
    SetPasswordComponent.prototype.requestProfile = function () {
        var _this = this;
        this.api.getProfile().subscribe(function (data) {
            _this.user.setProfile(data);
            _this.loaderService.showLoader(false);
        }, function () {
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    return SetPasswordComponent;
}());
export { SetPasswordComponent };
