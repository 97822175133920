import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { UserStatus } from '../constants/user-status.constant';
import * as i0 from "@angular/core";
import * as i1 from "../services/user.service";
import * as i2 from "@angular/router";
var FullyRegisteredGuard = /** @class */ (function () {
    function FullyRegisteredGuard(user, router) {
        this.user = user;
        this.router = router;
    }
    FullyRegisteredGuard.prototype.canActivate = function (next, state) {
        if (UserStatus.PREREGISTERED === this.user.getProfile().status) {
            this.router.navigate(['/set-password'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    };
    FullyRegisteredGuard.ngInjectableDef = i0.defineInjectable({ factory: function FullyRegisteredGuard_Factory() { return new FullyRegisteredGuard(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: FullyRegisteredGuard, providedIn: "root" });
    return FullyRegisteredGuard;
}());
export { FullyRegisteredGuard };
