import { Component, OnInit, Input } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-generic-info-modal',
  templateUrl: './generic-info-modal.component.html',
  styleUrls: ['./generic-info-modal.component.css']
})
export class GenericInfoModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() modalText: string;
  @Input() infoCardText: string;
  @Input() buttonText = '';
  @Input() minimizable: boolean;

  isMinimized = true;
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openModal(genericInfoModal): void {
    this.modalRef = this.modalService.open(
      genericInfoModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2',
        backdrop: 'static'
      }
    );

    if (this.minimizable) {
      this.isMinimized = false;
    }
  }

  closeModal() {
    this.modalRef.close();

    if (this.minimizable) {
      this.isMinimized = true;
    }
  }

}
