import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { NavigationService } from 'src/app/services/navigation.service';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { StringsConstant } from '../../constants/strings.constant';
import { map, tap, last, catchError } from 'rxjs/operators';
import { HttpEventType, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-mass-load',
  templateUrl: './mass-load.component.html',
  styleUrls: ['./mass-load.component.css']
})
export class MassLoadComponent implements OnInit {
  form: FormGroup;
  businessTeam: any;
  uploadedFilename: string;
  validated: boolean;
  validUsers: any[];
  invalidUsers: any[];
  errorMessage: string;

  constructor(
    private navService: NavigationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.validated = false;
    if (this.navService.params) {
      this.businessTeam = this.navService.params.businessTeam;
      this.navService.params = undefined;

      this.form = this.formBuilder.group({
        usersXLSX: null
      });
    } else {
      this.router.navigate(['/user-management']);
    }
    this.errorMessage = undefined;
  }

  /**
   * Goes back to User Management Section with the current business team
   * as a navigation parameter
   */
  userManagement() {
    this.navService.params = { businessTeam: this.businessTeam };
    this.router.navigate(['/user-management']);
  }

  /**
   * Sets usersXLSX form field value from file widget change
   * @param event - file change event
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const re = /(?:\.([^.]+))?$/;

      if (re.exec(file.name)[1] === 'xlsx') {
        this.errorMessage = undefined;
        this.form.get('usersXLSX').setValue(file);
        this.uploadedFilename = file.name;
      } else {
        this.errorMessage = 'El archivo a importar debe ser de tipo xlsx';
      }
    }
  }

  /**
   * Performs form validation request to PANA's API
   * Sets both valid and invalid users and shows validation
   * results step
   */
  onSubmit() {
    this.errorMessage = undefined;
    const formModel = this.prepareSave();
    this.loaderService.showLoader(true);
    this.api.validateMassBusinessRegister(this.businessTeam.id, formModel)
    .subscribe(
      response => {
        this.validUsers = response.data.valid;
        this.invalidUsers = response.data.invalid;
        this.validated = true;
        this.loaderService.showLoader(false);
      },
      error => {
        console.log(error);
        if (error.status === 400) {
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        }
        this.loaderService.showLoader(false);
      }
    );
  }

  /**
   * Gets validation comments for a given user.
   * @param user - User validation data object
   * @returns validation comments string
   */
  getComments(user: any): string {
    return user.comments ? user.comments.join(', ') : 'Listo para la suscripción';
  }

  /**
   * Gets cancelation modal text based on the number of valid users
   * to subscribe.
   * @returns - Cancelation modal text
   */
  getCancelModalText(): string {
    if (this.validUsers.length === 1) {
      return this.validUsers.length ?
          'El usuario válido no será suscrito' :
          'Ningún usuario será suscrito';
    } else {
      return this.validUsers.length ?
          'Los usuarios válidos no serán suscritos' :
          'Ningún usuario será suscrito';

    }
  }

  /**
   * Clears form fields.
   */
  clearForm() {
    this.uploadedFilename = undefined;
    this.errorMessage = undefined;
    this.form.get('usersXLSX').setValue(undefined);
  }

  /**
   * Performs mass load sample file download request to PANA's API
   */
  getSample() {
    this.errorMessage = undefined;
    this.api.downloadMassLoadSample().subscribe(
          data => {
            /**
             * Creates an invisible <a> DOM object and clicks it
             * to start file download
             */
            const url = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Mis Empleados PANA.xlsm';
            a.click();

            window.URL.revokeObjectURL(url);
          },
          error => {
            this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            this.loaderService.showLoaderProgressbar(false, 'Done!', 100);
          }
        );
    return;
  }


  /**
   * Prepares file upload request to PANA's backend from an instance of
   * FormData class
   */
  private prepareSave(): any {
    const input = new FormData();
    input.append('usersXLSX', this.form.get('usersXLSX').value);
    input.append('usersXLSXname', this.form.get('usersXLSX').value.name);
    return input;
  }
}
