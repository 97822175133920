<ng-template #forgotPassModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="content">
      <div class="text-center mb-3">
        <p>Ingresa tu correo para restablecer tu contraseña</p>
      </div>
      <div class="w-100 text-center">
        <form>
          <mat-form-field class="centered w-100">
            <input matInput appAutofocus type="text" id="recoveryEmail" [pattern]="emailRegex" [(ngModel)]="recoveryEmail" placeholder="Email"
              [ngModelOptions]="{standalone: true}">
            <mat-error *ngIf="errorMessage">
              <div>
                {{ errorMessage }}
              </div>
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="text-center" *ngIf="successMessage">
        <p class="text-green m-0">
          {{ successMessage }}
        </p>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="serverError">
        <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
        <p class="=mt-1 text-center text-green margin-top">
          {{ serverError }}
        </p>
      </div>
    </div>
    <div class="text-center">
      <div 
        class="btn btn-pana-gradient-dark w-100 mx-3"
        (click)="sendRequest()" 
        *ngIf="!successMessage"
      >
        <div>
          RECUPERAR
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="btn btn-pana-gradient-dark w-100" (click)="openModal(forgotPassModal)">
  <div>
    OLVIDÉ MI CONTRASEÑA
  </div>
</div>