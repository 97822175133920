<div class="d-flex flex-column h-100 align-items-center vertical-scroll position-absolute w-100">
    <img src="/assets/logo-principal.png" height="90" width="74" class="d-inline-block align-top mt-5 mb-3" alt="">
    <div class="text-center mb-4 my-5">
      <ng-container *ngIf="!inPasswordStep">
        <h1 class="font-light mb-3">Inicia sesión</h1>
        <h5 class="font-light">para ingresar a la plataforma</h5>
      </ng-container>
      <ng-container *ngIf="inPasswordStep && !isPreregistered">
        <h1 class="font-light mb-5">Ahora introduce tu clave</h1>
      </ng-container>
      <ng-container *ngIf="inPasswordStep && isPreregistered">
        <h1 class="font-light mb-3">Bienvenido</h1>
        <h5 class="font-light">Ya estás registrado, sólo falta un paso <br> Ingresa el código que recibiste en tu correo</h5>
      </ng-container>
    </div>
    <div class="d-flex flex-column h-100 align-items-center position-relative w-100" id="login-container">
      <form [formGroup]="loginForm" class="d-flex flex-row form-group text-center position-absolute" id="login-form" auto-focus
        [ngClass]="{'first-step': loginFirstStep, 'second-step': loginSecondStep}">
          <div class="d-flex flex-column align-items-center justify-content-center form-child"
            [ngClass]="{'shown': emailInputShown}">
            <mat-form-field
              class="centered mb-4"
              [ngClass]="{
                'w-75': globalData.ltSm(),
                'w-60': !globalData.ltSm() && globalData.ltMd(),
                'w-50': !globalData.ltMd() && globalData.ltLg(),
                'w-35': !globalData.ltLg() && globalData.ltXl(),
                'w-25': !globalData.ltXl()
              }"
            >
              <input matInput appAutofocus type="text" formControlName="username" placeholder="Email" >
              <mat-error *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched)">
                <div *ngIf="loginForm.controls.username.errors.required">
                  Por favor, introduce tu correo
                </div>
                <div *ngIf="loginForm.controls.username.errors.pattern">
                  Por favor, introduce un correo válido
                </div>
              </mat-error>
            </mat-form-field>
            <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block justify-content-center">
              <app-register-modal class="w-100 mx-3"></app-register-modal>
              <button
                [type]="requestEmailSubmit"
                (click)="requestAppCredentials()"
                class="btn btn-pana-gradient w-100 mx-3"
                [disabled]="loginForm.controls.username.invalid"
              >
                CONTINUAR
              </button>
            </div>
          </div>
          <div class="d-flex flex-column form-child position-relative align-items-center"
            [ngClass]="{'shown': passwordInputShown}" >
            <ng-container *ngIf="!isPreregistered && inPasswordStep">
              <mat-form-field class="centered mb-4" [ngClass]="{
                  'w-75': globalData.ltSm(),
                  'w-60': !globalData.ltSm() && globalData.ltMd(),
                  'w-50': !globalData.ltMd() && globalData.ltLg(),
                  'w-35': !globalData.ltLg() && globalData.ltXl(),
                  'w-25': !globalData.ltXl()
                }"
              >
                <input matInput appAutofocus type="password" formControlName="password" placeholder="Contraseña" [errorStateMatcher]="matcher">
                <mat-error *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
                  <div *ngIf="loginForm.controls.password.errors.required">
                    Por favor, introduce tu contraseña
                  </div>
                </mat-error>
                <mat-error *ngIf="!(loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)) && errorMessage ">
                  <div>
                    {{ errorMessage }}
                  </div>
                </mat-error>
              </mat-form-field>
              <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block justify-content-center">
                <app-forgot-password class="w-100 mx-3"></app-forgot-password>
                <button [type]="requestPasswordSubmit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!loginForm.valid" (click)="requestLogin()">
                  INGRESAR
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="isPreregistered && inPasswordStep">
              <mat-form-field class="centered mb-4" [ngClass]="{
                  'w-75': globalData.ltSm(),
                  'w-60': !globalData.ltSm() && globalData.ltMd(),
                  'w-50': !globalData.ltMd() && globalData.ltLg(),
                  'w-35': !globalData.ltLg() && globalData.ltXl(),
                  'w-25': !globalData.ltXl()
                }"
              >
                <input matInput appAutofocus type="text" formControlName="password" placeholder="Código Provisional" [errorStateMatcher]="matcher" (focus)="temporaryPassMessage = undefined;">
                <mat-error *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
                  <div *ngIf="loginForm.controls.password.errors.required">
                    Por favor, introduce el código provisional
                  </div>
                </mat-error>
                <mat-error *ngIf="!(loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)) && errorMessage ">
                  <div>
                    {{ errorMessage }}
                  </div>
                </mat-error>
              </mat-form-field>
              <div class="w-100 text-center" style="min-height: 25px;">
                <p class="text-green" >
                  {{ temporaryPassMessage }}
                </p>
              </div>
              <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block justify-content-center">
                <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="requestTemporaryPassword()">
                    <div>
                      REENVIAR CÓDIGO
                    </div>
                </div>
                <button [type]="requestPasswordSubmit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!loginForm.valid" (click)="temPassLogin()">
                  INGRESAR
                </button>
              </div>
            </ng-container>
            <div class="text-center">
              <button type="button" class="btn btn-pana-link w-100 px-0" (click)="clearEmail()">
                {{ 'No soy ' + loginForm.get('username').value }}
              </button>
            </div>
          </div>
      </form>
    </div>
  </div>
