import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/* This service is intended to be used to pass data between not
  parent/child-related components since there's not a better way
  to do this in Angular 2+. I don't like it, though.
  If you know a better and less coupled way, please, use it. (GS) */
export class NavigationService {

  // Navigation disable values
  private disableNavigationSource = new BehaviorSubject(false);
  currentDisablingStatus$ = this.disableNavigationSource.asObservable();
  private _params: any;

  /**
   * Updates navigation disability status
   * @param disable - Boolean: Whether site navigation should disabled or not
   */
  disableStatus(disable: boolean) {
    this.disableNavigationSource.next(disable);
  }

  /**
   * Gets navigation params object
   * @returns - Object with navigation params values
   */
  get params(): any {
    return this._params;
  }

  /**
   * Sets navigation params object
   * @param params - Object with navigation params values
   */
  set params(params: any) {
    this._params = params;
  }

}
