import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringMap'
})
export class StringMapPipe implements PipeTransform {
  transform(value: string | number, mapping: {[key: string]: string; [key: number]: string}): string {
    if (value == null) {
      return '';
    }

    if (typeof mapping !== 'object') {
      throw Error(`InvalidStringMapPipeArgument: ${mapping}`);
    }

    if (mapping.hasOwnProperty(value)) {
      return mapping[value];
    }

    if (mapping.hasOwnProperty('other')) {
      return mapping['other'];
    }

    return '';
  }

}
