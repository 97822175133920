var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { GlobalDataService } from '../../services/global-data.service';
import { LoaderService } from '../../services/loader.service';
var ServiceMapComponent = /** @class */ (function () {
    function ServiceMapComponent(gapi, loaderService, globalData) {
        this.gapi = gapi;
        this.loaderService = loaderService;
        this.globalData = globalData;
        this.latChange = new EventEmitter();
        this.lngChange = new EventEmitter();
        this.googleAddressChange = new EventEmitter();
        this.referenceChange = new EventEmitter();
        this.mapFailedChange = new EventEmitter();
        this.markerIconUrl = {
            url: '/assets/panapin.png',
            scaledSize: {
                height: 35,
                width: 25
            }
        };
        this.zoom = 15;
        this.showInfoWindow = true;
    }
    ServiceMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaderService.showLoaderStatus$.subscribe(function (status) { return _this.showLoader = status; });
        this.gapi.load().then(function () {
            _this.geocoder = new google.maps.Geocoder();
            if (navigator.geolocation) {
                _this.getCurrentLocation(true);
            }
        });
    };
    ServiceMapComponent.prototype.ngOnChanges = function (changes) {
        var pendingChange = changes.pending;
        var clientInfoChange = changes.clientInfo;
        if (pendingChange) {
            // TODO: Quitar este if?
        }
        if (clientInfoChange) {
            if (clientInfoChange.currentValue) {
                this.modalText = '<h2 class="my-5">' + this.clientInfo.name + '</h2>' +
                    '<p class="m-0">' + this.clientInfo.email + '</p>' +
                    '<p class="m-0">' + this.clientInfo.document + '</p>' +
                    '<p class="m-0">' + this.clientInfo.phone + '</p>' +
                    '<p class="m-0 mb-5">' + this.clientInfo.department + '</p>' +
                    '<p>' + this.googleAddress + '</p>';
                this.infoCardText = '<p class="m-0">' + this.clientInfo.name + '</p>' +
                    '<p class="m-0">' + this.clientInfo.phone + '</p>' +
                    '<p class"m-0">' + this.googleAddress + '</p>';
                if (this.reference) {
                    this.modalText += '<p> Punto de referencia: ' + this.reference + ' </p>';
                    this.infoCardText += '<p> Punto de referencia: ' + this.reference + ' </p>';
                }
            }
        }
    };
    ServiceMapComponent.prototype.onMapReady = function (map) {
        this.map = map;
    };
    ServiceMapComponent.prototype.onMapIdle = function () {
        if (this.map) {
            var lat = this.map.center.lat();
            var lng = this.map.center.lng();
            var coords = {
                lat: lat,
                lng: lng
            };
            this.onPlaceChange(coords);
        }
    };
    ServiceMapComponent.prototype.onChooseLocation = function ($event) {
        this.onPlaceChange($event.coords);
    };
    ServiceMapComponent.prototype.onPlaceChange = function (coords) {
        if (!this.loadingLocation && !this.pending) {
            this.updateLocation(coords);
            this.map.setCenter({ lat: this.lat, lng: this.lng });
        }
    };
    ServiceMapComponent.prototype.onReferenceChange = function (newReference) {
        this.reference = newReference; // This may be redundant
        this.referenceChange.emit(this.reference);
    };
    ServiceMapComponent.prototype.centerMap = function () {
        var _this = this;
        navigator.geolocation.getCurrentPosition(function (result) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // We get the current user's location in order to
                // center the map there (by default) in case there isn't a pending request
                if (this.lng !== result.coords.longitude || this.lat !== result.coords.latitude) {
                    this.getAddressFromCoords(result.coords.latitude, result.coords.longitude);
                }
                this.lng = result.coords.longitude;
                this.lat = result.coords.latitude;
                this.latChange.emit(this.lat);
                this.lngChange.emit(this.lng);
                this.map.setCenter({ lat: this.lat, lng: this.lng });
                this.loaderService.showLoader(false);
                return [2 /*return*/];
            });
        }); }, function () {
            _this.loaderService.showLoader(false);
            _this.mapFailed = true;
            _this.mapFailedChange.emit(_this.mapFailed);
        });
    };
    ServiceMapComponent.prototype.getCurrentLocation = function (onInit) {
        var _this = this;
        navigator.geolocation.getCurrentPosition(function (result) {
            // We get the current user's location in order to
            // center the map there (by default) in case there isn't a pending request
            if (!onInit || (!_this.lat && !_this.lng)) {
                _this.lng = result.coords.longitude;
                _this.lat = result.coords.latitude;
                _this.latChange.emit(_this.lat);
                _this.lngChange.emit(_this.lng);
            }
            _this.getAddressFromCoords(_this.lat, _this.lng);
            _this.loaderService.showLoader(false);
        }, function () {
            var lat = 10.5035003;
            var lng = -66.8602066;
            var coords = {
                lat: lat,
                lng: lng
            };
            _this.updateLocation(coords);
            _this.loaderService.showLoader(false);
        });
    };
    ServiceMapComponent.prototype.updateLocation = function (coords) {
        this.lat = coords.lat;
        this.lng = coords.lng;
        this.latChange.emit(this.lat);
        this.lngChange.emit(this.lng);
        this.getAddressFromCoords(coords.lat, coords.lng);
    };
    ServiceMapComponent.prototype.loaded = function () {
        this.loadingLocation = false;
        this.showInfoWindow = true;
    };
    ServiceMapComponent.prototype.loading = function () {
        this.loadingLocation = true;
        this.showInfoWindow = false;
        this.googleAddress = '';
    };
    ServiceMapComponent.prototype.getAddressFromCoords = function (lat, lng) {
        var _this = this;
        this.loading();
        this.geocoder.geocode({
            'location': {
                'lat': lat,
                'lng': lng
            }
        }, function (results, status) {
            _this.loaded();
            if (status === google.maps.GeocoderStatus.OK) {
                var result = results[0];
                _this.googleAddress = result.formatted_address;
                _this.googleAddressChange.emit(_this.googleAddress);
            }
            else {
                console.log('Error - ', results, ' & Status - ', status);
            }
        });
    };
    return ServiceMapComponent;
}());
export { ServiceMapComponent };
