import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private showLoaderSource = new BehaviorSubject(false);
  private showLoaderSourceProgressbar = new BehaviorSubject(false);

  showLoaderStatus$ = this.showLoaderSource.asObservable();
  showLoaderStatusProgressbar$ = this.showLoaderSourceProgressbar.asObservable();

  percentLoader: number;
  loaderMessage: string;


  /**
   * Determines wheter loader should be shown or not
   *
   * @param show - Whether loader should be shown or not
   */
  showLoader(show: boolean): void {
    this.showLoaderSource.next(show);
  }

  // TODO: Check if the function can be refactored
  /**
   * Determines wheter progress bar loader should be shown or not.
   *
   * @param show - Wheter loader should be shown or not
   * @param message The message to be displayed in the loader component
   * @param percent The percentage of the progress bar to be displayed
   */
  showLoaderProgressbar(show: boolean, message?: string, percent?: number): void {
    this.showLoaderSourceProgressbar.next(show);
    this.percentLoader = percent;
    this.loaderMessage = message;
  }

}
