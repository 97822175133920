import { Validators } from '@angular/forms';

export class User {
  first_name = '';
  last_name = '';
  email = '';
  type_document = 'V';
  document = '';
  phone = '';
  role = '';
  department = '';
  callCode = '+58';

  getValidators(field: string) {
    // tslint:disable-next-line:max-line-length
    const nameRegex = /^[áéíóúÁÉÍÓÚñÑa-zA-Z,\.'\-\ ]+$/;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const callCodeRegex = /^\+[0-9]{1,4}$/;
    const phoneRegex = /^[0-9]{10}$/;
    const validators = [];

    if (field !== 'department') {
      validators.push(Validators.required);
    }
    if (field === 'first_name' || field === 'last_name') {
      validators.push(Validators.pattern(nameRegex));
    }
    if (field === 'email') {
      validators.push(Validators.pattern(emailRegex));
    } else if (field === 'phone') {
      validators.push(Validators.pattern(phoneRegex));
    } else if (field === 'call_code') {
      validators.push(Validators.pattern(callCodeRegex));
    }

    return validators;
  }
}
