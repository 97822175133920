<ng-template #corporativeContactModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <app-business-contact class="d-flex flex-column flex-grow-1" [title]="'Buena idea'" (showMeChange)="closeModal()">
    </app-business-contact>
  </div>
</ng-template>

<button class="btn btn-pana-gradient w-100"
  (click)="openCorpContactModal(corporativeContactModal)">
  {{ 'contáctanos' | uppercase }}
</button>
