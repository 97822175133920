<ng-template #changePasswordModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="content">
      <div class="w-100 text-center" *ngIf="!success">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
          <!-- Actual Password -->
          <mat-form-field class="centered w-100">
            <input matInput appAutofocus type="password" formControlName="oldPassword" placeholder="Contraseña Actual" [maxlength]="32">
            <mat-error *ngIf="f.oldPassword.invalid && (f.oldPassword.dirty || f.oldPassword.touched)">
              <div *ngIf="f.oldPassword.errors.minlength">
                La contraseña debe tener al menos 8 caracteres
              </div>
              <div *ngIf="f.oldPassword.errors.required">
                Por favor, introduce tu contraseña actual
              </div>
            </mat-error>
          </mat-form-field>
          <!--New Password -->
          <mat-form-field class="centered w-100">
            <input matInput type="password" formControlName="newPassword" placeholder="Contraseña Nueva" [maxlength]="32">
            <mat-error *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)">
              <div *ngIf="f.newPassword.errors.minlength">
                La contraseña debe tener al menos 8 caracteres
              </div>
              <div *ngIf="f.newPassword.errors.required">
                Por favor, introduce tu contraseña nueva
              </div>
            </mat-error>
          </mat-form-field>
          <!-- Confirm new password-->
          <mat-form-field class="centered w-100">
            <input matInput type="password" formControlName="passwordConfirmation" placeholder="Confirmación de Contraseña"
            [errorStateMatcher]="matcher" [maxlength]="32">
            <mat-error *ngIf="changePasswordForm.hasError('missmatching-pass') && (f.passwordConfirmation.dirty || f.passwordConfirmation.touched)">
              <div>
                Tu contraseña no coincide
              </div>
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div class="w-100 text-center" *ngIf="success">
        <img src="/assets/ecommerce.svg" width="70" height="70" class="d-inline-block align-top mx-sm-2 " alt="">
        <p class="m-top">Contraseña cambiada exitosamente</p>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
        <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
        <p class="text-green mt-1 text-center">
          Contraseña antigua no coincide
        </p>
      </div>
    </div>
    <div class="text-center">
      <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()" *ngIf="!success" >
        <div>
          CANCELAR
        </div>
      </div>
      <button type="submit" class="btn btn-pana-gradient w-100" [disabled]="!changePasswordForm.valid" *ngIf="!success" (click)="onSubmit()">
        ACEPTAR
      </button>
      <button class="btn btn-pana-gradient w-100" (click)="closeModal(true)" *ngIf="success">
        CERRAR
      </button>
    </div>
  </div>
</ng-template>

<div class="btn btn-pana-gradient-dark w-100" (click)="openModal(changePasswordModal)">
  <div>
    {{ 'Cambiar contraseña' | uppercase }}
  </div>
</div>
