import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(input: any, from: any, to: any): any {
    // Verificamos que el input sea pasado correctamente
    // o le cargamos datos por defecto
    input = input || '';

    // Reemplazamos el caracter con Regex
    const regex = new RegExp(from, 'g');
    return input.replace(from, to);
  }

}
