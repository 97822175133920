/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./business-dropdown.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./business-dropdown.component";
import * as i5 from "../../services/api.service";
import * as i6 from "../../services/loader.service";
var styles_BusinessDropdownComponent = [i0.styles];
var RenderType_BusinessDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusinessDropdownComponent, data: {} });
export { RenderType_BusinessDropdownComponent as RenderType_BusinessDropdownComponent };
function View_BusinessDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "dropdown-element d-flex align-items-center justify-content-between"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectCompany(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "my-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h6", [["class", "my-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ID ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.display_name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.document; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.id; _ck(_v, 9, 0, currVal_2); }); }
function View_BusinessDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BusinessDropdownComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showCompany(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BusinessDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dropdown-no-match px-3 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-center default-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No hay ninguna empresa que coincida con su busqueda "]))], null, null); }
function View_BusinessDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dropdown-no-match px-3 d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "text-center default-cursor"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No se ha podido obtener ninguna empresa del servidor "]))], null, null); }
function View_BusinessDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "dropdown-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BusinessDropdownComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BusinessDropdownComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BusinessDropdownComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.companies; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.noMatchResults(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.serverError; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_BusinessDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "drop-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "input", [["class", "input-pana-darker dropdown-input input-pana-busqueda"], ["placeholder", "Buscar empresa"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.criteria = $event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.toogleDropDown() !== false);
        ad = (pd_5 && ad);
    } if (("input" === en)) {
        var pd_6 = (_co.showDropdown() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "dropdown-input-expanded": 0 }), i1.ɵdid(5, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(7, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(9, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BusinessDropdownComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "input-pana-darker dropdown-input input-pana-busqueda"; var currVal_8 = _ck(_v, 4, 0, _co.showDropDown); _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_9 = _co.criteria; _ck(_v, 7, 0, currVal_9); var currVal_10 = _co.showDropDown; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_BusinessDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business-dropdown", [], null, null, null, View_BusinessDropdownComponent_0, RenderType_BusinessDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i4.BusinessDropdownComponent, [i5.ApiService, i6.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BusinessDropdownComponentNgFactory = i1.ɵccf("app-business-dropdown", i4.BusinessDropdownComponent, View_BusinessDropdownComponent_Host_0, { company: "company" }, { companyChange: "companyChange" }, []);
export { BusinessDropdownComponentNgFactory as BusinessDropdownComponentNgFactory };
