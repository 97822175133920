import { InvoiceStatus as InvoiceStatusConstants } from 'src/app/constants/invoice-status.constant';
var InvoiceStatus = /** @class */ (function () {
    function InvoiceStatus() {
    }
    InvoiceStatus.KNOWN_BY_USERS = (_a = {},
        _a[InvoiceStatusConstants.CREATED] = 'Creada',
        _a[InvoiceStatusConstants.PENDING] = 'Pendiente - Sin Pago',
        _a[InvoiceStatusConstants.PARTIAL] = 'Pendiente - Pago Parcial',
        _a[InvoiceStatusConstants.COMPLETED] = 'Completada',
        _a[InvoiceStatusConstants.EXCEEDED] = 'Completada',
        _a[InvoiceStatusConstants.CANCELLED] = 'Cancelada',
        _a);
    return InvoiceStatus;
}());
export { InvoiceStatus };
var _a;
