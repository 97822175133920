import { SubscriptionsConstant } from '../constants/subscriptions.constant';
import * as i0 from "@angular/core";
var UserService = /** @class */ (function () {
    function UserService() {
    }
    /**
     * Sets app token in local storage
     * @param appToken
     */
    UserService.prototype.setAppToken = function (appToken) {
        localStorage.setItem('appToken', appToken);
    };
    /**
     * Sets users credentials in local storage
     * @param username
     * @param accessToken
     */
    UserService.prototype.setCredentials = function (username, accessToken) {
        localStorage.setItem('currentUser', JSON.stringify({
            username: username,
            token: accessToken
        }));
    };
    /**
     * Checks if current user is logged.
     * @returns - Boolean: whether current user is logged or not
     */
    UserService.prototype.isLogged = function () {
        return localStorage.getItem('currentUser') !== null;
    };
    /**
     * Sets current user profile
     * @param profile - UserProfile: object with user data
     */
    UserService.prototype.setProfile = function (profile) {
        var currentUser = this.getCurrentUser();
        currentUser.profile = profile;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    };
    /**
     * Gets current user's profile.
     * @returns - UserProfile: object with current user data
     */
    UserService.prototype.getProfile = function () {
        var currentUser = this.getCurrentUser();
        return currentUser.profile;
    };
    /**
     * Gets user id from profile
     * @returns - Number: user id
     */
    UserService.prototype.getId = function () {
        var currentProfile = this.getProfile();
        return currentProfile.id;
    };
    /**
     * Gets current user data
     * @returns - Object with current user data
     */
    UserService.prototype.getCurrentUser = function () {
        return JSON.parse(localStorage.getItem('currentUser'));
    };
    /**
     * Gets current user roles
     * @returns - UserRol[]: array with all user's roles data
     */
    UserService.prototype.getUserRoles = function () {
        var currentUser = this.getCurrentUser();
        var userRoles = currentUser.profile ? currentUser.profile.roles : null;
        return userRoles;
    };
    /**
     * Performs user logout
     */
    UserService.prototype.logout = function () {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('appToken');
    };
    /**
     * Checks whether current user has dependents or not
     * @returns - Boolean: Whether current user has dependents or not
     */
    UserService.prototype.hasDependents = function () {
        return (this.isSuperAdmin() || this.dependentTeams().length > 0);
    };
    /**
     * Gets all dependent teams from user's roles
     * @returns - UserRol[]: Array with dependent teams roles
     */
    UserService.prototype.dependentTeams = function () {
        return this.getUserRoles().filter(this.isDependent);
    };
    /**
     * Gets user's business subscriptions if exists
     *
     * TODO: Change name of function to be more mnemonic
     *
     * @returns - UserRol: user business team
     */
    UserService.prototype.getBusinessSubscription = function () {
        var businessTeam = this.getProfile().roles.find(function (role) {
            var team = role.team;
            return team ? team.type_label === SubscriptionsConstant.BUSINESS : false;
        });
        return businessTeam;
    };
    /**
     * Checks whether current user has a role in which is admin
     * @returns - Boolean: whether current user has a role in which is admin
     */
    UserService.prototype.isAdmin = function () {
        var businessRole = this.getBusinessSubscription();
        return businessRole && businessRole.name === 'admin';
    };
    /**
     * Checks whether current user is super admin
     * @returns - Boolean: whether current user is super admin
     */
    UserService.prototype.isSuperAdmin = function () {
        var roles = this.getUserRoles();
        var isSuperAdmin = roles.some(function (role) {
            return role.name === 'super_admin';
        });
        return isSuperAdmin;
    };
    /**
     * Checks whether current user has an active subscription or not
     * @returns - Boolean: whether current user has an active subscription or not
     */
    UserService.prototype.hasActiveSubscription = function () {
        var userProfile = this.getProfile();
        var userSubscriptions = userProfile.subscriptions;
        var currentDate = new Date();
        var activeSubscriptions = userSubscriptions.filter(function (subscription) {
            var expDate = new Date(subscription.expires_at);
            return expDate >= currentDate;
        });
        return activeSubscriptions.length > 0;
    };
    /**
     * Aux function. Checks if a given team depends on the current user
     * A dependent team is a team in which the current user has a role
     * other than 'user'
     * @param userRole
     * @returns - Boolean: whether the given team depends on the user
     */
    UserService.prototype.isDependent = function (userRole) {
        return userRole.team !== null && userRole.name !== 'user';
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
