<div class="d-flex flex-column h-100 ">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3>Solicitar Servicio</h3>
  </div>

  <div class="m-0 p-0 w-100 d-flex flex-grow-1 flex-column" *ngIf="!mapFailed">
    <!-- Employees dropdown element -->
    <div
      *ngIf="hasDependents && activeChecked && !pending && !showLoader"
      class="my-2 my-sm-3 mx-auto mx-sm-6"
      [ngClass]="{
        'employees-dropdown': globalData.ltSm(),
        'sm-employees-dropdown': globalData.ltMd(),
        'md-employees-dropdown': globalData.ltLg(),
        'lg-employees-dropdown': !globalData.ltLg()
      }"
    >
      <app-employees-dropdown
        [pending]="true"
        [(selectedEmployee)]="selectedEmployee">
      </app-employees-dropdown>
    </div>
    <ng-container *ngIf="!activeChecked">
      <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <div class="spinner-border pana-spinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>

    <app-service-map
      class="d-flex flex-grow-1 position-relative"
      *ngIf="activeChecked"
      [pending]="pending"
      [status]="status"
      [clientInfo]="clientInfo"
      [(lat)]="lat"
      [(lng)]="lng"
      [(googleAddress)]="googleAddress"
      [(reference)]="reference"
      [(mapFailed)]="mapFailed">
    </app-service-map>
    <ng-template [ngIf]="!pending" [ngIfElse]="cancelRequest">
      <div
        class="col-sm-10 col-xl-8 offset-sm-1 offset-xl-2 mt-4 px-4 text-center flex-basis-unset"
        *ngIf="!hasActiveSubscription"
      >
        <div class="alert m-0 text-green" role="alert">
          Parece que no tienes una suscripción activa. Por favor, suscríbete a través de la app para poder hacer uso del servicio
        </div>
      </div>
      <div class="col-sm-4 offset-sm-4 my-4 text-center flex-basis-unset">
        <app-generic-confirmation-modal
          *ngIf="!showLoader"
          [isDisabled]="incompleteData() || !hasActiveSubscription"
          [buttonText]="'SOLICITAR SERVICIO'"
          [modalTitle]="'Vas a solicitar un servicio en:'"
          [modalText]="googleAddress"
          [onConfirm]="hasDependents
                      ? dependentRequestService.bind(this)
                      : requestService.bind(this)"
          [confirmText]="'¡Ayuda!'"
          [btnStyles]="{
            'btn-pana-gradient m-0': true,
            'w-100': !globalData.ltSm() && globalData.ltLg(),
            'w-75': !globalData.ltLg(),
            'w-50': globalData.ltSm()
          }"
          [cancelText]="'Cambiar'">
        </app-generic-confirmation-modal>
      </div>
    </ng-template>
    <ng-template #cancelRequest>
      <div class="col-sm-4 offset-sm-4 my-4 text-center flex-basis-unset">
        <app-service-cancellation-modal
          [hasDependents]="hasDependents"
          [petitionId]="petitionId">
        </app-service-cancellation-modal>
      </div>
    </ng-template>
  </div>

  <div class="container-fluid flex-grow-1 d-flex justify-content-center align-items-center" *ngIf="mapFailed">
    <div class="row">
      <div class="col-12 text-center text-white">
        <img src="/assets/skull.svg" width="100" height="100" >
      </div>
      <div class="col-12 text-center text-white mar-skull">
        <h1>Ha ocurrido un error cargando el mapa</h1>
      </div>
      <div class="col-12 text-center text-white pb-5">
        <p>Por favor, recarga la página y aségurate de tener internet</p>
      </div>
    </div>
  </div>
</div>
