import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { User as UserForm } from '../../forms/corporative/user.form';
var CreateUserComponent = /** @class */ (function () {
    function CreateUserComponent(modalService, api, formBuilder, loaderService) {
        this.modalService = modalService;
        this.api = api;
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.usersChange = new EventEmitter();
        this.matcher = new CustomErrorStateMatcher();
    }
    CreateUserComponent.prototype.ngOnInit = function () {
        this.idTypes = ['V', 'E', 'P'];
        this.getDepartments();
        this.getRoles();
        this.errorMessage = undefined;
    };
    CreateUserComponent.prototype.validateUser = function () {
        var _this = this;
        var requestBody = this.form.value;
        if (!requestBody.department) {
            delete requestBody['department'];
        }
        requestBody['phone'] = '(' + requestBody['callCode'] + ')' + requestBody['phone'];
        this.loaderService.showLoader(true);
        this.api.validateBusinessRegister(this.businessId, requestBody).subscribe(function (data) {
            if (data.data.valid) {
                if (data.data.comments) {
                    _this.warningFromServer = data.data.comments.join('<br>');
                }
                _this.dataValidatedFromServer = true;
                _this.errorMessage = undefined;
            }
            else {
                _this.errorMessage = data.data.comments.join('<br>');
            }
            _this.loaderService.showLoader(false);
        }, function (error) {
            console.log('Error', error);
            _this.errorMessage = 'Ha ocurrido un error en el servidor, intenta nuevamente más tarde';
            _this.loaderService.showLoader(false);
        });
    };
    CreateUserComponent.prototype.registerUser = function () {
        var _this = this;
        var requestBody = this.form.value;
        if (!requestBody.department) {
            delete requestBody['department'];
        }
        this.loaderService.showLoader(true);
        this.api.businessRegister(this.businessId, requestBody).subscribe(function (data) {
            _this.serverResponse = true;
            _this.users = data.users;
            1;
            _this.usersChange.emit(_this.users);
            _this.errorMessage = undefined;
            _this.loaderService.showLoader(false);
        }, function (error) {
            console.log('Error', error);
            _this.serverResponse = true;
            _this.errorMessage = 'Ha ocurrido un error en el servidor, intenta nuevamente más tarde';
            _this.loaderService.showLoader(false);
        });
    };
    CreateUserComponent.prototype.openModal = function (createUserModal) {
        var _this = this;
        this.errorMessage = undefined;
        var userForm = new UserForm;
        this.form = this.formBuilder.group(userForm, {
            validator: [
                this.invalidIdentification,
                this.invalidPhone,
            ]
        });
        Object.keys(this.form.controls).forEach(function (field) {
            _this.form.get(field).setValidators(userForm.getValidators(field));
        });
        this.modalRef = this.modalService.open(createUserModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'offset-0 offset-md-3 offset-lg-2',
            beforeDismiss: this.closeModal.bind(this)
        });
    };
    CreateUserComponent.prototype.closeModal = function () {
        this.clearForm();
        this.serverResponse = false;
        this.errorMessage = undefined;
        this.dataValidatedFromServer = false;
        this.modalRef.close();
    };
    CreateUserComponent.prototype.backToForm = function () {
        this.serverResponse = false;
        this.dataValidatedFromServer = false;
        this.errorMessage = undefined;
        this.warningFromServer = undefined;
    };
    CreateUserComponent.prototype.getRoles = function () {
        var _this = this;
        this.api.getBusinessRoles().subscribe(function (data) {
            _this.roles = data.roles;
        });
    };
    CreateUserComponent.prototype.getDepartments = function () {
        var _this = this;
        this.api.getBusinessDepartments(this.businessId).subscribe(function (data) {
            _this.departments = data.data;
            if (_this.departments.length < 1 && _this.form != null) {
                _this.form.controls.department.disable();
            }
        });
    };
    CreateUserComponent.prototype.clearForm = function () {
        this.form.reset();
        this.errorMessage = undefined;
        this.warningFromServer = undefined;
    };
    CreateUserComponent.prototype.invalidIdentification = function (registrationForm) {
        var idCardRegex = /^[0-9]{7,9}$/;
        var pasRegex = /^[a-zA-Z0-9]{5,10}$/;
        var rifRegex = /^[0-9]{7,9}-[0-9]$/;
        var documentValue;
        documentValue = registrationForm.controls.document.value;
        var isValid;
        switch (registrationForm.controls.type_document.value) {
            case 'V':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'E':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'P':
                isValid = pasRegex.test(documentValue);
                break;
            default:
                isValid = false;
                break;
        }
        return isValid ? null : { 'invalid-identification': true };
    };
    /**
   * Class' form validator: checks wheter a given venezuelan phone number
   * is valid or not
   * @param registrationForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
    CreateUserComponent.prototype.invalidPhone = function (registrationForm) {
        var vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
        var vzlanPhone = registrationForm.controls.callCode.value === '+58';
        if (vzlanPhone && !vzlaPhoneRegex.test(registrationForm.controls.phone.value)) {
            return { 'invalid-phone': true };
        }
    };
    Object.defineProperty(CreateUserComponent.prototype, "f", {
        get: function () { return this.form.controls; },
        enumerable: true,
        configurable: true
    });
    return CreateUserComponent;
}());
export { CreateUserComponent };
