<ul class="nav nav-pills flex-column d-flex flex-grow-1 position-relative">
  <li
    class="d-flex "
    [ngClass]="{
      'active-section': isCurrentSection('/service'), 
      'inactive-section': !isCurrentSection('/service'),
      'bg-pana-dark': !isCurrentSection('/service')
    }"
  >
    <div></div>
    <span 
      class="w-100 pl-4 d-flex align-items-center text-uppercase"
      (click)="goToSection('/service')">
      Solicitar Servicio
    </span>
  </li>
  <li 
    class="d-flex"
    *ngIf="hasBusiness() && (user.isSuperAdmin() || user.isAdmin())" 
    [ngClass]="{
      'active-section': isCurrentSection('/my-business'), 
      'inactive-section bg-pana-dark': !isCurrentSection('/my-business'), 
      'bg-pana-dark': !isCurrentSection('/my-business') || navigationDisabled, 
      'disabled-section': navigationDisabled
    }"
    >
    <div></div>
    <span 
      class="w-100 pl-4 d-flex align-items-center text-uppercase"
      (click)="goToSection('/my-business')">
      Mi Empresa
    </span>
  </li>
  <li 
    class="d-flex"
    *ngIf="hasBusiness() && (user.isSuperAdmin() || user.isAdmin())" 
    [ngClass]="{
      'active-section': isCurrentSection('/user-management'), 
      'inactive-section': !isCurrentSection('/user-management'), 
      'bg-pana-dark': !isCurrentSection('/user-management') || navigationDisabled, 
      'disabled-section': navigationDisabled
    }"
  >
    <div></div>
    <span 
      class="w-100 pl-4 d-flex align-items-center text-uppercase"
      (click)="goToSection('/user-management')">
      Gestionar Empleados
    </span>
  </li>
  <li 
    class="d-flex"
    *ngIf="hasBusiness() && (user.isSuperAdmin() || user.isAdmin())" 
    [ngClass]="{
    'active-section': isCurrentSection('/invoices'), 
    'inactive-section': !isCurrentSection('/invoices'), 
    'bg-pana-dark': !isCurrentSection('/invoices') || navigationDisabled, 
    'disabled-section': navigationDisabled
    }"
  >
    <div></div>
    <span 
      class="w-100 pl-4 d-flex align-items-center text-uppercase"
      (click)="goToSection('/invoices')">
      Pagos y recibos
    </span>
  </li>
  <li class="d-flex flex-grow-1 align-items-end bg-pana-dark ">
    <div class="text-center contact-info w-100 mb-3 position-absulte">
      TELÉFONO DE EMERGENCIA <br>
      <span class="text-green">+58 424-1803194</span>
    </div>
  </li>
</ul>