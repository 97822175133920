import { HttpEvent, HttpDownloadProgressEvent, HttpEventType } from '@angular/common/http';

import { LoaderService } from '../services/loader.service';
import { isObject, isString, isNumber } from 'util';

export class ProgressBarEventHandler {

  /**
   * Handles http download events to create report messages with the status of
   * the download. When the download has ended, return the response body of the
   * petition.
   *
   * @param event The http download event data
   */
  public static downloadProgressHandler(event: HttpEvent<HttpDownloadProgressEvent>): any {
    return ProgressBarEventHandler.genericProgressHandler(
      event,
      {
        sentEvent: 'Estableciendo conexión con el servidor',
        downloadProgressEvent: 'Progreso de la descarga: '
      }
    );
  }

  /**
   * Handles http download events to create report messages with the status of
   * the preview. When the download has ended, return the response body of the
   * petition.
   *
   * @param event The http download event data
   */
  public static previewProgressHandler(event: HttpEvent<HttpDownloadProgressEvent>): any {
    return ProgressBarEventHandler.genericProgressHandler(
      event,
      {
        sentEvent: 'Preparando vista previa: 0%',
        downloadProgressEvent: 'Preparando vista previa: '
      }
    );
  }

  private static genericProgressHandler(
    event: HttpEvent<HttpDownloadProgressEvent>,
    messages: { sentEvent: string, downloadProgressEvent: string }
  ) {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          message: messages.sentEvent,
          downloadProgress: 0
        };

      case HttpEventType.DownloadProgress:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return {
          message: `${messages.downloadProgressEvent} ${percentDone}%`,
          downloadProgress: percentDone
        };

      case HttpEventType.Response:
        return event.body;

      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  /**
   * Shows the progress bar if the given data correspond to a download pogress
   *
   * @param data The report data object
   * @param loaderService The loader service used to show the progress bar
   */
  public static showProgressBar(data: any, loaderService: LoaderService) {
    if (isObject(data) && isString(data.message) && isNumber(data.downloadProgress)) {
      loaderService.showLoaderProgressbar(true, data.message, data.downloadProgress);
    } else {
      loaderService.showLoaderProgressbar(false);
    }
  }
}
