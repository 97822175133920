import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { NavigationService } from '../../services/navigation.service';
import { User } from '../../utils/user.utils';
import { UserEdit as UserEditForm } from '../../forms/corporative/user-edit.form';
import { UserFilterPipe } from '../../pipes/user-filter.pipe';
import { StringsConstant } from '../../constants/strings.constant';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
var UserManagementComponent = /** @class */ (function () {
    function UserManagementComponent(user, api, loaderService, userUtils, globals, userFilter, router, navigationService, formBuilder) {
        this.user = user;
        this.api = api;
        this.loaderService = loaderService;
        this.userUtils = userUtils;
        this.globals = globals;
        this.userFilter = userFilter;
        this.router = router;
        this.navigationService = navigationService;
        this.formBuilder = formBuilder;
        this.matcher = new CustomErrorStateMatcher();
        this.page = 1;
        this.math = Math;
        this.activeIndex = 0;
        this.usersPerPage = 100;
    }
    UserManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.idTypes = ['V', 'E', 'P'];
        this.navigationSubscription = this.router.events.subscribe(function (e) {
            /**
             * If it is a NavigationEnd event, re-initalize the component.
             * This is useful when the current section is clicked on at the sidebar.
             */
            if (e instanceof NavigationEnd) {
                _this.initialize();
            }
        });
        this.initialize();
    };
    UserManagementComponent.prototype.scrollToTop = function (top) {
        top.scrollTop = 0;
    };
    UserManagementComponent.prototype.ngOnDestroy = function () {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    };
    /**
     * Initializes class' variables values
     * Sets businessTeam if specified. Fetch it otherwise (when not a SuperAdmin)
     */
    UserManagementComponent.prototype.initialize = function () {
        var _this = this;
        this.businessTeam = undefined;
        this.focusedUser = undefined;
        this.departments = undefined;
        this.getEditForm();
        this.activeOrderMode = -1;
        this.isAdmin = this.user.isAdmin();
        this.isSuperAdmin = this.user.isSuperAdmin();
        if (this.navigationService.params) {
            setTimeout(function () {
                _this.setBusinessTeam(_this.navigationService.params.businessTeam);
                _this.navigationService.params = undefined;
            });
        }
        else if (!this.isSuperAdmin) {
            setTimeout(function () {
                _this.setBusinessTeam();
            });
        }
    };
    /**
     * Sets new business team when selected from business dropdown
     * and updates its users
     * @param selectedCompany - Object with selected company data
     */
    UserManagementComponent.prototype.onCompanyUpdated = function (selectedCompany) {
        this.businessTeam = selectedCompany;
        this.getUsers();
    };
    /**
     * Sets selected user from users table
     * @param user - Object with user data
     */
    UserManagementComponent.prototype.setFocusedUser = function (user) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        this.cancelEdit();
        this.focusedUser = user;
    };
    /**
     * Determines if user from users table is selected for details
     * @param user - Object with user data
     */
    UserManagementComponent.prototype.isFocused = function (user) {
        return this.focusedUser && this.focusedUser.email === user.email;
    };
    /**
     * Shows user data edition form, initializing and fetching required data
     */
    UserManagementComponent.prototype.showForm = function () {
        if (!this.departments || !this.roles) {
            this.getDepartments();
            this.getRoles();
        }
        this.errorMessage = undefined;
        this.idTypes = ['V', 'E', 'P'];
        var callCode = this.focusedUser.phone.match(/\+[0-9]+/g)[0];
        var phone = this.focusedUser.phone.match(/[0-9]{10}/g)[0];
        this.editForm.patchValue({
            firstName: this.focusedUser.first_name,
            lastName: this.focusedUser.last_name,
            documentType: this.focusedUser.type_document,
            document: this.focusedUser.document,
            email: this.focusedUser.email,
            callCode: callCode,
            phone: phone,
            role: this.userUtils.getRole(this.focusedUser, this.businessTeam.id).display_name,
            department: this.userUtils.getRole(this.focusedUser, this.businessTeam.id).department || ''
        });
        this.editForm.updateValueAndValidity();
        this.showEditForm = true;
    };
    /**
     * Performs user modifications reques to PANA's API
     * Hides edition form and updates both list of users and focused
     * user when success.
     */
    UserManagementComponent.prototype.editUser = function () {
        var _this = this;
        if (!this.editForm.valid) {
            return;
        }
        this.errorMessage = undefined;
        this.loaderService.showLoader(true);
        var requestBody = Object.assign({}, this.editForm.value);
        requestBody.phone = '(' + requestBody.callCode + ')' + requestBody.phone;
        requestBody.first_name = requestBody.firstName;
        requestBody.last_name = requestBody.lastName;
        requestBody.type_document = requestBody.documentType;
        this.api.editBusinessUser(this.businessTeam.id, this.focusedUser.id, requestBody)
            .subscribe(function (data) {
            _this.users = data.users;
            _this.cancelEdit();
            _this.updateFocused();
            _this.loaderService.showLoader(false);
        }, function (error) {
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    /**
     * Updates focused user data after modification from
     * a updated list of users
     */
    UserManagementComponent.prototype.updateFocused = function () {
        var _this = this;
        var updatedFocused = this.users.find(function (user) {
            return user.id === _this.focusedUser.id;
        });
        this.focusedUser = updatedFocused;
    };
    /**
     * Sets focused user and shows edition form
     * @param user - Object with selected user data from users table
     */
    UserManagementComponent.prototype.setAndEdit = function (user) {
        this.setFocusedUser(user);
        this.showForm();
    };
    /**
     * Resets and hides user edition form
     */
    UserManagementComponent.prototype.cancelEdit = function () {
        this.editForm.reset();
        this.errorMessage = undefined;
        this.showEditForm = false;
    };
    /**
     * Initializes a new user edition form and sets its validators
     */
    UserManagementComponent.prototype.getEditForm = function () {
        var _this = this;
        var editForm = new UserEditForm;
        this.editForm = this.formBuilder.group(editForm, {
            validator: [
                this.invalidPhone,
                this.invalidIdentification
            ]
        });
        Object.keys(this.editForm.controls).forEach(function (field) {
            _this.editForm.get(field).setValidators(editForm.getValidators(field));
        });
    };
    /**
     * Class' form validator: checks wheter a given venezuelan phone number
     * is valid or not
     * @param editForm - Registration form instance
     * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
     */
    UserManagementComponent.prototype.invalidPhone = function (editForm) {
        var vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
        var vzlanPhone = editForm.controls.callCode.value === '+58';
        if (vzlanPhone && !vzlaPhoneRegex.test(editForm.controls.phone.value)) {
            return { 'invalid-phone': true };
        }
    };
    /**
     * Navigates to Dashboard Section, initializing it with selected
     * user data.
     * @param user - Object with selected user data from users table
     */
    UserManagementComponent.prototype.requestService = function (user) {
        this.cancelEdit();
        this.navigationService.params = { user: user };
        this.router.navigate(['/service']);
    };
    /**
     * Navigates to Mass Load section, initializing it with current
     * business team
     */
    UserManagementComponent.prototype.massLoad = function () {
        this.cancelEdit();
        this.navigationService.params = { businessTeam: this.businessTeam };
        this.router.navigate(['/user-management/mass-load']);
    };
    /**
     * Performs employees CSV file download request to PANA's API
     */
    UserManagementComponent.prototype.downloadCSV = function () {
        var _this = this;
        this.cancelEdit();
        this.errorMessage = undefined;
        this.loaderService.showLoader(true);
        this.api.downloadCorpUSersFile(this.businessTeam.id)
            .subscribe(function (data) {
            /**
             * Creates an invisible <a> DOM object and clicks it
             * to start file download
             */
            var url = window.URL.createObjectURL(data);
            var a = document.createElement('a');
            a.href = url;
            a.download = _this.businessTeam.display_name + ' - Empleados.csv';
            a.click();
            window.URL.revokeObjectURL(url);
            _this.loaderService.showLoader(false);
        }, function (error) {
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    // User table helper methods definition
    UserManagementComponent.prototype.getTableElements = function () {
        return this.userFilter.transform(this.users, this.searchText, this.businessTeam.id);
    };
    UserManagementComponent.prototype.updateSearchText = function (newSearchText) {
        if (!this.isFirstPage()) {
            this.firstPage();
        }
        this.searchText = newSearchText;
    };
    UserManagementComponent.prototype.isFirstPage = function () {
        return this.page === 1;
    };
    UserManagementComponent.prototype.firstPage = function () {
        this.page = 1;
    };
    UserManagementComponent.prototype.lastPage = function () {
        this.page = this.getLastPage();
    };
    UserManagementComponent.prototype.previousPage = function () {
        if (this.page !== 1) {
            this.page -= 1;
        }
    };
    UserManagementComponent.prototype.nextPage = function () {
        if (this.page !== this.getLastPage()) {
            this.page += 1;
        }
    };
    UserManagementComponent.prototype.changePage = function (index) {
        this.page = index;
    };
    UserManagementComponent.prototype.range = function (index) {
        var fillRange = function (start, end) {
            return Array(end - start + 1).fill(null).map(function (_, i) { return start + i; });
        };
        return fillRange(index - 2, index + 2);
    };
    UserManagementComponent.prototype.getLastPage = function () {
        return Math.ceil(this.getTableElements().length / this.usersPerPage);
    };
    UserManagementComponent.prototype.orderByKey = function (key) {
        var _this = this;
        var userOrdering = function (user, other) {
            var comparator = function (a, b) {
                if (a[key] < b[key]) {
                    return -1 * _this.activeOrderMode;
                }
                else if (a[key] > b[key]) {
                    return 1 * _this.activeOrderMode;
                }
                return 0;
            };
            var dateComparator = function (a, b) {
                var date1 = new Date(a[key]);
                var date2 = new Date(b[key]);
                if (date1 < date2) {
                    return -1 * _this.activeOrderMode;
                }
                else if (date1 > date2) {
                    return 1 * _this.activeOrderMode;
                }
                return 0;
            };
            if (user[key]) {
                return comparator(user, other);
            }
            var role = _this.userUtils.getRole(user, _this.businessTeam.id);
            if (role[key]) {
                var role2 = _this.userUtils.getRole(other, _this.businessTeam.id);
                return comparator(role, role2);
            }
            var subs = _this.userUtils.getActiveSubscription(user);
            var subs2 = _this.userUtils.getActiveSubscription(other);
            return dateComparator(subs, subs2);
        };
        if (key === this.activeOrderKey) {
            this.activeOrderMode *= -1;
        }
        else {
            this.activeOrderMode = 1;
        }
        this.activeOrderKey = key;
        this.users.sort(userOrdering);
    };
    // End of user table helper definition
    /**
     * Sets business team data. This data can be passed as a parameter
     * or fetched from user's profile. Updates list of employees afterwards.
     * @param team? - Object with team data
     */
    UserManagementComponent.prototype.setBusinessTeam = function (team) {
        if (team) {
            this.businessTeam = team;
        }
        else {
            var businessRole = this.user.getBusinessSubscription();
            this.businessTeam = businessRole.team;
        }
        this.getUsers();
    };
    /**
     * Class' form validator: checks wheter a given venezuelan ID number
     * is valid or not
     * @param registrationForm - Registration form instance
     * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
     */
    UserManagementComponent.prototype.invalidIdentification = function (registrationForm) {
        var idCardRegex = /^[0-9]{7,9}$/;
        var pasRegex = /^[a-zA-Z0-9]{5,10}$/;
        var documentValue;
        documentValue = registrationForm.controls.document.value;
        var isValid;
        switch (registrationForm.controls.documentType.value) {
            case 'V':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'E':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'P':
                isValid = pasRegex.test(documentValue);
                break;
            default:
                isValid = false;
                break;
        }
        return isValid ? null : { 'invalid-identification': true };
    };
    /**
     * Performs users list request for a given company to PANA's API.
     * Updates list of users if succeeds.
     */
    UserManagementComponent.prototype.getUsers = function () {
        var _this = this;
        this.errorMessage = undefined;
        this.loaderService.showLoader(true);
        this.api.getTeamMembers(this.businessTeam.id).subscribe(function (data) {
            if (data.data.length > 0) {
                _this.users = data.data;
            }
            else {
                _this.usersLoaded = true;
            }
            _this.loaderService.showLoader(false);
        }, function (_) {
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    /**
     * Performs available roles request to PANA's API.
     * Updates list of roles if succeeds.
     */
    UserManagementComponent.prototype.getRoles = function () {
        var _this = this;
        this.api.getBusinessRoles().subscribe(function (data) {
            _this.roles = data.roles;
        });
    };
    /**
     * Performs departments list request for a given company to PANA's API.
     * Updates list of departments if succeeds.
     */
    UserManagementComponent.prototype.getDepartments = function () {
        var _this = this;
        this.api.getBusinessDepartments(this.businessTeam.id).subscribe(function (data) {
            _this.departments = data.data;
            if (_this.departments.length < 1) {
                _this.editForm.controls.department.disable();
            }
        });
    };
    Object.defineProperty(UserManagementComponent.prototype, "f", {
        get: function () { return this.editForm.controls; },
        enumerable: true,
        configurable: true
    });
    return UserManagementComponent;
}());
export { UserManagementComponent };
