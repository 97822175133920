import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.showLoaderSource = new BehaviorSubject(false);
        this.showLoaderSourceProgressbar = new BehaviorSubject(false);
        this.showLoaderStatus$ = this.showLoaderSource.asObservable();
        this.showLoaderStatusProgressbar$ = this.showLoaderSourceProgressbar.asObservable();
    }
    /**
     * Determines wheter loader should be shown or not
     *
     * @param show - Whether loader should be shown or not
     */
    LoaderService.prototype.showLoader = function (show) {
        this.showLoaderSource.next(show);
    };
    // TODO: Check if the function can be refactored
    /**
     * Determines wheter progress bar loader should be shown or not.
     *
     * @param show - Wheter loader should be shown or not
     * @param message The message to be displayed in the loader component
     * @param percent The percentage of the progress bar to be displayed
     */
    LoaderService.prototype.showLoaderProgressbar = function (show, message, percent) {
        this.showLoaderSourceProgressbar.next(show);
        this.percentLoader = percent;
        this.loaderMessage = message;
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
