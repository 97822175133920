<ng-template #unsubscribeUserModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-template [ngIf]="!deleted" [ngIfElse]="successfullyDeleted" >
      <div class="content">
        <div class="text-center">
          <img src="/assets/Cone.svg" size="2x">
        </div>
        <div class="text-center mar-top">
          <p>Vas a dar de baja a:</p>
        </div>
        <div class="text-center">
          <h2>{{ user.first_name + ' ' + user.last_name }}</h2>
        </div>
        <div class="text-center">
          <p class="my-0">{{ user.email }}</p>
          <p class="my-0">{{ user.phone }}</p>
          <p class="my-0">CI: {{ user.type_document + '-' + user.document }}</p>
          <p class="my-0">{{ userRole.department }}</p>
          <p class="my-0">{{ userRole.display_name }}</p>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-pink mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            {{ 'Cancelar' | uppercase }}
          </div>
        </div>
        <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="unsubscribeUser()">
          {{ 'Adelante' | uppercase }}
        </button>
      </div>
    </ng-template>
    <ng-template #successfullyDeleted >
      <div class="content">
        <div class="text-center" *ngIf="!errorMessage">
          <img src="/assets/check-green.svg" width="50" height="50" >
          <p class="margin-text">Se dio de baja a:</p>
          <h2>{{ user.first_name + ' ' + user.last_name }}</h2>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-pink mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-pana-gradient w-100 mx-3" (click)="closeModal()">
          {{ 'Cerrar' | uppercase }}
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>

<img class="clickable mar-x" [src]="iconSrc" height="25" width="25" (click)="openModal(unsubscribeUserModal)"
  matTooltip="Dar de baja" matTooltipPosition="above" *ngIf="size === 45"/>

<img class="clickable mar-x" [src]="iconSrc" height="20" width="20" (click)="openModal(unsubscribeUserModal)"
matTooltip="Dar de baja" matTooltipPosition="above" *ngIf="size !== 45"/>
