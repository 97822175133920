import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { MouseEvent, MapsAPILoader } from '@agm/core';

import { GlobalDataService } from '../../services/global-data.service';
import { LoaderService } from '../../services/loader.service';

declare var google: any;

@Component({
  selector: 'app-service-map',
  templateUrl: './service-map.component.html',
  styleUrls: ['./service-map.component.css']
})
export class ServiceMapComponent implements OnChanges, OnInit {
  @Input() pending: boolean;
  @Input() status: string;
  @Input() clientInfo: any;
  @Input() lat: number;
  @Input() lng: number;
  @Input() googleAddress: string;
  @Input() reference: string;
  @Input() mapFailed: boolean;
  @Output() latChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() lngChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() googleAddressChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() referenceChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() mapFailedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  markerIconUrl: Object = {
    url: '/assets/panapin.png',
    scaledSize: {
      height: 35,
      width: 25
    }
  };

  modalText: string;
  infoCardText: string;

  // Google's geocode service
  geocoder: any;
  zoom = 15;
  showInfoWindow = true;
  loadingLocation: boolean;
  showLoader: boolean;
  map: any;

  constructor(
    private gapi: MapsAPILoader,
    private loaderService: LoaderService,
    public globalData: GlobalDataService
  ) {}

  ngOnInit() {
    this.loaderService.showLoaderStatus$.subscribe(
      status => this.showLoader = status
    );

    this.gapi.load().then(
      () => {
        this.geocoder = new google.maps.Geocoder();
        if (navigator.geolocation) {
          this.getCurrentLocation(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const pendingChange: SimpleChange = changes.pending;
    const clientInfoChange: SimpleChange = changes.clientInfo;
    if (pendingChange) {
      // TODO: Quitar este if?
    }

    if (clientInfoChange) {
      if (clientInfoChange.currentValue) {
        this.modalText = '<h2 class="my-5">' + this.clientInfo.name + '</h2>' +
          '<p class="m-0">' + this.clientInfo.email + '</p>' +
          '<p class="m-0">' + this.clientInfo.document + '</p>' +
          '<p class="m-0">' + this.clientInfo.phone + '</p>' +
          '<p class="m-0 mb-5">' + this.clientInfo.department + '</p>' +
          '<p>' + this.googleAddress + '</p>';

          this.infoCardText = '<p class="m-0">' + this.clientInfo.name + '</p>' +
          '<p class="m-0">' + this.clientInfo.phone + '</p>' +
          '<p class"m-0">' + this.googleAddress + '</p>';

        if (this.reference) {
          this.modalText += '<p> Punto de referencia: ' + this.reference + ' </p>';
          this.infoCardText += '<p> Punto de referencia: ' + this.reference + ' </p>';
        }
      }
    }
  }

  onMapReady(map) {
    this.map = map;
  }

  onMapIdle() {
    if (this.map) {
      const lat = this.map.center.lat();
      const lng = this.map.center.lng();
      const coords = {
        lat: lat,
        lng: lng
      };
      this.onPlaceChange(coords);
    }
  }

  onChooseLocation($event: MouseEvent): void {
    this.onPlaceChange($event.coords);
  }

  onPlaceChange(coords: any): void {
    if (!this.loadingLocation && !this.pending) {
      this.updateLocation(coords);
      this.map.setCenter({ lat: this.lat, lng: this.lng });
    }
  }

  onReferenceChange(newReference: string): void {
    this.reference = newReference; // This may be redundant
    this.referenceChange.emit(this.reference);
  }

  centerMap() {
    navigator.geolocation.getCurrentPosition(
      async (result) => {
        // We get the current user's location in order to
        // center the map there (by default) in case there isn't a pending request
        if (this.lng !== result.coords.longitude || this.lat !== result.coords.latitude) {
          this.getAddressFromCoords(result.coords.latitude, result.coords.longitude);
        }
        this.lng = result.coords.longitude;
        this.lat = result.coords.latitude;
        this.latChange.emit(this.lat);
        this.lngChange.emit(this.lng);
        this.map.setCenter({ lat: this.lat, lng: this.lng});

        this.loaderService.showLoader(false);
      },
      () => {
        this.loaderService.showLoader(false);
        this.mapFailed = true;
        this.mapFailedChange.emit(this.mapFailed);
      }
    );
  }

  getCurrentLocation(onInit: boolean): void {
    navigator.geolocation.getCurrentPosition(
      (result) => {
        // We get the current user's location in order to
        // center the map there (by default) in case there isn't a pending request
        if (!onInit || (!this.lat && !this.lng)) {
          this.lng = result.coords.longitude;
          this.lat = result.coords.latitude;
          this.latChange.emit(this.lat);
          this.lngChange.emit(this.lng);
        }
        this.getAddressFromCoords(this.lat, this.lng);

        this.loaderService.showLoader(false);
      },
      () => {
        let lat = 10.5035003;
        let lng = -66.8602066;
        const coords = {
          lat: lat,
          lng: lng
        };
        this.updateLocation(coords);
        this.loaderService.showLoader(false);
      }
    );
  }

  private updateLocation(coords: any): void {
    this.lat = coords.lat;
    this.lng = coords.lng;
    this.latChange.emit(this.lat);
    this.lngChange.emit(this.lng);
    this.getAddressFromCoords(coords.lat, coords.lng);
  }

  private loaded(): void {
    this.loadingLocation = false;
    this.showInfoWindow = true;
  }

  private loading(): void {
    this.loadingLocation = true;
    this.showInfoWindow = false;
    this.googleAddress = '';
  }

  private getAddressFromCoords(lat: number, lng: number): void {
    this.loading();
    this.geocoder.geocode({
      'location': {
        'lat': lat,
        'lng': lng
      }
    }, (results, status) => {
      this.loaded();
      if (status === google.maps.GeocoderStatus.OK) {
        const result: any = results[0];
        this.googleAddress = result.formatted_address;
        this.googleAddressChange.emit(this.googleAddress);
      } else {
        console.log('Error - ', results, ' & Status - ', status);
      }
    });
  }
}
