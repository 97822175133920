<ng-template #genericCofirmationModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="content">
      <div class="text-center">
        <p>{{ modalTitle }} </p>
      </div>
      <div class="text-center">
        <h2>{{ modalText }}</h2>
      </div>
    </div>
    <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
      <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
        <div>
          {{ cancelText | uppercase }}
        </div>
      </div>
      <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="confirmModal()">
        {{ confirmText | uppercase }}
      </button>
    </div>
  </div>
</ng-template>

<button class="btn btn-pana-gradient w-100"
        [ngClass]="btnStyles"
        *ngIf="!icon && !btnDark"
        (click)="openModal(genericCofirmationModal)"
        [disabled]="isDisabled">
  {{ buttonText | uppercase }}
</button>

<div class="btn btn-pana-gradient-dark w-100"
     [ngClass]="btnStyles"
     *ngIf="!icon && btnDark"
     (click)="openModal(genericCofirmationModal)">
  <div>
    {{ buttonText | uppercase }}
  </div>
</div>

<img src="/assets/Download.svg" height="25" width="25"  [name]="icon" *ngIf="icon"  class="clickable text-gray" (click)="openModal(genericCofirmationModal)" [matTooltip]="tooltip" [matTooltipPosition]="tooltipPosition">