/// <reference types="@types/googlemaps" />
import { Directive, OnInit, ElementRef, Output, EventEmitter, Renderer } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[appGooglePlaces]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() placeChange: EventEmitter<any> = new EventEmitter<any>();
  private element: HTMLInputElement;

  constructor(private elemRef: ElementRef, private renderer: Renderer) {
    this.element = this.elemRef.nativeElement;
   }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.element);
    // Place must be within Caracas Limits
    const swCoords = new google.maps.LatLng({ lat: 10.406474, lng: -67.065743 });
    const neCoords = new google.maps.LatLng({ lat: 10.532126, lng: -66.716838});
    const bounds = new google.maps.LatLngBounds(swCoords, neCoords);
    autocomplete.setBounds(bounds);
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.placeChange.emit(this.getLocation(autocomplete.getPlace()));
    });
    this.element.addEventListener('blur', this.onBlur.bind(this));
    this.element.addEventListener('focus', this.onFocus.bind(this));
    this.element.addEventListener('input', this.onInput.bind(this));
  }

  getLocation(place: any): any {
    const result = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    };
    return result;
  }

  onFocus(event) {
    if (event.target.value) {
      this.renderer.setElementClass(this.element, 'dropdown-input-expanded', true);
    }
  }

  onBlur(event) {
    this.renderer.setElementClass(this.element, 'dropdown-input-expanded', false);
  }

  onInput(event) {
    if (event.target.value) {
      this.renderer.setElementClass(this.element, 'dropdown-input-expanded', true);
    } else {
      this.renderer.setElementClass(this.element, 'dropdown-input-expanded', false);
    }
  }
}
