/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../business-contact/business-contact.component.ngfactory";
import * as i3 from "../business-contact/business-contact.component";
import * as i4 from "../../services/api.service";
import * as i5 from "@angular/common";
import * as i6 from "./register-modal.component";
import * as i7 from "../../services/global-data.service";
import * as i8 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i9 from "@angular/router";
var styles_RegisterModalComponent = [i0.styles];
var RenderType_RegisterModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterModalComponent, data: {} });
export { RenderType_RegisterModalComponent as RenderType_RegisterModalComponent };
function View_RegisterModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Est\u00E1s interesado en una cuenta..."])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-pana-gradient w-100 mx-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToUserRegister() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" PANA PERSONAL "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-pana-gradient w-100 mx-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showBusiness = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" PANA CORPORATIVO "]))], null, null); }
function View_RegisterModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-business-contact", [["class", "d-flex flex-column flex-grow-1"]], null, [[null, "showMeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showMeChange" === en)) {
        var pd_0 = ((_co.showBusiness = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BusinessContactComponent_0, i2.RenderType_BusinessContactComponent)), i1.ɵdid(1, 114688, null, 0, i3.BusinessContactComponent, [i4.ApiService], { showMe: [0, "showMe"] }, { showMeChange: "showMeChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBusiness; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RegisterModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RegisterModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showBusiness; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showBusiness; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RegisterModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["registerChoiceModal", 2]], null, 0, null, View_RegisterModalComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "btn btn-pana-gradient-dark w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NO TENGO CUENTA "]))], null, null); }
export function View_RegisterModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register-modal", [], null, null, null, View_RegisterModalComponent_0, RenderType_RegisterModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.RegisterModalComponent, [i7.GlobalDataService, i8.NgbModal, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterModalComponentNgFactory = i1.ɵccf("app-register-modal", i6.RegisterModalComponent, View_RegisterModalComponent_Host_0, {}, {}, []);
export { RegisterModalComponentNgFactory as RegisterModalComponentNgFactory };
