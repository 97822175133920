import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { User as UserForm } from '../../forms/corporative/user.form';
import { UserProfile } from '../../interfaces/user-profile.interface';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  @Input() businessId: number;
  @Input() btnXS: boolean;
  @Input() users: Array<UserProfile>;
  @Output() usersChange = new EventEmitter<Array<UserProfile>>();

  modalRef: NgbModalRef;
  form: FormGroup;
  idTypes: string[];
  departments: any[];
  roles: any[];
  errorMessage: string;
  warningFromServer: string;
  dataValidatedFromServer: boolean;
  serverResponse: boolean;
  matcher = new CustomErrorStateMatcher();

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.idTypes = ['V', 'E', 'P'];
    this.getDepartments();
    this.getRoles();
    this.errorMessage = undefined;
  }

  validateUser() {
    const requestBody = this.form.value;

    if (!requestBody.department) {
      delete requestBody['department'];
    }

    requestBody['phone'] = '(' + requestBody['callCode'] + ')' + requestBody['phone'];

    this.loaderService.showLoader(true);
    this.api.validateBusinessRegister(this.businessId, requestBody).subscribe(
      data => {
        if (data.data.valid) {
          if (data.data.comments) {
            this.warningFromServer = data.data.comments.join('<br>');
          }
          this.dataValidatedFromServer = true;
          this.errorMessage = undefined;
        } else {
          this.errorMessage = data.data.comments.join('<br>');
        }

        this.loaderService.showLoader(false);
      },
      error => {
        console.log('Error', error);
        this.errorMessage = 'Ha ocurrido un error en el servidor, intenta nuevamente más tarde';
        this.loaderService.showLoader(false);
      }
    );
  }

  registerUser() {
    const requestBody = this.form.value;

    if (!requestBody.department) {
      delete requestBody['department'];
    }

    this.loaderService.showLoader(true);
    this.api.businessRegister(this.businessId, requestBody).subscribe(
      data => {
        this.serverResponse = true;
        this.users = data.users;1
        this.usersChange.emit(this.users);
        this.errorMessage = undefined;
        this.loaderService.showLoader(false);
      },
      error => {
        console.log('Error', error);
        this.serverResponse = true;
        this.errorMessage = 'Ha ocurrido un error en el servidor, intenta nuevamente más tarde';
        this.loaderService.showLoader(false);
      }
    );
  }

  openModal(createUserModal) {
    this.errorMessage = undefined;
    const userForm = new UserForm;
    this.form = this.formBuilder.group(
      userForm,
      {
        validator: [
          this.invalidIdentification,
          this.invalidPhone,
        ]
      }
    );
    Object.keys(this.form.controls).forEach(field => {
      this.form.get(field).setValidators(
        userForm.getValidators(field)
      );
    });
    this.modalRef = this.modalService.open(
      createUserModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2',
        beforeDismiss: this.closeModal.bind(this)
      }
    );
  }

  closeModal() {
    this.clearForm();
    this.serverResponse = false;
    this.errorMessage = undefined;
    this.dataValidatedFromServer = false;
    this.modalRef.close();
  }

  backToForm() {
    this.serverResponse = false;
    this.dataValidatedFromServer = false;
    this.errorMessage = undefined;
    this.warningFromServer = undefined;
  }

  private getRoles() {
    this.api.getBusinessRoles().subscribe(
      data => {
        this.roles = data.roles;
      }
    );
  }

  private getDepartments() {
    this.api.getBusinessDepartments(this.businessId).subscribe(
      data => {
        this.departments = data.data;
        if (this.departments.length < 1 && this.form != null) {
          this.form.controls.department.disable();
        }
      }
    );
  }

  private clearForm(): void {
    this.form.reset();
    this.errorMessage = undefined;
    this.warningFromServer = undefined;
  }

  private invalidIdentification(registrationForm: FormGroup) {
    const idCardRegex = /^[0-9]{7,9}$/;
    const pasRegex = /^[a-zA-Z0-9]{5,10}$/;
    const rifRegex = /^[0-9]{7,9}-[0-9]$/;
    let documentValue: string;
    documentValue = registrationForm.controls.document.value;
    let isValid: boolean;
    switch (registrationForm.controls.type_document.value) {
      case 'V':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'E':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'P':
        isValid = pasRegex.test(documentValue);
        break;
      default:
        isValid = false;
        break;
    }

    return isValid ? null : { 'invalid-identification': true };
  }

  /**
 * Class' form validator: checks wheter a given venezuelan phone number
 * is valid or not
 * @param registrationForm - Registration form instance
 * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
 */
  private invalidPhone(registrationForm: FormGroup) {
    const vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
    const vzlanPhone = registrationForm.controls.callCode.value === '+58';
    if (vzlanPhone && !vzlaPhoneRegex.test(registrationForm.controls.phone.value)) {
      return { 'invalid-phone': true };
    }
  }

  get f() { return this.form.controls; }
}
