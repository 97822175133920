<div class="d-flex flex-column h-100 align-items-center vertical-scroll position-absolute w-100">
  <img src="/assets/logo-principal.png" height="90" width="74" class="d-inline-block align-top mt-5 m-bot" alt="">

  <div class="text-center mb-4 mt-5" *ngIf="!success">
    <h1 class="font-light mb-5">¡Ya casi estamos listos!</h1>
    <h5 class="font-light ">Configura tu nueva contraseña</h5>
  </div>
  <div class="w-100">
    <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()" class="form-group d-flex flex-column align-items-center justify-content-center" *ngIf="!success">
      <mat-form-field class="centered mb-3" *ngIf="!tempPass" [ngClass]="{
          'w-75': globalData.ltSm(),
          'w-60': !globalData.ltSm() && globalData.ltMd(),
          'w-50': !globalData.ltMd() && globalData.ltLg(),
          'w-35': !globalData.ltLg() && globalData.ltXl(),
          'w-25': !globalData.ltXl()
        }"
      >
        <input matInput appAutofocus type="password" formControlName="oldPassword" placeholder="Contraseña Provisional">
        <mat-error *ngIf="f.oldPassword.invalid && (f.oldPassword.dirty || f.oldPassword.touched)">
          <div *ngIf="f.oldPassword.errors.minlength">
            La contraseña introducida tiene menos 8 caracteres
          </div>
          <div *ngIf="f.oldPassword.errors.required">
            Por favor, introduce el código provisional enviado a tu correo
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="centered mb-3" [ngClass]="{
          'w-75': globalData.ltSm(),
          'w-60': !globalData.ltSm() && globalData.ltMd(),
          'w-50': !globalData.ltMd() && globalData.ltLg(),
          'w-35': !globalData.ltLg() && globalData.ltXl(),
          'w-25': !globalData.ltXl()
        }"
      >
        <input matInput type="password" formControlName="newPassword" placeholder="Contraseña Nueva">
        <mat-error *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)">
          <div *ngIf="f.newPassword.errors.minlength">
            La contraseña debe tener al menos 8 caracteres
          </div>
          <div *ngIf="f.newPassword.errors.required">
            Por favor, introduce tu contraseña 
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="centered mb-3" [ngClass]="{
          'w-75': globalData.ltSm(),
          'w-60': !globalData.ltSm() && globalData.ltMd(),
          'w-50': !globalData.ltMd() && globalData.ltLg(),
          'w-35': !globalData.ltLg() && globalData.ltXl(),
          'w-25': !globalData.ltXl()
        }"
      >
        <input matInput type="password" formControlName="passwordConfirmation" placeholder="Confirmación de Contraseña" [errorStateMatcher]="matcher">
        <mat-error *ngIf="setPasswordForm.hasError('missmatching-pass') && (f.passwordConfirmation.dirty || f.passwordConfirmation.touched)">
          <div>
            Tu contraseña no coincide
          </div>
        </mat-error>
      </mat-form-field>
      <div class="w-100 text-center">
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!setPasswordForm.valid">
          CONFIGURAR CONTRASEÑA
        </button>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-pana-link w-100 px-0" (click)="logout()">
          Descartar proceso
        </button>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mt-5" *ngIf="errorMessage">
        <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
        <p class="text-pink mt-1 text-center">
          {{ errorMessage }}
        </p>
      </div>
    </form>
  </div>
  <div class="text-center" *ngIf="success">
    <img src="/assets/ecommerce.svg" width="70" height="70" class="d-inline-block align-top mx-sm-2" alt="">
    <h5 class="font-light mar-top">Contraseña actualizada exitosamente</h5>
    <!-- <fa name="check-circle" size="lg"></fa> -->
  </div>
  <div class="text-center w-100" *ngIf="success">
    <button type="submit" class="btn btn-pana-gradient w-100 m-top" [routerLink]="['/service']">
      CONTINUAR
    </button>
  </div>
</div>
