import { Injectable } from '@angular/core';

import { CurrentUser } from '../interfaces/current-user.interface';
import { UserProfile } from '../interfaces/user-profile.interface';
import { UserRol } from '../interfaces/user-rol.interface';

import { SubscriptionsConstant } from '../constants/subscriptions.constant';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor() { }

  /**
   * Sets app token in local storage
   * @param appToken
   */
  setAppToken(appToken: string): void {
    localStorage.setItem('appToken', appToken);
  }

  /**
   * Sets users credentials in local storage
   * @param username
   * @param accessToken
   */
  setCredentials(username: string, accessToken: string): void {
    localStorage.setItem('currentUser', JSON.stringify({
      username: username,
      token: accessToken
    }));
  }

  /**
   * Checks if current user is logged.
   * @returns - Boolean: whether current user is logged or not
   */
  isLogged(): boolean {
    return localStorage.getItem('currentUser') !== null;
  }

  /**
   * Sets current user profile
   * @param profile - UserProfile: object with user data
   */
  setProfile(profile: UserProfile): void {
    const currentUser = this.getCurrentUser();
    currentUser.profile = profile;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  /**
   * Gets current user's profile.
   * @returns - UserProfile: object with current user data
   */
  getProfile(): UserProfile {
    const currentUser = this.getCurrentUser();
    return currentUser.profile;
  }

  /**
   * Gets user id from profile
   * @returns - Number: user id
   */
  getId(): number {
    const currentProfile = this.getProfile();
    return currentProfile.id;
  }

  /**
   * Gets current user data
   * @returns - Object with current user data
   */
  getCurrentUser(): CurrentUser {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /**
   * Gets current user roles
   * @returns - UserRol[]: array with all user's roles data
   */
  getUserRoles(): Array<UserRol> {
    const currentUser = this.getCurrentUser();
    const userRoles = currentUser.profile ? currentUser.profile.roles : null;
    return userRoles;
  }

  /**
   * Performs user logout
   */
  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('appToken');
  }

  /**
   * Checks whether current user has dependents or not
   * @returns - Boolean: Whether current user has dependents or not
   */
  hasDependents(): boolean {
    return (this.isSuperAdmin() || this.dependentTeams().length > 0);
  }

  /**
   * Gets all dependent teams from user's roles
   * @returns - UserRol[]: Array with dependent teams roles
   */
  dependentTeams(): Array<UserRol> {
    return this.getUserRoles().filter(this.isDependent);
  }

  /**
   * Gets user's business subscriptions if exists
   *
   * TODO: Change name of function to be more mnemonic
   *
   * @returns - UserRol: user business team
   */
  getBusinessSubscription(): UserRol {
    const businessTeam = this.getProfile().roles.find(
      function (role: UserRol) {
        const team = role.team;
        return team ? team.type_label === SubscriptionsConstant.BUSINESS : false;
      }
    );
    return businessTeam;
  }

  /**
   * Checks whether current user has a role in which is admin
   * @returns - Boolean: whether current user has a role in which is admin
   */
  isAdmin(): boolean {
    const businessRole = this.getBusinessSubscription();
    return businessRole && businessRole.name === 'admin';
  }

  /**
   * Checks whether current user is super admin
   * @returns - Boolean: whether current user is super admin
   */
  isSuperAdmin(): boolean {
    const roles = this.getUserRoles();
    const isSuperAdmin = roles.some(
      function (role: UserRol) {
        return role.name === 'super_admin';
      }
    );
    return isSuperAdmin;
  }

  /**
   * Checks whether current user has an active subscription or not
   * @returns - Boolean: whether current user has an active subscription or not
   */
  hasActiveSubscription(): boolean {
    const userProfile = this.getProfile();
    const userSubscriptions = userProfile.subscriptions;
    const currentDate = new Date();
    const activeSubscriptions = userSubscriptions.filter(
      (subscription) => {
        const expDate = new Date(subscription.expires_at);
        return expDate >= currentDate;
      }
    );
    return activeSubscriptions.length > 0;
  }

  /**
   * Aux function. Checks if a given team depends on the current user
   * A dependent team is a team in which the current user has a role
   * other than 'user'
   * @param userRole
   * @returns - Boolean: whether the given team depends on the user
   */
  private isDependent(userRole: UserRol): boolean {
    return userRole.team !== null && userRole.name !== 'user';
  }
}
