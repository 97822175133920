import { OnInit, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
var AddCreditCardComponent = /** @class */ (function () {
    function AddCreditCardComponent(modalService, formBuilder, loaderService) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.paymentMethodsChange = new EventEmitter();
        this.cardInputFocused = false;
        this.cardInputValid = false;
        this.showCardInput = false;
    }
    AddCreditCardComponent.prototype.ngOnInit = function () {
        this.errorMessage = undefined;
        this.stripeErrorMessage = undefined;
    };
    AddCreditCardComponent.prototype.ngAfterViewInit = function () {
        var style = {
            base: {
                iconColor: '#FFF',
                color: '#FFF',
                '::placeholder': {
                    color: '#999',
                },
            },
        };
        this.stripe = Stripe(environment.tokenStripe, { locale: 'es' });
        var elements = this.stripe.elements();
        this.cardInput = elements.create('card', {
            style: style,
        });
    };
    AddCreditCardComponent.prototype.openModal = function (addCreditCardModal) {
        var _this = this;
        this.errorMessage = undefined;
        this.stripeErrorMessage = undefined;
        this.addCreditCardForm = this.formBuilder.group({
            name: new FormControl('', [
                Validators.required,
                Validators.pattern('^[a-zA-Zñáééióú.\s]*$'),
                Validators.minLength(5)
            ]),
        });
        this.modalRef = this.modalService.open(addCreditCardModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'offset-0 offset-md-3 offset-lg-2',
            beforeDismiss: this.closeModal.bind(this)
        });
        this.cardInput.mount('#card-element');
        this.cardInput.addEventListener('change', function (event) {
            if (event.error) {
                _this.stripeErrorMessage = event.error.message;
            }
            else {
                _this.stripeErrorMessage = '';
                _this.errorMessage = '';
            }
            if (event.complete) {
                _this.cardInputValid = true;
            }
            else {
                _this.cardInputValid = false;
            }
        });
        this.cardInput.addEventListener('focus', function () {
            _this.cardInputFocused = true;
        });
        this.cardInput.addEventListener('blur', function () {
            _this.cardInputFocused = false;
        });
        this.cardInput.on('ready', function () {
            _this.showCardInput = true;
            _this.cardInput.focus();
        });
    };
    AddCreditCardComponent.prototype.closeModal = function () {
        this.clearForm();
        this.serverResponse = false;
        this.errorMessage = undefined;
        this.hideCardInputs = false;
        this.modalRef.close();
    };
    AddCreditCardComponent.prototype.clearForm = function () {
        this.addCreditCardForm.reset();
        this.errorMessage = undefined;
    };
    AddCreditCardComponent.prototype.addPaymentMethod = function () {
        var _this = this;
        var billingDetails = {
            name: this.addCreditCardForm.controls.name.value,
        };
        var metadata = {
            default: true,
        };
        this.loaderService.showLoader(true);
        this.stripe.createPaymentMethod({
            type: 'card',
            card: this.cardInput,
            billing_details: billingDetails,
            metadata: metadata
        })
            .then(function (result) {
            _this.paymentMethods.map(function (paymentMethod) {
                delete paymentMethod.metadata.default;
            });
            _this.paymentMethods.push(result.paymentMethod);
            _this.serverResponse = true;
            _this.hideCardInputs = true;
            _this.loaderService.showLoader(false);
            // this.closeModal();
            console.log(result);
        })
            .catch(function (error) {
            console.log(error);
            _this.loaderService.showLoader(false);
            _this.serverResponse = false;
            _this.errorMessage = error;
            _this.hideCardInputs = true;
        });
    };
    AddCreditCardComponent.prototype.ngOnDestroy = function () {
        // this.cardInput.removeEventListener('change', event);
        this.cardInput.destroy();
    };
    Object.defineProperty(AddCreditCardComponent.prototype, "f", {
        get: function () { return this.addCreditCardForm.controls; },
        enumerable: true,
        configurable: true
    });
    return AddCreditCardComponent;
}());
export { AddCreditCardComponent };
