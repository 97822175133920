import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { NavigationService } from '../../services/navigation.service';
import { User } from '../../utils/user.utils';
import { UserEdit as UserEditForm } from '../../forms/corporative/user-edit.form';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { UserFilterPipe } from '../../pipes/user-filter.pipe';
import { StringsConstant } from '../../constants/strings.constant';
import { Subscription } from 'rxjs';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  isSuperAdmin: boolean;
  errorMessage: string;

  businessTeam: any;
  usersLoaded: boolean;
  users: any[];
  focusedUser: UserProfile;
  editForm: FormGroup;
  roles: any[];
  idTypes: string[];
  departments: any[];
  showEditForm: boolean;
  matcher = new CustomErrorStateMatcher();

  searchText: string;
  activeIndex: number;
  usersPerPage: number;
  activeOrderKey: string;
  activeOrderMode: number;

  navigationSubscription: Subscription;

  page = 1;
  math = Math;

  constructor(
    private user: UserService,
    private api: ApiService,
    private loaderService: LoaderService,
    public userUtils: User,
    public globals: GlobalDataService,
    private userFilter: UserFilterPipe,
    private router: Router,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder
  ) {
    this.activeIndex = 0;
    this.usersPerPage = 100;
  }

  ngOnInit() {
    this.idTypes = ['V', 'E', 'P'];

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      /**
       * If it is a NavigationEnd event, re-initalize the component.
       * This is useful when the current section is clicked on at the sidebar.
       */
      if (e instanceof NavigationEnd) {
        this.initialize();
      }
    });
    this.initialize();
  }

  scrollToTop(top) {
    top.scrollTop = 0;
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Initializes class' variables values
   * Sets businessTeam if specified. Fetch it otherwise (when not a SuperAdmin)
   */
  initialize() {
    this.businessTeam = undefined;
    this.focusedUser = undefined;
    this.departments = undefined;
    this.getEditForm();
    this.activeOrderMode = -1;
    this.isAdmin = this.user.isAdmin();
    this.isSuperAdmin = this.user.isSuperAdmin();
    if (this.navigationService.params) {
      setTimeout(() => {
        this.setBusinessTeam(this.navigationService.params.businessTeam);
        this.navigationService.params = undefined;
      });
    } else if (!this.isSuperAdmin) {
      setTimeout(() => {
        this.setBusinessTeam();
      });
    }
  }

  /**
   * Sets new business team when selected from business dropdown
   * and updates its users
   * @param selectedCompany - Object with selected company data
   */
  onCompanyUpdated(selectedCompany: any) {
    this.businessTeam = selectedCompany;
    this.getUsers();
  }

  /**
   * Sets selected user from users table
   * @param user - Object with user data
   */
  setFocusedUser(user: any) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.cancelEdit();
    this.focusedUser = user;
  }

  /**
   * Determines if user from users table is selected for details
   * @param user - Object with user data
   */
  isFocused(user: UserProfile) {
    return this.focusedUser && this.focusedUser.email === user.email;
  }

  /**
   * Shows user data edition form, initializing and fetching required data
   */
  showForm() {
    if (!this.departments || !this.roles) {
      this.getDepartments();
      this.getRoles();
    }

    this.errorMessage = undefined;
    this.idTypes = ['V', 'E', 'P'];

    const callCode = this.focusedUser.phone.match(/\+[0-9]+/g)[0];
    const phone = this.focusedUser.phone.match(/[0-9]{10}/g)[0];

    this.editForm.patchValue({
      firstName: this.focusedUser.first_name,
      lastName: this.focusedUser.last_name,
      documentType: this.focusedUser.type_document,
      document: this.focusedUser.document,
      email: this.focusedUser.email,
      callCode: callCode,
      phone: phone,
      role: this.userUtils.getRole(this.focusedUser, this.businessTeam.id).display_name,
      department: this.userUtils.getRole(this.focusedUser, this.businessTeam.id).department || ''
    });

    this.editForm.updateValueAndValidity();
    this.showEditForm = true;
  }

  /**
   * Performs user modifications reques to PANA's API
   * Hides edition form and updates both list of users and focused
   * user when success.
   */
  editUser() {
    if (!this.editForm.valid) {
      return;
    }
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);
    const requestBody = Object.assign({}, this.editForm.value);

    requestBody.phone = '(' + requestBody.callCode + ')' + requestBody.phone;
    requestBody.first_name = requestBody.firstName;
    requestBody.last_name = requestBody.lastName;
    requestBody.type_document = requestBody.documentType;
    this.api.editBusinessUser(this.businessTeam.id, this.focusedUser.id, requestBody)
      .subscribe(
        data => {
          this.users = data.users;
          this.cancelEdit();
          this.updateFocused();
          this.loaderService.showLoader(false);
        },
        error => {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );


  }

  /**
   * Updates focused user data after modification from
   * a updated list of users
   */
  updateFocused() {
    const updatedFocused = this.users.find(
      (user: any) => {
        return user.id === this.focusedUser.id;
      }
    );

    this.focusedUser = updatedFocused;
  }

  /**
   * Sets focused user and shows edition form
   * @param user - Object with selected user data from users table
   */
  setAndEdit(user: any) {
    this.setFocusedUser(user);
    this.showForm();
  }

  /**
   * Resets and hides user edition form
   */
  cancelEdit() {
    this.editForm.reset();
    this.errorMessage = undefined;
    this.showEditForm = false;
  }

  /**
   * Initializes a new user edition form and sets its validators
   */
  getEditForm() {
    const editForm = new UserEditForm;
    this.editForm = this.formBuilder.group(
      editForm,
      {
        validator: [
          this.invalidPhone,
          this.invalidIdentification
        ]
      }
    );
    Object.keys(this.editForm.controls).forEach(field => {
      this.editForm.get(field).setValidators(
        editForm.getValidators(field)
      );
    });
  }

  /**
   * Class' form validator: checks wheter a given venezuelan phone number
   * is valid or not
   * @param editForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidPhone(editForm: FormGroup) {
    const vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
    const vzlanPhone = editForm.controls.callCode.value === '+58';
    if (vzlanPhone && !vzlaPhoneRegex.test(editForm.controls.phone.value)) {
      return { 'invalid-phone': true };
    }
  }

  /**
   * Navigates to Dashboard Section, initializing it with selected
   * user data.
   * @param user - Object with selected user data from users table
   */
  requestService(user: any) {
    this.cancelEdit();
    this.navigationService.params = {user: user};
    this.router.navigate(['/service']);
  }

  /**
   * Navigates to Mass Load section, initializing it with current
   * business team
   */
  massLoad() {
    this.cancelEdit();
    this.navigationService.params = {businessTeam: this.businessTeam};
    this.router.navigate(['/user-management/mass-load']);
  }

  /**
   * Performs employees CSV file download request to PANA's API
   */
  downloadCSV() {
    this.cancelEdit();
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);
    this.api.downloadCorpUSersFile(this.businessTeam.id)
    .subscribe(
      data => {
        /**
         * Creates an invisible <a> DOM object and clicks it
         * to start file download
         */
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.businessTeam.display_name + ' - Empleados.csv';
        a.click();

        window.URL.revokeObjectURL(url);
        this.loaderService.showLoader(false);
      },
      error => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  // User table helper methods definition
  getTableElements() {
    return this.userFilter.transform(this.users, this.searchText, this.businessTeam.id);
  }

  updateSearchText(newSearchText: string) {
    if (!this.isFirstPage()) {
      this.firstPage();
    }
    this.searchText = newSearchText;
  }

  isFirstPage() {
    return this.page === 1;
  }

  firstPage() {
    this.page = 1;
  }

  lastPage() {
    this.page = this.getLastPage();
  }

  previousPage() {
    if ( this.page !== 1 ) {
      this.page -= 1;
    }
  }

  nextPage() {
    if (this.page !== this.getLastPage()) {
      this.page += 1;
    }
  }

  changePage(index) {
    this.page = index;
  }

  range(index) {
    const fillRange = (start, end) => {
      return Array(end - start + 1).fill(null).map((_, i) => start + i);
    };

    return fillRange(index - 2, index + 2);
  }

  getLastPage() {
    return Math.ceil(this.getTableElements().length / this.usersPerPage);
  }

  orderByKey(key) {
    const userOrdering = (user, other) => {
      const comparator = (a, b) => {
        if (a[key] < b[key]) {
          return -1 * this.activeOrderMode;
        } else if (a[key] > b[key]) {
          return 1 * this.activeOrderMode;
        }
        return 0;
      };

      const dateComparator = (a, b) => {
        const date1 = new Date(a[key]);
        const date2 = new Date(b[key]);

        if (date1 < date2) {
          return -1 * this.activeOrderMode;
        } else if (date1 > date2) {
          return 1 * this.activeOrderMode;
        }
        return 0;
      };

      if (user[key]) {
        return comparator(user, other);
      }

      const role = this.userUtils.getRole(user, this.businessTeam.id);
      if (role[key]) {
        const role2 = this.userUtils.getRole(other, this.businessTeam.id);
        return comparator(role, role2);
      }

      const subs = this.userUtils.getActiveSubscription(user);
      const subs2 = this.userUtils.getActiveSubscription(other);

      return dateComparator(subs, subs2);
    };

    if (key === this.activeOrderKey) {
      this.activeOrderMode *= -1;
    } else {
      this.activeOrderMode = 1;
    }

    this.activeOrderKey = key;

    this.users.sort(userOrdering);
  }
  // End of user table helper definition

  /**
   * Sets business team data. This data can be passed as a parameter
   * or fetched from user's profile. Updates list of employees afterwards.
   * @param team? - Object with team data
   */
  private setBusinessTeam(team?: any): void {
    if (team) {
      this.businessTeam = team;
    } else {
      const businessRole = this.user.getBusinessSubscription();
      this.businessTeam = businessRole.team;
    }
    this.getUsers();
  }

  /**
   * Class' form validator: checks wheter a given venezuelan ID number
   * is valid or not
   * @param registrationForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidIdentification(registrationForm: FormGroup) {
    const idCardRegex = /^[0-9]{7,9}$/;
    const pasRegex = /^[a-zA-Z0-9]{5,10}$/;
    let documentValue: string;
    documentValue = registrationForm.controls.document.value;
    let isValid: boolean;
    switch (registrationForm.controls.documentType.value) {
      case 'V':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'E':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'P':
        isValid = pasRegex.test(documentValue);
        break;
      default:
        isValid = false;
        break;
    }
    return isValid ? null : { 'invalid-identification': true };
  }


  /**
   * Performs users list request for a given company to PANA's API.
   * Updates list of users if succeeds.
   */
  private getUsers(): void {
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);

    this.api.getTeamMembers(this.businessTeam.id).subscribe(
      data => {
        if (data.data.length > 0) {
          this.users = data.data;
        } else {
          this.usersLoaded = true;
        }
        this.loaderService.showLoader(false);
      },
      _ => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  /**
   * Performs available roles request to PANA's API.
   * Updates list of roles if succeeds.
   */
  private getRoles() {
    this.api.getBusinessRoles().subscribe(
      data => {
        this.roles = data.roles;
      }
    );
  }

  /**
   * Performs departments list request for a given company to PANA's API.
   * Updates list of departments if succeeds.
   */
  private getDepartments() {
    this.api.getBusinessDepartments(this.businessTeam.id).subscribe(
      data => {
        this.departments = data.data;
        if (this.departments.length < 1) {
          this.editForm.controls.department.disable();
        }
      }
    );
  }

  get f() { return this.editForm.controls; }

}
