<ng-template #modifyDepartmentModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-template [ngIf]="!showConfirmation" [ngIfElse]="confirmationStep">
      <div class="content">
        <div class="text-center mb-3">
          <p>Vas a modificar el nombre del área</p>
        </div>
        <div class="text-center mb-3">
          <h2>{{ departmentName | titlecase }}</h2>
        </div>
        <div class="w-100 text-center">
          <form>
            <mat-form-field class="centered w-100">
              <input 
                matInput
                appAutofocus
                type="text" 
                placeholder="Indica el Nuevo Nombre" 
                [(ngModel)]="newDepartmentName" 
                [ngModelOptions]="{standalone: true}" 
                id="departmentNameInput">
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!newDepartmentName" (click)="showConfirmation = true">
          ACEPTAR
        </button>
      </div>
    </ng-template>
    <ng-template #confirmationStep>
      <div class="content">
        <div class="text-center mb-3">
          <p>Modificarás el área</p>
        </div>
        <div class="text-center mb-3">
          <h2>{{ departmentName | titlecase }}</h2>
        </div>
        <div class="text-center mb-3">
          <p>por:</p>
        </div>
        <div class="text-center mb-3">
          <h2>{{ newDepartmentName | titlecase }}</h2>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-green mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="text-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button class="btn btn-pana-gradient w-100 mx-3" (click)="modifyDepartment()">
          DE ACUERDO
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>

<img src="/assets/Pencil-light.svg" height="20" width="20" name="cog" size="lg" class="clickable  mar-p" (click)="openModal(modifyDepartmentModal)" matTooltip="Modificar" matTooltipPosition="above">