import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { LoaderService } from '../../services/loader.service';
import { NavigationService } from '../../services/navigation.service';
import { UserRegistrationModel } from '../../models/user-registration.model';
import { StringsConstant } from '../../constants/strings.constant';
var UserRegistrationComponent = /** @class */ (function () {
    function UserRegistrationComponent(globalData, api, user, formBuilder, router, loaderService, navigationService) {
        this.globalData = globalData;
        this.api = api;
        this.user = user;
        this.formBuilder = formBuilder;
        this.router = router;
        this.loaderService = loaderService;
        this.navigationService = navigationService;
        this.matcher = new CustomErrorStateMatcher();
    }
    Object.defineProperty(UserRegistrationComponent.prototype, "f", {
        /**
         * Form's controls getter
         * @returns { [key: string]: AbstractControl; } - Form's controls hash
         */
        get: function () { return this.registrationForm.controls; },
        enumerable: true,
        configurable: true
    });
    UserRegistrationComponent.prototype.ngOnInit = function () {
        /**
         * Form creation and class variables initialization
         */
        this.idTypes = ['V', 'E', 'P'];
        this.existingEmail = false;
        this.existingDocument = false;
        this.registrationForm = this.formBuilder.group(new UserRegistrationModel, {
            validator: [
                this.mismatchingPasswords,
                this.invalidIdentification,
                this.invalidPhone
            ]
        });
        this.setFormControlsValidators();
        this.cleanErrors();
        /**
         * Checks whether section is activated via Login Section or not.
         * If so, patches email value to registration form instance and shows
         * the appropriate message.
         */
        this.emailFromLogin = false;
        if (this.navigationService.params) {
            if (this.navigationService.params.unregisteredEmail) {
                this.emailFromLoginStr = this.navigationService.params.unregisteredEmail;
                this.registrationForm.patchValue({
                    email: this.navigationService.params.unregisteredEmail
                });
                this.navigationService.params = undefined;
                this.registrationForm.updateValueAndValidity();
                this.emailFromLogin = true;
            }
        }
    };
    /**
     * Triggers chain of PANA's API request to perform registration
     */
    UserRegistrationComponent.prototype.onSubmit = function () {
        this.cleanErrors();
        /**
         * Performs registration request if appToken exists in local storage.
         * Performs app credentials request otherwise.
         */
        if (localStorage.getItem('appToken')) {
            this.requestRegister();
        }
        else {
            this.requestAppCredentials();
        }
    };
    /**
     * Enables email field in registration form
     */
    UserRegistrationComponent.prototype.enableEmailField = function () {
        this.registrationForm.get('email').enable();
        this.emailFromLogin = false;
    };
    /**
     * Class' form validator: checks if both new password and its confirmation
     * are the same.
     * @param registrationForm - Registration form instance
     * @returns Object - null if passwords match, 'missmatching-pass' error otherwise.
     */
    UserRegistrationComponent.prototype.mismatchingPasswords = function (registrationForm) {
        var pass1 = registrationForm.controls.password.value;
        var pass2 = registrationForm.controls.password2.value;
        return pass1 === pass2 ? null : { 'missmatching-pass': true };
    };
    /**
     * Class' form validator: checks wheter a given venezuelan ID number
     * is valid or not
     * @param registrationForm - Registration form instance
     * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
     */
    UserRegistrationComponent.prototype.invalidIdentification = function (registrationForm) {
        var idCardRegex = /^[0-9]{7,9}$/;
        var pasRegex = /^[a-zA-Z0-9]{5,10}$/;
        var documentValue;
        documentValue = registrationForm.controls.document.value;
        var isValid;
        switch (registrationForm.controls.documentType.value) {
            case 'V':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'E':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'P':
                isValid = pasRegex.test(documentValue);
                break;
            default:
                isValid = false;
                break;
        }
        return isValid ? null : { 'invalid-identification': true };
    };
    /**
     * Class' form validator: checks wheter a given venezuelan phone number
     * is valid or not
     * @param registrationForm - Registration form instance
     * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
     */
    UserRegistrationComponent.prototype.invalidPhone = function (registrationForm) {
        var vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
        var vzlanPhone = registrationForm.controls.callCode.value === '+58';
        if (vzlanPhone && !vzlaPhoneRegex.test(registrationForm.controls.phone.value)) {
            return { 'invalid-phone': true };
        }
    };
    /**
     * Sets form controls validator functions
     */
    UserRegistrationComponent.prototype.setFormControlsValidators = function () {
        var _this = this;
        var nameRegex = /^[áéíóúÁÉÍÓÚñÑa-zA-Z,\.'\-\ ]+$/;
        // tslint:disable-next-line:max-line-length
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var callCodeRegex = /^\+[0-9]{1,4}$/;
        var phoneRegex = /^[0-9]{10}$/;
        Object.keys(this.registrationForm.controls).forEach(function (key) {
            /* We mark all form fields as required since all of them are required :) */
            var validators = [Validators.required];
            if (key === 'email') {
                validators.push(Validators.pattern(emailRegex));
            }
            else if (key === 'firstName' || key === 'lastName') {
                validators.push(Validators.pattern(nameRegex));
            }
            else if (key === 'phone') {
                validators.push(Validators.pattern(phoneRegex));
            }
            else if (key === 'callCode') {
                validators.push(Validators.pattern(callCodeRegex));
            }
            else if (key === 'password') {
                validators.push(Validators.minLength(8));
            }
            _this.registrationForm.get(key).setValidators(validators);
        });
    };
    /**
     * Performs app credentials request to PANA's API.
     * Sets the app token and performs registration request when success.
     */
    UserRegistrationComponent.prototype.requestAppCredentials = function () {
        var _this = this;
        this.loaderService.showLoader(true);
        this.api.appAccessToken()
            .subscribe(function (data) {
            var appToken = data.access_token;
            _this.user.setAppToken(appToken);
            _this.requestRegister();
        }, function (error) {
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    /**
     * Creates request body object from registrarion form instance
     * @returns requestBody - Object with the required data to user registration request
     */
    UserRegistrationComponent.prototype.getRequestBody = function () {
        var result = Object.assign({}, this.registrationForm.value);
        var requestBody = {
            first_name: result.firstName,
            last_name: result.lastName,
            call_code: result.callCode,
            phone: result.phone,
            email: result.email,
            type_document: result.documentType,
            document: result.document,
            password: result.password
        };
        return requestBody;
    };
    /**
     * Performs user registration request to PANA's API.
     * Redirects to Login Section if succeeds, shows the appropriate
     * error message otherwise.
     */
    UserRegistrationComponent.prototype.requestRegister = function () {
        var _this = this;
        var requestBody = this.getRequestBody();
        this.api.register(requestBody)
            .subscribe(function (data) {
            _this.router.navigate(['/login'], { queryParams: { fromRegister: 'true' } });
            _this.loaderService.showLoader(false);
        }, function (error) {
            if (error.status === 422) {
                console.log(error);
                var messageObject = error.error.errors;
                _this.existingDocument = messageObject.hasOwnProperty('document');
                _this.existingEmail = messageObject.hasOwnProperty('email');
            }
            else {
                _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            }
            _this.loaderService.showLoader(false);
        });
    };
    /**
     * Makes sure to hide any error message that could be shown to the user.
     */
    UserRegistrationComponent.prototype.cleanErrors = function () {
        this.errorMessage = undefined;
        this.existingEmail = false;
        this.existingDocument = false;
    };
    return UserRegistrationComponent;
}());
export { UserRegistrationComponent };
