import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoginComponent } from '../sections/login/login.component';

export class CustomErrorStatePassword implements ErrorStateMatcher {
  constructor(
    private component: LoginComponent
  ) {}
  
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isControlInvalid = control && control.invalid;
    const isControlTouched = control && control.touched;
    if (this.component.errorMessage || (isControlInvalid && isControlTouched)) {
      return true;  
    }
    return false;
  //   const isFormInvalid = form && form.invalid;
  //   return !!((isFormInvalid || isControlInvalid) && isControlTouched);
  }
}