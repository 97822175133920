<ng-template #addCreditCardModal>
  <div class="modal-body">
    <div class="content" [ngClass]="{'hide-card': hideCardInputs}">
      <form [formGroup]="addCreditCardForm" class="w-100">
        <div class="row m-0 w-100">
          <div class="col-12 p-0">
            <label class="input-label-stripe" [ngClass]="{'text-white': cardInputFocused}"> Tarjeta </label>
            <div id="card-element" [ngClass]="{'thide-card-input': !showCardInput, 'show-card-input': showCardInput}" 
            style="border-bottom: 1px solid #999; padding-bottom: 8px;"></div>
            <div class="stripe-error-container">
              <div *ngIf="stripeErrorMessage" class="stripe-error">
                {{ stripeErrorMessage }}
              </div>
            </div>
          </div>
          <!-- Name -->
          <div class="col-12 mt-3 p-0">
            <mat-form-field class="small w-100">
              <input matInput name="cardholder-name" type="text" formControlName="name" placeholder="Nombre del tarjetahabiente" [maxlength]="16">
              <mat-error *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)">
                <div *ngIf="f.name.errors.required">
                  Por favor, introduce el nombre del tarjetahabiente
                </div>
                <div *ngIf="f.name.errors.pattern">
                  Por favor, introduce un nombre válido 
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- phone -->
          <!-- <div class="col-12 mt-3 p-0 d-flex justify-content-start">
            <int-phone-prefix class="phone-prefix small" [defaultCountry]="'ve'" [locale]="'es'" formControlName="callCode">
            </int-phone-prefix>
            <mat-form-field class="small flex-grow-1 phone-field">
              <input matInput id="phone" type="text" formControlName="phone" placeholder="Teléfono" [maxLength]="10"
                [errorStateMatcher]="matcher">
                <mat-error *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)">
                  <div *ngIf="f.phone.errors.required">
                    Por favor, introduce un teléfono válido
                  </div>
                  <div *ngIf="f.phone.errors.pattern">
                    Por favor, introduce un teléfono celular válido
                  </div>
                </mat-error>
            </mat-form-field>
          </div> -->
        </div>
      </form>
    </div>
    <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
      <div [ngClass]="{'hide-card': hideCardInputs}" >
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="addCreditCardForm.invalid" (click)="addPaymentMethod()">
          AÑADIR
        </button>
      </div>
    </div>
    <ng-template #responseStatus [ngIf]="hideCardInputs">
      <div class="content">
        <div class="text-center" *ngIf="!errorMessage">
          <img src="/assets/check-green.svg" width="50" height="50" >
          <h2 class="margin-text">Tarjeta agregada</h2>
          <p>La tarjeta ha sido guardada exitosamente</p>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-pink mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-pana-gradient w-100 mx-3" (click)="closeModal()">
          {{ 'Cerrar' | uppercase }}
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>
<button class="btn btn-pana-gradient w-100 px-5" (click)="openModal(addCreditCardModal)">
  {{ 'Añadir Tarjeta' | uppercase }}
</button>