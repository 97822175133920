/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../components/employees-dropdown/employees-dropdown.component.ngfactory";
import * as i4 from "../../components/employees-dropdown/employees-dropdown.component";
import * as i5 from "../../services/global-data.service";
import * as i6 from "../../utils/user.utils";
import * as i7 from "../../services/api.service";
import * as i8 from "../../services/user.service";
import * as i9 from "../../services/navigation.service";
import * as i10 from "../../components/service-map/service-map.component.ngfactory";
import * as i11 from "../../components/service-map/service-map.component";
import * as i12 from "@agm/core/services/maps-api-loader/maps-api-loader";
import * as i13 from "../../services/loader.service";
import * as i14 from "../../components/generic-confirmation-modal/generic-confirmation-modal.component.ngfactory";
import * as i15 from "../../components/generic-confirmation-modal/generic-confirmation-modal.component";
import * as i16 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i17 from "../../components/service-cancellation-modal/service-cancellation-modal.component.ngfactory";
import * as i18 from "../../components/service-cancellation-modal/service-cancellation-modal.component";
import * as i19 from "./dashboard.component";
import * as i20 from "@angular/router";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "my-2 my-sm-3 mx-auto mx-sm-6"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "employees-dropdown": 0, "sm-employees-dropdown": 1, "md-employees-dropdown": 2, "lg-employees-dropdown": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-employees-dropdown", [], null, [[null, "selectedEmployeeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedEmployeeChange" === en)) {
        var pd_0 = ((_co.selectedEmployee = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EmployeesDropdownComponent_0, i3.RenderType_EmployeesDropdownComponent)), i1.ɵdid(4, 114688, null, 0, i4.EmployeesDropdownComponent, [i5.GlobalDataService, i6.User, i7.ApiService, i8.UserService, i9.NavigationService], { pending: [0, "pending"], selectedEmployee: [1, "selectedEmployee"] }, { selectedEmployeeChange: "selectedEmployeeChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "my-2 my-sm-3 mx-auto mx-sm-6"; var currVal_1 = _ck(_v, 2, 0, _co.globalData.ltSm(), _co.globalData.ltMd(), _co.globalData.ltLg(), !_co.globalData.ltLg()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = true; var currVal_3 = _co.selectedEmployee; _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_DashboardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex align-items-center justify-content-center w-100 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "spinner-border pana-spinner"], ["role", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
function View_DashboardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-map", [["class", "d-flex flex-grow-1 position-relative"]], null, [[null, "latChange"], [null, "lngChange"], [null, "googleAddressChange"], [null, "referenceChange"], [null, "mapFailedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("latChange" === en)) {
        var pd_0 = ((_co.lat = $event) !== false);
        ad = (pd_0 && ad);
    } if (("lngChange" === en)) {
        var pd_1 = ((_co.lng = $event) !== false);
        ad = (pd_1 && ad);
    } if (("googleAddressChange" === en)) {
        var pd_2 = ((_co.googleAddress = $event) !== false);
        ad = (pd_2 && ad);
    } if (("referenceChange" === en)) {
        var pd_3 = ((_co.reference = $event) !== false);
        ad = (pd_3 && ad);
    } if (("mapFailedChange" === en)) {
        var pd_4 = ((_co.mapFailed = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i10.View_ServiceMapComponent_0, i10.RenderType_ServiceMapComponent)), i1.ɵdid(1, 638976, null, 0, i11.ServiceMapComponent, [i12.MapsAPILoader, i13.LoaderService, i5.GlobalDataService], { pending: [0, "pending"], status: [1, "status"], clientInfo: [2, "clientInfo"], lat: [3, "lat"], lng: [4, "lng"], googleAddress: [5, "googleAddress"], reference: [6, "reference"], mapFailed: [7, "mapFailed"] }, { latChange: "latChange", lngChange: "lngChange", googleAddressChange: "googleAddressChange", referenceChange: "referenceChange", mapFailedChange: "mapFailedChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pending; var currVal_1 = _co.status; var currVal_2 = _co.clientInfo; var currVal_3 = _co.lat; var currVal_4 = _co.lng; var currVal_5 = _co.googleAddress; var currVal_6 = _co.reference; var currVal_7 = _co.mapFailed; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_DashboardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-sm-10 col-xl-8 offset-sm-1 offset-xl-2 mt-4 px-4 text-center flex-basis-unset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "alert m-0 text-green"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Parece que no tienes una suscripci\u00F3n activa. Por favor, suscr\u00EDbete a trav\u00E9s de la app para poder hacer uso del servicio "]))], null, null); }
function View_DashboardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-generic-confirmation-modal", [], null, null, null, i14.View_GenericConfirmationModalComponent_0, i14.RenderType_GenericConfirmationModalComponent)), i1.ɵdid(1, 114688, null, 0, i15.GenericConfirmationModalComponent, [i16.NgbModal], { modalTitle: [0, "modalTitle"], modalText: [1, "modalText"], buttonText: [2, "buttonText"], btnStyles: [3, "btnStyles"], confirmText: [4, "confirmText"], onConfirm: [5, "onConfirm"], cancelText: [6, "cancelText"], isDisabled: [7, "isDisabled"] }, null), i1.ɵpod(2, { "btn-pana-gradient m-0": 0, "w-100": 1, "w-75": 2, "w-50": 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Vas a solicitar un servicio en:"; var currVal_1 = _co.googleAddress; var currVal_2 = "SOLICITAR SERVICIO"; var currVal_3 = _ck(_v, 2, 0, true, (!_co.globalData.ltSm() && _co.globalData.ltLg()), !_co.globalData.ltLg(), _co.globalData.ltSm()); var currVal_4 = "\u00A1Ayuda!"; var currVal_5 = (_co.hasDependents ? _co.dependentRequestService.bind(_co) : _co.requestService.bind(_co)); var currVal_6 = "Cambiar"; var currVal_7 = (_co.incompleteData() || !_co.hasActiveSubscription); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_DashboardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_6)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-sm-4 offset-sm-4 my-4 text-center flex-basis-unset"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasActiveSubscription; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.showLoader; _ck(_v, 4, 0, currVal_1); }, null); }
function View_DashboardComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-sm-4 offset-sm-4 my-4 text-center flex-basis-unset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-service-cancellation-modal", [], null, null, null, i17.View_ServiceCancellationModalComponent_0, i17.RenderType_ServiceCancellationModalComponent)), i1.ɵdid(2, 114688, null, 0, i18.ServiceCancellationModalComponent, [i5.GlobalDataService, i16.NgbModal, i7.ApiService, i9.NavigationService], { hasDependents: [0, "hasDependents"], petitionId: [1, "petitionId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasDependents; var currVal_1 = _co.petitionId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "m-0 p-0 w-100 d-flex flex-grow-1 flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["cancelRequest", 2]], null, 0, null, View_DashboardComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.hasDependents && _co.activeChecked) && !_co.pending) && !_co.showLoader); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.activeChecked; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.activeChecked; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.pending; var currVal_4 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_3, currVal_4); }, null); }
function View_DashboardComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container-fluid flex-grow-1 d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-12 text-center text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["height", "100"], ["src", "/assets/skull.svg"], ["width", "100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12 text-center text-white mar-skull"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ha ocurrido un error cargando el mapa"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 text-center text-white pb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Por favor, recarga la p\u00E1gina y as\u00E9gurate de tener internet"]))], null, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "d-flex flex-column h-100 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "pl-3 pl-md-5 my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Solicitar Servicio"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.mapFailed; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.mapFailed; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 245760, null, 0, i19.DashboardComponent, [i8.UserService, i7.ApiService, i9.NavigationService, i5.GlobalDataService, i13.LoaderService, i6.User, i20.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i19.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
