import { Component, OnInit, Input  } from '@angular/core';

import { ApiService } from 'src/app/services/api.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';
import { StringsConstant } from '../../constants/strings.constant';
import { Team } from 'src/app/interfaces/team.interface';
import { UserRol } from 'src/app/interfaces/user-rol.interface';
import { ProgressBarEventHandler } from 'src/app/utils/progress-bar-event-handler';
import { ServicesNames } from 'src/app/constants/services-names.constant';
import { InvoiceStatus as InvoiceStatusMapping } from 'src/app/string-mappings/invoice-status.mapping';
import { formatDate } from '@angular/common';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})

export class InvoicesComponent implements OnInit {
  @Input() users: Array<UserProfile>;

  businessTeam: Team;
  invoices: any;
  paymentMethods: any;
  currentInvoiceSummary: any;
  isSuperAdminView: boolean;
  errorMessage: string;

  Object = Object;
  ServiceNames = ServicesNames;
  InvoiceStatusMapping = InvoiceStatusMapping;

  constructor(
    private user: UserService,
    private api: ApiService,
    private navigationService: NavigationService,
    private loadingScreen: LoadingScreenService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.setInitialState();
  }

  /**
   * Sets component's variables necessary for the initial state of the view.
   * Sets business team data if the user is an admin or if specified in the
   * navigation parameters and the user is a super admin.
   */
  setInitialState(): void {
    this.businessTeam = undefined;
    this.isSuperAdminView = this.user.isSuperAdmin();

    if (this.navigationService.params) {
      setTimeout(() => {
        this.setBusinessTeam(this.navigationService.params.businessTeam);
        this.setInvoicesData();
        this.setPaymentMethodsData();
        this.navigationService.params = undefined;
      });
    } else if (!this.isSuperAdminView) {
      setTimeout(() => {
        this.setBusinessTeam();
        this.setInvoicesData();
        this.setPaymentMethodsData();
      });
    }
  }

  /**
   * Sets business team data. This data can be passed as a parameter
   * or fetched from user's profile.
   *
   * @param team? - Object with business team data
   */
  private setBusinessTeam(team?: Team): void {
    if (team) {
      this.businessTeam = team;
    } else {
      const businessRole: UserRol = this.user.getBusinessSubscription();
      this.businessTeam = businessRole.team;
    }
  }

  /**
   * Sets invoices data variables. Includes the invoices history data,
   * and the resume of the current period subscriptions, unsubscriptions
   * and renovations. It is necessary the business team data is set.
   */
  private setInvoicesData(): void {
    this.getInvoicesHistory();
    this.getCurrentInvoiceSummary();
  }

  /**
   * Sets payment methods data variables.
   */
  private setPaymentMethodsData(): void {
    this.getPaymentMethods();
  }

  /**
   * Sets new business team data when selected from business dropdown.
   *
   * @param selectedBusiness - Object with selected business team data
   */
  onCompanyUpdated(selectedBusiness: Team): void {
    this.businessTeam = selectedBusiness;
    this.setInvoicesData();
    this.setPaymentMethodsData();
  }

  /**
   * Sets the invoices history data. This data is fetched from the server
   * trough the api. It is necessary the business team data is set.
   */
  getInvoicesHistory(): void {
    this.api.getInvoicesHistory(this.businessTeam.id).subscribe(invoices => {
      this.invoices = invoices;
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not load invoices history', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  getCurrentInvoiceSummary(): void {
    this.loaderService.showLoader(true);
    this.api.getCurrentInvoiceSummary(this.businessTeam.id).subscribe(summary => {
      this.currentInvoiceSummary = summary;
      this.loaderService.showLoader(false);
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not load current invoice summary', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  getPaymentMethods(): void {
    this.api.getBusinessPaymentMethods(this.businessTeam.id).subscribe(paymentMethods => {
      this.paymentMethods = paymentMethods;
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not load payment methods', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  showInvoice(invoice: any): void {
    this.loadingScreen.enable();
    const invoicePreview = window.open('loading');
    invoicePreview.blur();
    window.focus();
    const invoiceId = invoice.id;
    const handlers = {
      downloadEvent: ProgressBarEventHandler.previewProgressHandler,
      reportMessage: ProgressBarEventHandler.showProgressBar
    };
    this.errorMessage = undefined;
    this.api.downloadBusinessInvoiceDetailFile(this.businessTeam.id, invoiceId, handlers).subscribe(file => {
      const url = window.URL.createObjectURL(file);
      invoicePreview.location.href = url;
      invoicePreview.focus();
      this.loadingScreen.disable();
      window.URL.revokeObjectURL(url);
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not donwload invoice detail', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  downloadInvoice(invoice: any): void {
    const invoiceId = invoice.id;
    const handlers = {
      downloadEvent: ProgressBarEventHandler.downloadProgressHandler,
      reportMessage: ProgressBarEventHandler.showProgressBar
    };
    this.errorMessage = undefined;
    this.api.downloadBusinessInvoiceDetailFile(this.businessTeam.id, invoiceId, handlers).subscribe(file => {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Recibo PANA ${formatDate(invoice.created_at, 'MMMM y', 'es-VE')}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not donwload invoice detail', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  showCurrentInvoiceSummary(): void {
    const handlers = {
      downloadEvent: ProgressBarEventHandler.previewProgressHandler,
      reportMessage: ProgressBarEventHandler.showProgressBar
    };
    this.errorMessage = undefined;
    this.api.downloadCurrentBusinessInvoiceSummaryFile(this.businessTeam.id, handlers).subscribe(file => {
      const url = window.URL.createObjectURL(file);
      window.open(url);
      window.URL.revokeObjectURL(url);
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not donwload invoice detail', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  downloadCurrentInvoiceSummary(): void {
    const handlers = {
      downloadEvent: ProgressBarEventHandler.downloadProgressHandler,
      reportMessage: ProgressBarEventHandler.showProgressBar
    };
    this.errorMessage = undefined;
    this.api.downloadCurrentBusinessInvoiceSummaryFile(this.businessTeam.id, handlers).subscribe(file => {
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Recibo.pdf';
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      // TODO: Add proper handling of this error
      console.error('ERROR: Could not donwload invoice detail', error);
      this.errorMessage = StringsConstant.UNKNOWN_ERROR;
    });
  }

  /**
   * Returns the date of the period charged on the invoice
   *
   * @param invoice The invoice data
   */
  getInvoicePeriodDate(invoice: any): Date {
    const createdAt = new Date (invoice.created_at);
    return new Date(createdAt.setMonth( createdAt.getMonth() - 1 ));
  }

  getCurrentInvoicePeriodBeginDate(): Date {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  getCurrentInvoicePeriodEndDate(): Date {
    return new Date();
  }

  setFavorite(paymentMethod) {
    this.paymentMethods.forEach(card => {
      if (card.id === paymentMethod.id) {
        card.metadata.default = true;
      } else {
        delete card.metadata.default;
      }
    });
  }

  deletePaymentMethod(paymentMethod) {
    let paymentMethodIndex = -1;
    this.paymentMethods.forEach((card, index) => {
      if (card.id === paymentMethod.id) {
        paymentMethodIndex = index;
      }
    });
    this.paymentMethods.splice(paymentMethodIndex, 1);
  }
  
  // openModal() {
  //   this.errorMessage = undefined;
  //   const userForm = new UserForm;
  //   this.modalRef = this.modalService.open(
  //     AddCreditCardComponent,
  //     {
  //       ariaLabelledBy: 'modal-basic-title',
  //       centered: true,
  //       backdrop: 'static',
  //       keyboard: false,
  //       windowClass: 'offset-0 offset-md-3 offset-lg-2',
  //       beforeDismiss: this.closeModal.bind(this)
  //     }
  //   );
  //   this.modalRef.result.then((result) => {
  //     console.log(`Closed with: ${result}`);
  //     this.addCard(result);
  //   }, (reason) => {
  //     console.log("Dimissed");
  //   });
  //   this.form = this.formBuilder.group(
  //     userForm,
  //     {
  //       validator: [
  //         this.invalidPhone,
  //       ]
  //     }
  //   );
  // }

  // openEditModal(invoice) {
  //   this.errorMessage = undefined;
  //   const userForm = new UserForm;

  //   this.modalRef = this.modalService.open(
  //     EditCreditCardComponent,
  //     {
  //       ariaLabelledBy: 'modal-basic-title',
  //       centered: true,
  //       backdrop: 'static',
  //       keyboard: false,
  //       windowClass: 'offset-0 offset-md-3 offset-lg-2',
  //       beforeDismiss: this.closeModal.bind(this)
  //     }
  //   );
  //   this.modalRef.result.then((result) => {
  //     console.log(`Closed with: ${result}`);
  //   }, (reason) => {
  //     console.log("Dimissed");
  //   });
  //   this.form = this.formBuilder.group(
  //     userForm,
  //     {
  //       validator: [
  //         this.invalidPhone,
  //       ]
  //     }
  //   );
  // }

  // private invalidPhone(registrationForm: FormGroup) {
  //   const vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
  //   const vzlanPhone = registrationForm.controls.callCode.value === '58';
  //   if (vzlanPhone && !vzlaPhoneRegex.test(registrationForm.controls.phone.value)) {
  //     return { 'invalid-phone': true };
  //   }
  // }

  // closeModal() {
  //   this.clearForm();
  //   this.serverResponse = false;
  //   this.errorMessage = undefined;
  //   this.dataValidatedFromServer = false;
  //   this.modalRef.close();
  // }

  // private clearForm(): void {
  //   this.form.reset();
  //   this.errorMessage = undefined;
  //   this.warningFromServer = undefined;
  // }

  // addCard(card){
  //   card['created_at'] = '2020-05-01 00:00:02';
  //   card['card_num'] = 'Xxx-5555';
  //   this.cards.push(card);
  // }

  // deleteCard(card){
  //   let index = this.cards.indexOf(card, 0);
  //   if (index > -1) {
  //     this.cards.splice(index, 1);
  //   }
  // }

  // updateCard(card){
  //   let index = this.cards.indexOf(card, 0);
  //   if (index > -1) {
  //     this.cards[index] = card;
  //   }
  // }
}
