import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(api, formBuilder, modalService, loaderService) {
        this.api = api;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.loaderService = loaderService;
        this.matcher = new CustomErrorStateMatcher();
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.success = false;
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),
            newPassword: new FormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),
            passwordConfirmation: new FormControl('', [
                Validators.required
            ])
        }, {
            validator: this.mismatchingPasswords
        });
        this.cleanMessages();
    };
    Object.defineProperty(ChangePasswordComponent.prototype, "f", {
        get: function () { return this.changePasswordForm.controls; },
        enumerable: true,
        configurable: true
    });
    ChangePasswordComponent.prototype.openModal = function (changePasswordModal) {
        this.cleanMessages();
        this.changePasswordForm.reset();
        this.modalRef = this.modalService.open(changePasswordModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'offset-0 offset-md-3 offset-lg-2'
        });
    };
    ChangePasswordComponent.prototype.closeModal = function () {
        this.cleanMessages();
        this.changePasswordForm.reset();
        this.modalRef.close();
    };
    ChangePasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.cleanMessages();
        var result = Object.assign({}, this.changePasswordForm.value);
        var requestBody = {
            old_password: result.oldPassword,
            password: result.newPassword
        };
        this.loaderService.showLoader(true);
        this.api.changePassword(requestBody).subscribe(function (data) {
            _this.success = true;
            _this.changePasswordForm.reset();
            _this.loaderService.showLoader(false);
        }, function (error) {
            if (error.status === 422) {
                _this.errorMessage = error.error.errors.old_password;
                _this.loaderService.showLoader(false);
            }
        });
    };
    ChangePasswordComponent.prototype.cleanMessages = function () {
        this.errorMessage = undefined;
        this.success = false;
    };
    ChangePasswordComponent.prototype.mismatchingPasswords = function (changePasswordForm) {
        var pass1 = changePasswordForm.controls.newPassword.value;
        var pass2 = changePasswordForm.controls.passwordConfirmation.value;
        return pass1 === pass2 ? null : { 'missmatching-pass': true };
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
