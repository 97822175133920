<div #focosusersection class="d-flex flex-column position-absolute w-100 h-100 vertical-scroll">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3>Gestionar Empleados</h3>
  </div>

  <!-- Business team selection for superadmins -->
  <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center main-section" *ngIf="!businessTeam && isSuperAdmin">
    <div class="w-100 text-center">
      <h4 class="text-gray">Selecciona la empresa:</h4>
    </div>
    <div class="row pt-4 m-0 w-100">
      <div class="col-sm-6 offset-sm-3 col-10 offset-1 p-0">
        <app-business-dropdown [company]="businessTeam" (companyChange)="onCompanyUpdated($event)"></app-business-dropdown>
      </div>
    </div>
  </div>

  <!-- Server error messages section -->
  <div class="d-flex flex-column justify-content-center align-items-center my-5" *ngIf="errorMessage">
    <img src="/assets/error-skull.svg" width="40" height="40" class="d-block" alt="">
    <p class="text-pink mt-1 mb-3 text-center">
      {{ errorMessage }}
    </p>
  </div>

  <!-- Employee information -->
  <div class="px-3 px-md-5 pb-4" *ngIf="businessTeam && focusedUser">
    <div class="w-100 d-flex justify-content-between align-items-center mb-3" *ngIf="focusedUser">
      <h4 class="text-gray">
        <span *ngIf="!showEditForm || (showEditForm && focusedUser.status !== 3)">{{ focusedUser.first_name + " " + focusedUser.last_name}}</span>
      </h4>
      <div *ngIf="!showEditForm" class="text-right">
        <img class="clickable" src="/assets/Pencil.svg" height="25" width="25" (click)="showForm()" matTooltip="Editar"
          matTooltipPosition="above" />
        <app-unsubscribe-business-user [user]="focusedUser" [(focusedUser)]="focusedUser" [(users)]="users" [companyId]="businessTeam.id"
          [size]="45" (btnClick)="cancelEdit()"></app-unsubscribe-business-user>
        <img class="clickable" src="/assets/Motorcycle.svg" height="25" width="25" (click)="requestService(focusedUser)" matTooltip="Pedir servicio" matTooltipPosition="above"/>
      </div>
      <div *ngIf="showEditForm" class="text-right">
        <!-- Undo-->
        <img class="clickable" src="/assets/Revert.svg" name="undo" height="25" width="25" class="clickable mar-undo" (click)="cancelEdit()" matTooltip="Deshacer cambios" matTooltipPosition="above">
        <!-- Save changes -->
        <img class="clickable" src="/assets/Check.svg" name="check" height="25" width="25"  class="clickable" (click)="editUser()" matTooltip="Guardar cambios" matTooltipPosition="above">
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 col-md-8 p-0 pb-3 pb-md-0 pr-md-3">
        <div class="card pana-card">
          <div class="card-body"  >
            <form [formGroup]="editForm">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-12 col-lg-8">
                  <div class="row h-100">
                    <!-- First Name -->
                    <div class="col-12 col-lg-6 py-3" *ngIf = "showEditForm && focusedUser.status == 3 ">
                      <h6 class = "text-gray">Nombre</h6>
                      <mat-form-field class = "small w-100" *ngIf="showEditForm">
                          <input matInput type="text" id="firstName" formControlName="firstName" [maxlength]="24">
                          <mat-error *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)">
                            <div *ngIf="f.firstName.errors.required">
                              Por favor, introduce el nombre del empleado
                            </div>
                            <div *ngIf="f.firstName.errors.pattern">
                              Por favor, introduce un nombre válido
                            </div>
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Last Name -->
                    <div class="col-14 col-md-6 py-3" *ngIf = "showEditForm && focusedUser.status == 3 ">
                      <h6 class = "text-gray">Apellido</h6 >
                      <mat-form-field class = "small w-100" *ngIf="showEditForm">
                          <input matInput type="text" formControlName="lastName" [maxlength]="24">
                          <mat-error *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)">
                            <div *ngIf="f.lastName.errors.required">
                              Por favor, introduce el apellido del empleado
                            </div>
                            <div *ngIf="f.lastName.errors.pattern">
                              Por favor, introduce un apellido válido
                            </div>
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Email -->
                    <div class="col-12 col-lg-6 py-3">
                      <h6 class="text-gray">Email</h6>
                      <h6 class="m-0" *ngIf = "!showEditForm || showEditForm && focusedUser.status !== 3" [ngClass]="{'padded': showEditForm}">{{ focusedUser.email }}</h6>
                      <mat-form-field class = "small w-100" *ngIf = "showEditForm && focusedUser.status == 3 ">
                        <input matInput type="text" formControlName="email" [maxlength]="40">
                        <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                          <div *ngIf="f.email.errors.required">
                            Por favor, introduce el correo del empleado
                          </div>
                          <div *ngIf="f.email.errors.pattern">
                            Por favor, introduce un correo válido
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- ID -->
                    <div class="col-12 col-md-6 py-3">
                      <h6 class="text-gray">Documento de Identidad</h6>
                      <h6 class="m-0" *ngIf = "!showEditForm || showEditForm && focusedUser.status !== 3" [ngClass]="{'padded': showEditForm}">{{ focusedUser.type_document + " - " + focusedUser.document }}</h6>
                      <mat-form-field class = "w-20 id-type-select mr-2 small" *ngIf = "showEditForm && focusedUser.status == 3 ">
                        <mat-select formControlName="documentType">
                          <mat-option *ngFor="let idType of idTypes" [value]="idType">
                            {{idType}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field class="w-60 small" *ngIf="showEditForm && focusedUser.status == 3" id="id-errormessage1">
                        <input matInput type="text" [maxlength]="10" formControlName="document" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="f.document.invalid && (f.document.dirty || f.document.touched)">
                          <div *ngIf="f.document.errors.required">
                            Por favor, introduce la identificación del empleado
                          </div>
                          <div *ngIf="f.document.errors.pattern">
                            Por favor, introduce una identificación válida
                          </div>
                        </mat-error>
                        <mat-error *ngIf="editForm.hasError('invalid-identification') && (f.document.dirty || f.document.touched) && !f.document.invalid">
                          <div>
                            Introduce una identificación válida
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <!-- Cell Phone -->
                    <div class="col-12 col-md-6 col-lg-5 py-3">
                      <h6 class="text-gray">Teléfono Celular</h6>
                      <h6 class="m-0" *ngIf="!showEditForm">{{ focusedUser.phone }}</h6>
                      <div class="d-flex justify-content-start" *ngIf="showEditForm" >
                        <int-phone-prefix
                          class="phone-prefix small"
                          [defaultCountry]="'ve'"
                          [locale]="'es'"
                          [maxLength]="5"
                          formControlName="callCode"></int-phone-prefix>
                        <mat-form-field class="small flex-grow-1  phone-field" id="phone-errormessage1">
                          <input matInput type="text" [maxlength]="10" formControlName="phone" [errorStateMatcher]="matcher">
                          <mat-error class="d-flex" *ngIf="editForm.controls.phone.invalid && (editForm.controls.phone.dirty || editForm.controls.phone.touched)">
                            <div *ngIf="editForm.controls.phone.errors.required">
                              Por favor, introduce el teléfono celular del empleado
                            </div>
                            <div *ngIf="editForm.controls.phone.errors.pattern">
                              Por favor, introduce un teléfono celular válido
                            </div>
                          </mat-error>
                          <mat-error *ngIf="editForm.hasError('invalid-phone') && (editForm.controls.phone.dirty || editForm.controls.phone.touched) && !editForm.controls.phone.invalid">
                            <div>
                              Por favor, introduce un teléfono celular válido
                            </div>
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-4">
                  <div class="row h-30 ">
                    <div class="col-12 col-sm-6 col-lg-12 py-3">
                    <!-- Area -->
                      <h6 class="text-gray">Área</h6>
                      <h6  class="m-0" *ngIf="!showEditForm ">{{ userUtils.getRole(focusedUser, businessTeam.id)?.department || 'N/A' }}</h6>
                      <mat-form-field class="small w-100" *ngIf="showEditForm">
                        <mat-select *ngIf="showEditForm" formControlName="department">
                          <mat-option value="">Sin especificar</mat-option>
                          <mat-option *ngFor="let d of departments" [value]="d.name">
                            {{d.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!-- Perfil -->
                    <div class="col-12 col-md-6 col-lg-12 py-3">
                      <h6 class="text-gray">Perfil</h6>
                      <h6  class="m-0" *ngIf="!showEditForm">{{ userUtils.getRole(focusedUser, businessTeam.id)?.display_name }}</h6>
                      <mat-form-field class="small w-100" *ngIf="showEditForm">
                        <mat-select formControlName="role">
                          <mat-option *ngFor="let r of roles" [value]="r.display_name">
                            {{r.display_name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 p-0 pt-3 pt-md-0 pl-md-3">
        <div class="card pana-card h-100">
          <div class="card-body">
            <div class="row">
              <div class="col-12 py-3">
                <h6 class="text-gray">Estado Suscripción</h6>
                <h6 class="m-0" [ngClass]="{'padded': showEditForm}">{{ focusedUser.subs_status_label }}</h6>
              </div>
              <div class="col-12 py-3">
                <h6 class="text-gray">Vencimiento de Subscripción</h6>
                <h6 class="m-0" [ngClass]="{'padded': showEditForm}">{{ userUtils.getActiveSubscription(focusedUser)?.expires_at |
                date:'d-MMM-y':'-0400':'es-VE' | replace : '.' : '' }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Employees table -->
  <div class="px-3 px-md-5 pb-4 " *ngIf="businessTeam" >
    <ng-template [ngIf]="users" [ngIfElse]="noUsers">
      <div class="w-100 d-flex justify-content-between align-items-center" >
        <input type="text" class="form-control input-pana-darker input-pana-busqueda" [ngModel]="searchText" (ngModelChange)="updateSearchText($event)"
          placeholder="Buscar Empleado">
        <!-- User per table -->
        <ng-template [ngIf]="!globals.ltSm()">
          <div class="w-7 mar-num" >
            <p class="text-gray mar-text">
              {{ (usersPerPage * (page - 1) + 1) }} -  {{math.min((usersPerPage * page), getTableElements().length)}} de {{getTableElements().length}}
            </p>
          </div>
          <!-- Next & Previous buttoms-->
          <div >
              <img class="clickable mar-previous" src="/assets/Previous.svg" height="45" width="45" (click)="previousPage()"
              matTooltip="Anterior" matTooltipPosition="above" *ngIf="getTableElements().length > usersPerPage" />
          </div>
          <div>
            <img class="clickable mar-next" src="/assets/Next.svg" height="45" width="45" (click)="nextPage()"
            matTooltip="Siguiente" matTooltipPosition="above" *ngIf="getTableElements().length > usersPerPage" />
          </div>
        </ng-template>
        <!-- Register users -->
        <div ngbDropdown placement="bottom-right">
          <img  class="clickable" src="/assets/Plus.svg" ngbDropdownToggle name="plus" height="25" width="25" id="register-users" class="clickable mar-plus text-gray" matTooltip="Ingresar usuarios" matTooltipPosition="above">
          <div ngbDropdownMenu aria-labelledby="register-users" class="pana-card">
            <div class="d-flex flex-column p-topbot p-right">
              <!-- Add a new user-->
              <div class="d-flex mb-3 mx-2 mt-2">
                <img src="/assets/Person.svg" height="30" width="30" name="user" size="lg" class="clickable text-gray mr-3">
                <app-create-user [businessId]="businessTeam.id" [(users)]="users" btnXS="true" class="d-flex align-items-center flex-grow-1"></app-create-user>
              </div>
              <!-- Massload -->
              <div class="d-flex mx-2 mb-2">
                <img src="/assets/Persons.svg" height="30" width="30" name="users" size="lg" class="clickable text-gray mr-3">
                <div class="flex-grow-1 d-flex align-items-center">
                  <div class="btn w-100 btn-pana-gradient-dark btn-xs m-0" (click)="massLoad()">
                    <div>
                      {{ 'carga masiva' | uppercase }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-generic-confirmation-modal icon="download" confirmText="Descargar" cancelText="Cancelar" modalTitle="Se descargará un CSV con los empleados de la empresa"
          modalText="¿Estás seguro que quieres realizar esta operación?" [onConfirm]="downloadCSV.bind(this)" tooltip="Descargar usuarios" tooltipPosition="above">
        </app-generic-confirmation-modal>
      </div>
      <div class="w-100 mt-3 table-responsive" >
        <table class="table table-bordered table-hover text-center" >
          <thead class="thead-dark">
            <tr>
              <th (click)="orderByKey('first_name')">Nombre</th>
              <th (click)="orderByKey('first_name')" class="px-0"></th>
              <th (click)="orderByKey('document')" class="wmin-6">Cédula</th>
              <th (click)="orderByKey('email')">Email</th>
              <th (click)="orderByKey('phone')">Teléfono</th>
              <th (click)="orderByKey('department')">Área</th>
              <th (click)="orderByKey('display_name')">Perfil</th>
              <th (click)="orderByKey('expires_at')" class="wmax-9" hidden>Vencimiento</th>
              <th class="wmin-7">Acciones</th>
            </tr>
          </thead>
          <tbody class="tbody-dark" id="prueba" >
            <ng-container *ngFor="let user of getTableElements() | paginate: { itemsPerPage: usersPerPage, currentPage: page }; let i = index" >
              <tr *ngIf="user == -1">
                <th colspan="9">No hay ningún empleado que coincida con su búsqueda</th>
              </tr>
              <tr *ngIf="user != -1
                         && activeIndex * usersPerPage <= i
                         && (activeIndex + 1) * usersPerPage > i"
                  [ngClass]="{ 'highlight-row': isFocused(user) }" (click)="scrollToTop(focosusersection)">
                <!-- Name -->
                <td class="clickable" (click)="setFocusedUser(user)" [innerHtml]="userUtils.getFullName(user) | highlight: searchText"></td>
                <!-- Green Check -->
                <td class="clickable px-0" (click)="setFocusedUser(user)">
                  <img src="/assets/check-green.svg" height="15" width="15" *ngIf="user.status !== 3" matTooltip="Registro completo" matTooltipPosition="above"/>
                </td>
                <!-- ID -->
                <td class="clickable" (click)="setFocusedUser(user)" [innerHtml]="user.type_document + '-' + user.document | highlight: searchText"></td>
                <!-- Email -->
                <td class="clickable wmax-9" (click)="setFocusedUser(user)" [innerHtml]="user.email | highlight: searchText" [matTooltip]="user.email" matTooltipPosition="above"></td>
                <!-- Phone -->
                <td class="clickable" (click)="setFocusedUser(user)" [innerHtml]="user.phone | highlight: searchText"></td>
                <!-- Department -->
                <td class="clickable" (click)="setFocusedUser(user)" [innerHtml]="(userUtils.getRole(user, businessTeam.id)?.department || 'N/A') | highlight: searchText"></td>
                <!-- Profile -->
                <td class="clickable" (click)="setFocusedUser(user)" [innerHtml]="userUtils.getRole(user, businessTeam.id)?.display_name | highlight: searchText"></td>
                <!-- Subscription -->
                <td class="clickable" (click)="setFocusedUser(user)" hidden>
                  <span *ngIf="userUtils.getActiveSubscription(user)">
                    {{ userUtils.getActiveSubscription(user)?.expires_at |
                    date:'d-MMM-y':'-0400':'es-VE' |
                    replace : '.' : '' }}
                  </span>
                  <span *ngIf="!userUtils.getActiveSubscription(user)">
                    Sin Suscripción
                  </span>
                </td>
                <!--Edit - Unsubscribe - Request Service-->
                <td>
                  <img class="clickable" src="/assets/Pencil-light.svg" height="20" width="20" (click)="setAndEdit(user)"
                    matTooltip="Editar" matTooltipPosition="above" />
                  <app-unsubscribe-business-user [user]="user" [(focusedUser)]="focusedUser" [(users)]="users" [companyId]="businessTeam.id"
                    [size]="15" (btnClick)="cancelEdit()" [clear]="true"></app-unsubscribe-business-user>
                  <img class="clickable" src="/assets/Motorcycle-light.svg" height="20" width="20" (click)="requestService(focusedUser)"
                  matTooltip="Pedir servicio" matTooltipPosition="above"/>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="d-flex flex-grow-1 flex-item ">
        <p class="text-gray ">
          {{ (usersPerPage * (page - 1) + 1) }} -  {{math.min((usersPerPage * page), getTableElements().length)}} de {{getTableElements().length}}
        </p>
        <div>
          <img class="clickable mar-arrows" src="/assets/Previous.svg" height="25" width="25" (click)="previousPage()"
          matTooltip="Anterior" matTooltipPosition="above" *ngIf="getTableElements().length > usersPerPage" />
        </div>
        <div>
          <img class="clickable mar-arrows mar-minext" src="/assets/Next.svg" height="25" width="25" (click)="nextPage()"
          matTooltip="Siguiente" matTooltipPosition="above" *ngIf="getTableElements().length > usersPerPage" />
        </div>
      </div>
    </ng-template>
    <ng-template #noUsers>
      <div *ngIf="usersLoaded">
        <div class="d-flex flex-column align-items-center justify-content-center mt-4">
          <p class="m-0 text-center">
            ¡Vaya! <br> Parece que no tenemos ningún empleado registrado en esta empresa<br>
            ¿Qué quieres hacer?
          </p>
          <div class="text-center w-100 mt-5">
              <app-create-user [businessId]="businessTeam.id" [(users)]="users" class="w-100 mx-3 d-inline-block"></app-create-user>
              <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="massLoad()">
                <div>
                  CARGA MASIVA
                </div>
              </div>
              <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="businessTeam = null">
                <div>
                  CAMBIAR EMPRESA
                </div>
              </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
