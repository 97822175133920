<ng-template #cancelRequestModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-container *ngIf="!showReasons; then confirmation; else reasons">
    </ng-container>
  </div>
</ng-template>

<ng-template #confirmation>
  <div class="content">
    <div class="text-center">
      <p>¿Seguro que deseas cancelar la solicitud?</p>
    </div>
  </div>
  <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
    <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
      <div>
        NO
      </div>
    </div>
    <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="confirmCancellation()">
      CANCELAR
    </button>
  </div>
</ng-template>

<ng-template #reasons>
  <div class="content">
    <div class="text-center">
      <p class="mb-5">¿Por qué deseas cancelar la solicitud?</p>
      <mat-form-field class="small centered w-100 mt-3">
        <mat-select [(ngModel)]="cancellationReason" placeholder="Selecciona la razón de cancelación">
          <mat-option *ngFor="let reason of cancellationReasons" [value]="reason.id">
            {{ reason.reason }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
    <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="goBack()">
      <div>
        ATRÁS
      </div>
    </div>
    <button type="button" class="btn btn-pana-gradient w-100 mx-3"
      [disabled]="!cancellationReason"
      (click)="hasDependents ? dependentRequestCancellation() : requestCancellation()"
    >
      CANCELAR
    </button>
  </div>
</ng-template>

<div
  class="btn btn-pana-gradient-dark m-0"
  (click)="openModal(cancelRequestModal)"
  [ngClass]="{
    'w-100': !globalData.ltSm() && globalData.ltLg(),
    'w-75': !globalData.ltLg(),
    'w-50': globalData.ltSm()
  }"
>
  <div>
    {{ 'Cancelar servicio' | uppercase }}
  </div>
</div>
