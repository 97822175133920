<div class="w-100">
  <div class="drop-down">
      <input type="text" class="input-pana-darker dropdown-input input-pana-busqueda" [ngClass]="{'dropdown-input-expanded': showDropDown}"
        [(ngModel)]="criteria" (click)="toogleDropDown()" (input)="showDropdown()" placeholder="Buscar empresa">
  </div>
  <div class="dropdown-container" *ngIf="showDropDown">
      <div *ngFor="let company of companies">
        <div class="dropdown-element d-flex align-items-center justify-content-between" (click)="selectCompany(company)"
          *ngIf="showCompany(company)">
          <div>
            <span class="my-0">
              {{ company.display_name }}
            </span>
            <br>
            <small>
              {{ company.document }}
            </small>
          </div>
          <div>
            <h6 class="my-0">
              ID {{company.id}}
            </h6>
          </div>
        </div>
      </div>
      <div *ngIf="noMatchResults()" class="dropdown-no-match px-3 d-flex align-items-center justify-content-center">
        <span class="text-center default-cursor">
          No hay ninguna empresa que coincida con su busqueda
        </span>
      </div>
      <div *ngIf="serverError" class="dropdown-no-match px-3 d-flex align-items-center justify-content-center">
        <span class="text-center default-cursor">
          No se ha podido obtener ninguna empresa del servidor
        </span>
      </div>
  </div>
</div>