<div class="d-flex flex-column h-100 vertical-scroll position-absolute w-100">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3 [innerHtml]="businessTeam ? 'Mi Empresa: <span>' + businessTeam.display_name + '</span>' : 'Mi Empresa'"></h3>
  </div>

  <!-- Business team selection for superadmins -->
  <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center main-section" *ngIf="!businessTeam && isSuperAdmin">
    <div class="w-100 text-center">
      <h4 class="text-gray">Selecciona la empresa:</h4>
    </div>
    <div class="row pt-4 m-0 w-100">
      <div class="col-sm-6 offset-sm-3 col-10 offset-1 p-0">
        <app-business-dropdown [company]="businessTeam" (companyChange)="onCompanyUpdated($event)"></app-business-dropdown>
      </div>
    </div>
  </div>

  <!-- Business info -->
  <div class="px-3 px-md-5 pb-4" *ngIf="businessTeam">
    <div class="card pana-card">
      <div class="card-body">
        <div class="row m-0">
          <div class="col-md-4 col-12 p-0">
            <h6 class="text-gray">RIF</h6>
            <h6 class="m-0">{{ businessTeam.document }}</h6>
          </div>
          <div class="col-md-8 col-12 p-0 mt-4 mt-md-0">
            <h6 class="text-gray">Dirección</h6>
            <h6 class="m-0">{{ businessTeam.address }}</h6>
          </div>
        </div>
        <div class="row m-0 mt-md-5">
          <div class="col-md-4 col-12 p-0 pr-4 mt-4 mt-md-0">
            <h6 class="text-gray">Persona de Contacto</h6>
            <h6 class="m-0">{{ businessTeam.contact }}</h6>
          </div>
          <div class="col-md-4 col-12 p-0 pr-4 mt-4 mt-md-0">
            <h6 class="text-gray">Email de Contacto</h6>
            <h6 class="m-0">{{ businessTeam.contact_email }}</h6>
          </div>
          <div class="col-md-4 col-12 p-0 mt-4 mt-md-0">
            <h6 class="text-gray">Teléfono de Contacto</h6>
            <h6 class="m-0">{{ businessTeam.contact_tlf }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Department sub-section title -->
  <div class="w-100 d-flex justify-content-between align-items-center my-4 px-3 px-md-5 " *ngIf="businessTeam">
    <h4 class="font-light">Áreas</h4>
  </div>

  <!-- Departments table -->
  <div class="px-3 px-md-5" *ngIf="businessTeam">
    <ng-template [ngIf]="departmentsLoaded && departments.length > 0" [ngIfElse]="noDepartments">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <input type="text" class="form-control input-pana-darker input-pana-busqueda" [(ngModel)]="filterCriteria" placeholder="Buscar área">
        <app-create-department 
          *ngIf="isAdmin || isSuperAdmin" 
          [businessId]="businessTeam.id" 
          (refresh)="onRefresh($event)"
          class="ml-3">
        </app-create-department>
      </div>
      <div class="w-100 mt-3 horizontal-scroll">
        <table class="table table-bordered table-hover text-center">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Fecha Creación</th>
              <th>Fecha Últ. Modificación</th>
              <th *ngIf="isAdmin || isSuperAdmin">Acciones</th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            <ng-container *ngFor="let department of departments">
              <tr *ngIf="showDepartment(department)">
                <td [innerHtml]="department.name | titlecase | highlight: filterCriteria"></td>
                <td>{{ department.created_at |
                  date:'d-MMM-y':'-0400':'es-VE' | replace : '.' : '' }}</td>
                <td>{{ department.updated_at |
                  date:'d-MMM-y':'-0400':'es-VE' | replace : '.' : '' }}</td>
                <td *ngIf="isAdmin || isSuperAdmin">
                  <app-modify-department [businessId]="businessTeam.id" [departmentName]="department.name" [departmentId]="department.id"
                    (refresh)="onRefresh($event)">
                  </app-modify-department>
                  <app-remove-department [businessId]="businessTeam.id" [departmentName]="department.name" [departmentId]="department.id"
                    (refresh)="onRefresh($event)">
                  </app-remove-department>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="noMatchResults()">
              <th colspan="3" *ngIf="!(isAdmin || isSuperAdmin)">No hay áreas que coincidan con su búsqueda</th>
              <th colspan="4" *ngIf="isAdmin || isSuperAdmin">No hay áreas que coincidan con su búsqueda</th>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
    <ng-template #noDepartments>
      <div class="d-flex flex-column align-items-center justify-content-center mt-4">
        <p class="m-0">
          Actualmente tu empresa no cuenta con áreas en PANA
        </p>
        <p class="mb-4" *ngIf="isAdmin || isSuperAdmin">
          ¿Deseas crear un área nueva?
        </p>
        <app-create-department 
          [businessId]="businessTeam.id" 
          (refresh)="onRefresh($event)" 
          class="w-100"
          *ngIf="isAdmin || isSuperAdmin">
        </app-create-department>
      </div>
    </ng-template>
  </div>
</div>
