var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { interval } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { GlobalDataService } from '../../services/global-data.service';
import { NavigationService } from '../../services/navigation.service';
import { LoaderService } from '../../services/loader.service';
import { User } from 'src/app/utils/user.utils';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(user, api, navigationService, globalData, loaderService, userUtils, router) {
        this.user = user;
        this.api = api;
        this.navigationService = navigationService;
        this.globalData = globalData;
        this.loaderService = loaderService;
        this.userUtils = userUtils;
        this.router = router;
        this.reference = '';
        // Required google formatted address
        this.googleAddress = '';
        this.pending = false;
        this.activeChecked = false;
        this.mapFailed = false;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaderService.showLoaderStatus$.subscribe(function (status) { return _this.showLoader = status; });
        this.navigationSubscription = this.router.events.subscribe(function (e) {
            /**
             * If it is a NavigationEnd event, re-initalize the component.
             * This is useful when the current section is clicked on at the sidebar.
             */
            if (e instanceof NavigationEnd) {
                _this.initializeComponent();
            }
        });
        this.initializeComponent();
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        // Prevent memory leak when component destroyed
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    };
    /**
     * Initializes class variables.
     */
    DashboardComponent.prototype.initializeVariables = function () {
        this.lat = undefined;
        this.lng = undefined;
        this.reference = '';
        this.activeChecked = false;
        this.selectedEmployee = undefined;
        this.mapFailed = false;
    };
    /**
     * Initializes the whole component by initializing this class' variables
     * and checking if the current user has dependents.
     */
    DashboardComponent.prototype.initializeComponent = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.initializeVariables()];
                    case 1:
                        _a.sent();
                        this.hasDependents = this.user.hasDependents();
                        this.hasActiveSubscription = this.user.hasActiveSubscription();
                        if (!this.hasDependents) {
                            // We check whether if there exists a pending request.
                            // If exists, we center the map in its location and set a subscriber
                            // in order to refresh the request status
                            setTimeout(function () {
                                _this.checkIfActiveRequest();
                            });
                        }
                        else {
                            setTimeout(function () {
                                _this.checkIfDependentActiveRequest();
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Performs service request through PANA's API.
     * Sets a subscription for service request status updates when
     * successfully created.
     */
    DashboardComponent.prototype.requestService = function () {
        var _this = this;
        var requestData = {
            latitude: this.lat,
            longitude: this.lng,
            reference_point: this.reference
        };
        this.api.requestService(requestData)
            .subscribe(function (data) {
            _this.petitionId = data.petition.id;
            _this.pending = true;
            _this.setSubscription();
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
    * Performs service request for a dependent through PANA's API.
    * Sets a subscription for service request status updates when
    * successfully created.
    */
    DashboardComponent.prototype.dependentRequestService = function () {
        var _this = this;
        var requestData = {
            latitude: this.lat,
            longitude: this.lng,
            reference_point: this.reference,
            client_id: this.selectedEmployee.id
        };
        this.api.createDependentRequest(requestData)
            .subscribe(function (data) {
            _this.petitionId = data.petition.id;
            _this.pending = true;
            _this.setDependentSubscription();
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
     * Checks if any required field is missing in order to
     * perform PANA service request.
     */
    DashboardComponent.prototype.incompleteData = function () {
        var undefinedSelectedEmployee = this.hasDependents && this.selectedEmployee === undefined;
        var undefinedLat = this.lat === undefined;
        var undefinedLng = this.lng === undefined;
        return (undefinedSelectedEmployee || undefinedLat || undefinedLng);
    };
    /**
     * Retrieves active request data from server (if exists), updates the map
     * with it and sets a subscriber for refreshing its status.
     */
    DashboardComponent.prototype.checkIfActiveRequest = function () {
        var _this = this;
        this.api.getActiveRequest()
            .subscribe(function (data) {
            if (data.data.length !== 0) {
                _this.updateActiveData(data.data[0]);
                _this.pending = true;
                _this.setSubscription();
            }
            _this.activeChecked = true;
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
     * Retrieves dependent's active request data from server (if exists),
     * updates the map with it and sets a subscriber for refreshing its status.
     */
    DashboardComponent.prototype.checkIfDependentActiveRequest = function () {
        var _this = this;
        this.api.getDependentActiveRequest()
            .subscribe(function (data) {
            if (data.data.length !== 0) {
                _this.updateActiveData(data.data[0]);
                _this.updateSelectedEmployee(data.data[0]);
                _this.pending = true;
                _this.setDependentSubscription();
            }
            _this.activeChecked = true;
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
     * Updates dashboard with active request data from server's response
     * @param activeRequestData - JSON Object with active request data.
     */
    DashboardComponent.prototype.updateActiveData = function (activeRequestData) {
        this.lat = +activeRequestData.lat;
        this.lng = +activeRequestData.lng;
        this.googleAddress = activeRequestData.google_reference_point;
        this.reference = activeRequestData.reference_point;
        this.status = activeRequestData.status;
        var client = activeRequestData.user;
        this.clientInfo = client;
        this.clientInfo = {
            name: client.first_name + ' ' + client.last_name,
            phone: client.phone,
            document: client.type_document + '-' + client.document,
            email: client.email,
            department: ''
            // department: this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A'
        };
        if (!this.user.isSuperAdmin()) {
            this.clientInfo['department'] = this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A';
        }
        this.petitionId = activeRequestData.id;
    };
    /**
     * Sets and updates the user asociated to an existing active request.
     * @param activeDependentRequestData JSON Object with active request data.
     */
    DashboardComponent.prototype.updateSelectedEmployee = function (activeDependentRequestData) {
        var client = activeDependentRequestData.user;
        this.selectedEmployee = client;
    };
    /**
     * Retrieves active request data from server (if exists) and refresh its status.
     * If there's no active request, unsubscribes the class' subscription
     */
    DashboardComponent.prototype.refreshActiveStatus = function () {
        var _this = this;
        this.api.getActiveRequest()
            .subscribe(function (data) {
            if (data.data.length !== 0) {
                _this.pending = true;
                _this.status = data.data[0].status;
                if (!_this.clientInfo) {
                    var client = data.data[0].user;
                    _this.clientInfo = client;
                    _this.clientInfo = {
                        name: client.first_name + ' ' + client.last_name,
                        phone: client.phone,
                        document: client.type_document + '-' + client.document,
                        email: client.email,
                    };
                    // if (!this.user.isSuperAdmin()) {
                    //   this.clientInfo['department'] = this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A';
                    // }
                }
                if (!_this.selectedEmployee) {
                    _this.selectedEmployee = data.data[0].user;
                }
            }
            else {
                _this.pending = false;
                _this.subscription.unsubscribe();
                _this.navigationService.disableStatus(false);
            }
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
     * Retrieves active request data from server (if exists) and refresh its status.
     * If there's no active request, unsubscribes the class' subscription
     */
    DashboardComponent.prototype.refreshDependentActiveStatus = function () {
        var _this = this;
        this.api.getDependentActiveRequest()
            .subscribe(function (data) {
            if (data.data.length !== 0) {
                _this.pending = true;
                _this.status = data.data[0].status;
                if (!_this.clientInfo) {
                    var client = data.data[0].user;
                    _this.clientInfo = client;
                    _this.clientInfo = {
                        name: client.first_name + ' ' + client.last_name,
                        phone: client.phone,
                        document: client.type_document + '-' + client.document,
                        email: client.email,
                        department: ''
                    };
                    if (!_this.user.isSuperAdmin()) {
                        _this.clientInfo['department'] = _this.userUtils.getUserDependentRole(client, _this.user.getProfile()).department || 'N/A';
                    }
                }
            }
            else {
                _this.pending = false;
                _this.subscription.unsubscribe();
                _this.navigationService.disableStatus(false);
            }
        }, function () {
            _this.mapFailed = true;
        });
    };
    /**
     * Sets a subscription every 10 secs. for refreshActiveStatus
     */
    DashboardComponent.prototype.setSubscription = function () {
        var _this = this;
        var subsInterval = interval(3000);
        this.subscription = subsInterval.subscribe(function () {
            _this.refreshActiveStatus();
        });
        this.navigationService.disableStatus(true);
    };
    /**
     * Sets a subscription every 10 secs. for refreshActiveStatus
     */
    DashboardComponent.prototype.setDependentSubscription = function () {
        var _this = this;
        var subsInterval = interval(3000);
        this.subscription = subsInterval.subscribe(function () {
            _this.refreshDependentActiveStatus();
        });
        this.navigationService.disableStatus(true);
    };
    return DashboardComponent;
}());
export { DashboardComponent };
