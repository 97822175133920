import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';
import { FullyRegisteredGuard } from './_guards/fully-registered.guard';

import { LoginComponent } from './sections/login/login.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { UserRegistrationComponent } from './sections/user-registration/user-registration.component';
import { MyAccountComponent } from './sections/my-account/my-account.component';
import { MyBusinessComponent } from './sections/my-business/my-business.component';
import { SetPasswordComponent } from './sections/set-password/set-password.component';
import { UserManagementComponent } from './sections/user-management/user-management.component';
import { MassLoadComponent } from './sections/mass-load/mass-load.component';
import { FamilyGroupComponent } from './sections/family-group/family-group.component';
import { InvoicesComponent } from './sections/invoices/invoices.component';
import { LoadingComponent } from './sections/loading/loading.component';
import { LoadingScreenGuard } from './_guards/loading-screen.guard';

// TODO: Add role guard to views that require admin and super admin permissions
const routes: Routes = [
  {
    path: 'service',
    component: DashboardComponent,
    canActivate: [AuthGuard, FullyRegisteredGuard],
    runGuardsAndResolvers: 'always',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: UserRegistrationComponent
  },
  {
    path: 'account',
    component: MyAccountComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: 'my-business',
    component: MyBusinessComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: 'user-management/mass-load',
    component: MassLoadComponent,
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: 'loading',
    component: LoadingComponent
  },
  {
    path: 'family-group',
    component: FamilyGroupComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard, FullyRegisteredGuard],
  },
  {
    path: '**',
    redirectTo: 'service',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
