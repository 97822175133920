import { Component, OnInit, Input } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';

import { StringsConstant } from '../../constants/strings.constant';
import { GlobalDataService } from 'src/app/services/global-data.service';


@Component({
  selector: 'app-service-cancellation-modal',
  templateUrl: './service-cancellation-modal.component.html',
  styleUrls: ['./service-cancellation-modal.component.css']
})
export class ServiceCancellationModalComponent implements OnInit {
  @Input() hasDependents;
  @Input() petitionId;

  modalRef: NgbModalRef;
  showReasons = false;
  cancellationReasons: string[];
  cancellationReason: string;
  errorMessage: string;


  constructor(
    public globalData: GlobalDataService,
    private modalService: NgbModal,
    private api: ApiService,
    private navigationService: NavigationService
  ) {
    this.cancellationReason = '';
  }

  ngOnInit() {
    this.getCancellationReasons();
  }

  openModal(cancelRequestModal): void {
    this.modalRef = this.modalService.open(
      cancelRequestModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  confirmCancellation() {
    this.showReasons = true;
  }

  goBack() {
    this.showReasons = false;
  }

  requestCancellation() {
    const serviceCancellationObject = {
      'canceled_reason_id': this.cancellationReason
    };
    this.api.cancelService(serviceCancellationObject, this.petitionId)
      .subscribe(
      data => {
          this.navigationService.disableStatus(false);
          location.reload();
        },
        error => {
          console.log(error);
        }
      );
  }

  dependentRequestCancellation() {
    const serviceCancellationObject = {
      'canceled_reason_id': this.cancellationReason
    };
    this.api.cancelDependentRequest(serviceCancellationObject, this.petitionId)
      .subscribe(
        data => {
          this.navigationService.disableStatus(false);
          location.reload();
        },
        error => {
          console.log(error);
        }
      );
  }

  closeModal() {
    this.modalRef.close();
  }

  private getCancellationReasons(): void {
    this.api.cancellationReasons()
      .subscribe(
        data => {
          this.cancellationReasons = data.canceled_reasons;
        },
        error => {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        }
      );
  }

}
