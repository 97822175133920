
import { Component, OnInit, Input,  AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';

declare var Stripe: any;

@Component({
  selector: 'app-edit-credit-card',
  templateUrl: './edit-credit-card.component.html',
  styleUrls: ['./edit-credit-card.component.css']
})

export class EditCreditCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() businessId: number;
  @Input() paymentMethod: any;

  editCreditCardForm: FormGroup;

  stripe: any;
  cardExpiryInput: any;
  cardCvcInput: any;
  stripeExpiryErrorMessage: string;
  stripeCvcErrorMessage: string;
  errorMessage: string;
  modalRef: NgbModalRef;
  cardExpiryInputFocused = false;
  cardCvcInputFocused = false;
  cardExpiryInputValid = false;
  cardCvcInputValid = false;
  hideCardInputs: boolean;
  showCardInput = false;
  serverResponse: boolean;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    // private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.errorMessage = undefined;
    this.stripeExpiryErrorMessage = undefined;
    this.stripeCvcErrorMessage = undefined;
  }

  ngAfterViewInit() {
    const style = {
      base: {
        iconColor: '#FFF',
        color: '#FFF',
        '::placeholder': {
          color: '#999',
        },
      },
    };
    this.stripe = Stripe(environment.tokenStripe, { locale: 'es' });
    const elements = this.stripe.elements();
    this.cardExpiryInput = elements.create('cardExpiry', {
      style: style,
    });
    this.cardCvcInput = elements.create('cardCvc', {
      style: style,
    });
  }

  openModal(editCreditCardModal) {
    this.errorMessage = undefined;
    this.stripeExpiryErrorMessage = undefined;
    this.stripeCvcErrorMessage = undefined;
    this.editCreditCardForm = this.formBuilder.group({
      name: new FormControl(this.paymentMethod.billing_details.name, [
        Validators.required,
        Validators.pattern('^[a-zA-Zñáééióú.\s]*$'),
        Validators.minLength(5)
      ]),
      // phone: new FormControl(this.paymentMethod.metadata.phone, [
      //   Validators.required,
      //   Validators.pattern('^[0-9]+$'),
      //   Validators.minLength(10)
      // ]),
      // callCode: new FormControl(this.paymentMethod.metadata.callCode, [
      //   Validators.required
      // ]),
    });
    this.modalRef = this.modalService.open(
      editCreditCardModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2',
        beforeDismiss: this.closeModal.bind(this)
      }
    );
    this.cardExpiryInput.mount('#card-expiry-element');
    this.cardCvcInput.mount('#card-cvc-element');

    this.cardExpiryInput.addEventListener('change', event => {
      if (event.error) {
        this.stripeExpiryErrorMessage = event.error.message;
      } else {
        this.stripeExpiryErrorMessage = '';
        this.errorMessage = '';
      }

      if (event.complete) {
        this.cardExpiryInputValid = true;
      } else {
        this.cardExpiryInputValid = false;
      }
    });
    this.cardCvcInput.addEventListener('change', event => {
      if (event.error) {
        this.stripeCvcErrorMessage = event.error.message;
      } else {
        this.stripeCvcErrorMessage = '';
        this.errorMessage = '';
      }

      if (event.complete) {
        this.cardExpiryInputValid = true;
      } else {
        this.cardExpiryInputValid = false;
      }
    });
    this.cardExpiryInput.addEventListener('focus', () => {
      this.cardExpiryInputFocused = true;
    });
    this.cardExpiryInput.addEventListener('blur', () => {
      this.cardExpiryInputFocused = false;
    });
    this.cardCvcInput.addEventListener('focus', () => {
      this.cardCvcInputFocused = true;
    });
    this.cardCvcInput.addEventListener('blur', () => {
      this.cardCvcInputFocused = false;
    });
    this.cardExpiryInput.on('ready', () => {
      this.showCardInput = true;
      this.cardExpiryInput.focus();
    });
  }

  closeModal() {
    this.clearForm();
    this.errorMessage = undefined;
    this.modalRef.close();
    this.hideCardInputs = false;
  }

  private clearForm(): void {
    this.editCreditCardForm.reset();
    this.errorMessage = undefined;
  }

  editPaymentMethod() {
    // this.serverResponse = false;
    // this.loaderService.showLoader(true);
    this.hideCardInputs = true;
    setTimeout(function () {
      this.loaderService.showLoader(false);
    }, 1000);
    this.closeModal();
  }

  ngOnDestroy() {
    // this.cardExpiryInput.removeEventListener('change', event);
    this.cardExpiryInput.destroy();
  }

  get f() { return this.editCreditCardForm.controls; }
}

