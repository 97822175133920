import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';

import { LoaderConstant } from '../../constants/loader.constant';
import { GlobalDataService } from '../../services/global-data.service';
import { NavigationService } from '../../services/navigation.service';
import { LoaderService } from '../../services/loader.service';
import { User } from 'src/app/utils/user.utils';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  // Default lat and lng to center the map
  lat: number;
  lng: number;
  reference = '';
  // Required google formatted address
  googleAddress = '';
  // Active request status:
  status: string;
  clientInfo: Object;
  pending = false;
  activeChecked = false;
  petitionId: number;
  subscription: Subscription;

  hasDependents: boolean;
  hasActiveSubscription: boolean;
  selectedEmployee: any;
  showLoader: boolean;
  mapFailed = false;

  navigationSubscription: Subscription;
  constructor(
    private user: UserService,
    private api: ApiService,
    private navigationService: NavigationService,
    public globalData: GlobalDataService,
    public loaderService: LoaderService,
    public userUtils: User,
    private router: Router
    ) {}

  ngOnInit() {
    this.loaderService.showLoaderStatus$.subscribe(
      status => this.showLoader = status
    );

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      /**
       * If it is a NavigationEnd event, re-initalize the component.
       * This is useful when the current section is clicked on at the sidebar.
       */
      if (e instanceof NavigationEnd) {
        this.initializeComponent();
      }
    });

    this.initializeComponent();
  }

  ngOnDestroy() {
    // Prevent memory leak when component destroyed
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Initializes class variables.
   */
  initializeVariables() {
    this.lat = undefined;
    this.lng = undefined;
    this.reference = '';
    this.activeChecked = false;
    this.selectedEmployee = undefined;
    this.mapFailed = false;
  }

  /**
   * Initializes the whole component by initializing this class' variables
   * and checking if the current user has dependents.
   */
  async initializeComponent() {
    await this.initializeVariables();
    this.hasDependents = this.user.hasDependents();
    this.hasActiveSubscription = this.user.hasActiveSubscription();
    if (!this.hasDependents) {
      // We check whether if there exists a pending request.
      // If exists, we center the map in its location and set a subscriber
      // in order to refresh the request status
      setTimeout(() => {
        this.checkIfActiveRequest();
      });
    } else {
      setTimeout(() => {
        this.checkIfDependentActiveRequest();
      });
    }
  }

  /**
   * Performs service request through PANA's API.
   * Sets a subscription for service request status updates when
   * successfully created.
   */
  requestService(): void {
    const requestData: Object = {
      latitude: this.lat,
      longitude: this.lng,
      reference_point: this.reference
    };
    this.api.requestService(requestData)
      .subscribe(
        data => {
          this.petitionId = data.petition.id;
          this.pending = true;
          this.setSubscription();
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

   /**
   * Performs service request for a dependent through PANA's API.
   * Sets a subscription for service request status updates when
   * successfully created.
   */
  dependentRequestService(): void {
    const requestData: Object = {
      latitude: this.lat,
      longitude: this.lng,
      reference_point: this.reference,
      client_id: this.selectedEmployee.id
    };
    this.api.createDependentRequest(requestData)
      .subscribe(
        data => {
          this.petitionId = data.petition.id;
          this.pending = true;
          this.setDependentSubscription();
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

  /**
   * Checks if any required field is missing in order to
   * perform PANA service request.
   */
  incompleteData(): boolean {
    const undefinedSelectedEmployee = this.hasDependents && this.selectedEmployee === undefined;
    const undefinedLat = this.lat === undefined;
    const undefinedLng = this.lng === undefined;
    return (undefinedSelectedEmployee || undefinedLat || undefinedLng);
  }

  /**
   * Retrieves active request data from server (if exists), updates the map
   * with it and sets a subscriber for refreshing its status.
   */
  private checkIfActiveRequest(): void {
    this.api.getActiveRequest()
      .subscribe(
        data => {
          if (data.data.length !== 0) {
            this.updateActiveData(data.data[0]);
            this.pending = true;
            this.setSubscription();
          }
          this.activeChecked = true;
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

  /**
   * Retrieves dependent's active request data from server (if exists),
   * updates the map with it and sets a subscriber for refreshing its status.
   */
  private checkIfDependentActiveRequest(): void {
    this.api.getDependentActiveRequest()
      .subscribe(
        data => {
          if (data.data.length !== 0) {
            this.updateActiveData(data.data[0]);
            this.updateSelectedEmployee(data.data[0]);
            this.pending = true;
            this.setDependentSubscription();
          }
          this.activeChecked = true;
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

  /**
   * Updates dashboard with active request data from server's response
   * @param activeRequestData - JSON Object with active request data.
   */
  private updateActiveData(activeRequestData: any): void {
    this.lat = +activeRequestData.lat;
    this.lng = +activeRequestData.lng;
    this.googleAddress = activeRequestData.google_reference_point;
    this.reference = activeRequestData.reference_point;
    this.status = activeRequestData.status;
    const client = activeRequestData.user;
    this.clientInfo = client;
    this.clientInfo = {
      name: client.first_name + ' ' + client.last_name,
      phone: client.phone,
      document: client.type_document + '-' + client.document,
      email: client.email,
      department: ''
      // department: this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A'
    };

    if (!this.user.isSuperAdmin()) {
      this.clientInfo['department'] = this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A';
    }

    this.petitionId = activeRequestData.id;
  }

  /**
   * Sets and updates the user asociated to an existing active request.
   * @param activeDependentRequestData JSON Object with active request data.
   */
  private updateSelectedEmployee(activeDependentRequestData: any): void {
    const client = activeDependentRequestData.user;
    this.selectedEmployee = client;
  }

  /**
   * Retrieves active request data from server (if exists) and refresh its status.
   * If there's no active request, unsubscribes the class' subscription
   */
  private refreshActiveStatus(): void {
    this.api.getActiveRequest()
      .subscribe(
        data => {
          if (data.data.length !== 0) {
            this.pending = true;
            this.status = data.data[0].status;
            if (!this.clientInfo) {
              const client = data.data[0].user;
              this.clientInfo = client;
              this.clientInfo = {
                name: client.first_name + ' ' + client.last_name,
                phone: client.phone,
                document: client.type_document + '-' + client.document,
                email: client.email,
                // department: ''
              };

              // if (!this.user.isSuperAdmin()) {
              //   this.clientInfo['department'] = this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A';
              // }
            }
            if (!this.selectedEmployee) {
              this.selectedEmployee = data.data[0].user;
            }
          } else {
            this.pending = false;
            this.subscription.unsubscribe();
            this.navigationService.disableStatus(false);
          }
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

  /**
   * Retrieves active request data from server (if exists) and refresh its status.
   * If there's no active request, unsubscribes the class' subscription
   */
  private refreshDependentActiveStatus(): void {
    this.api.getDependentActiveRequest()
      .subscribe(
        data => {
          if (data.data.length !== 0) {
            this.pending = true;
            this.status = data.data[0].status;
            if (!this.clientInfo) {
              const client = data.data[0].user;
              this.clientInfo = client;
              this.clientInfo = {
                name: client.first_name + ' ' + client.last_name,
                phone: client.phone,
                document: client.type_document + '-' + client.document,
                email: client.email,
                department: ''
              };

              if (!this.user.isSuperAdmin()) {
                this.clientInfo['department'] = this.userUtils.getUserDependentRole(client, this.user.getProfile()).department || 'N/A';
              }
            }
          } else {
            this.pending = false;
            this.subscription.unsubscribe();
            this.navigationService.disableStatus(false);
          }
        },
        () => {
          this.mapFailed = true;
        }
      );
  }

  /**
   * Sets a subscription every 10 secs. for refreshActiveStatus
   */
  private setSubscription(): void {
    const subsInterval = interval(3000);
    this.subscription = subsInterval.subscribe(
      () => {
        this.refreshActiveStatus();
      }
    );
    this.navigationService.disableStatus(true);
  }

  /**
   * Sets a subscription every 10 secs. for refreshActiveStatus
   */
  private setDependentSubscription(): void {
    const subsInterval = interval(3000);
    this.subscription = subsInterval.subscribe(
      () => {
        this.refreshDependentActiveStatus();
      }
    );
    this.navigationService.disableStatus(true);
  }

}
