<ng-template #customLoadingTemplate>
  <div class="col-12 p-0 position-absolute
              d-flex flex-column flex-grow-1 h-100
              align-items-center justify-content-center"
    [ngClass]="{'col-md-9 col-lg-10 offset-md-3 offset-lg-2': !hideSidebar()}">
    <div class="spinner-pulse"></div>
  </div>
</ng-template>
<ng-template #customLoadingTemplateProgressbar>
  <div class="col-12 p-0 position-absolute h-100
              d-flex flex-column
              align-items-center justify-content-center" style="z-index: 999; background: rgba(34, 34, 34, 0.883);"
    [ngClass]="{'col-md-9 col-lg-10 offset-md-3 offset-lg-2': !hideSidebar()}">
    <h5>{{messageLoaderProgress}}</h5>
    <mat-progress-bar style="width: 20%;" mode="determinate" value="{{percentLoaderProgress}}"></mat-progress-bar>
  </div>
</ng-template>

<ngx-loading [show]="showLoader" [config]="loaderConfig" [template]="customLoadingTemplate"></ngx-loading>
<ngx-loading [show]="showLoaderProgressbar" [config]="loaderConfig" [template]="customLoadingTemplateProgressbar"></ngx-loading>

<div class="d-flex flex-column p-0 h-100 disable-scroll">
  <div class="w-100 ">
    <app-navbar
      *ngIf="!hideSidebar()"
      [navigationDisabled]="navigationDisabled"
      [showToggleSidebarButton]="toggleSidebar && !hideSidebar()"
      [(sidebarIsExpanded)]="isExpanded">
    </app-navbar>
  </div>
  <div class="row m-0 p-0 d-flex flex-grow-1 position-relative">
    <div
      *ngIf="!hideSidebar() && (!toggleSidebar || (toggleSidebar && isExpanded))"
      class="col-3 col-lg-2 m-0 p-0 bg-gradient-dark d-flex "
      [ngClass]="{'toggled-sidebar': toggleSidebar}"
    >
      <app-sidebar class="d-flex h-100 flex-column w-100" [toggleMe]="toggleSidebar" [(expandMe)]="isExpanded" [navigationDisabled]="navigationDisabled"></app-sidebar>
    </div>
    <div class="col-12 m-0 p-0 bg-gradient-dark" [ngClass]="{'col-md-9 col-lg-10': !hideSidebar()}">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
