import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { UserService } from '../../services/user.service';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { UserRol } from '../../interfaces/user-rol.interface';
import { SubscriptionsConstant } from '../../constants/subscriptions.constant';
import { ProfileEdit as MyProfileForm } from '../../forms/corporative/my-profile.form';
import { FormBuilder, FormGroup, COMPOSITION_BUFFER_MODE} from '@angular/forms';
import { LoaderService } from '../../services/loader.service';
import { ApiService } from '../../services/api.service';
import { StringsConstant } from '../../constants/strings.constant';
import { User } from 'src/app/utils/user.utils';
import { Subscription } from 'rxjs';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  userProfile: UserProfile;
  subscription: any;
  familyGroup: UserRol;
  businessTeam: UserRol;

  focusedUser: UserProfile;
  profileForm: FormGroup;
  errorMessage: string;
  idTypes: string[];
  showProfileForm: boolean;
  navigationSubscription: Subscription;
  matcher = new CustomErrorStateMatcher();
  existingDocument: boolean;

  constructor(
    private user: UserService,
    private loaderService: LoaderService,
    private api: ApiService,
    public userUtils: User,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.userProfile = this.user.getProfile();
    this.setSubscription();
    this.setFamilyGroup();
    this.setBusinessTeam();
    this.idTypes = ['V', 'E', 'P'];
    this.existingDocument = false;

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      /**
       * If it is a NavigationEnd event, re-initalize the component.
       * This is useful when the current section is clicked on at the sidebar.
       */
      if (e instanceof NavigationEnd) {
        this.initialize();
      }
    });
    this.initialize();

  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

   /**
   * Initializes class' variables values
   * Sets businessTeam if specified. Fetch it otherwise (when not a SuperAdmin)
   */
  initialize() {
    this.businessTeam = undefined;
    this.focusedUser = undefined;  
    this.getProfileForm();
  }

  /**
   * Evaluates a subscription status and returns its display name.
   * @return string - Subscription status display name.
   */
  getSubscriptionStatus() {
    switch (this.subscription.status) {
      case SubscriptionsConstant.SUBS_INACTIVE:
        return 'Inactiva';
      case SubscriptionsConstant.SUBS_ACTIVE:
        return 'Activa';
      case SubscriptionsConstant.SUBS_SUSPENDED:
        return 'Suspendida';
      case SubscriptionsConstant.SUBS_CREATED:
        return 'Creada';
      default:
        return 'No disponible';
    }
  }

  setFocusedUser(user: any) {
    this.cancelEdit();
    this.focusedUser = user;
  }

   /**
   * Determines if user from users table is selected for details
   * @param user - Object with user data
   */
  isFocused(user: UserProfile) {
    return this.focusedUser && this.focusedUser.email === user.email;
  }

    /**
   * Shows user data edition form, initializing and fetching required data
   */
  showForm() {
    this.errorMessage = undefined;
    this.idTypes = ['V', 'E', 'P'];


    const callCode = this.userProfile.phone.match(/\+[0-9]+/g)[0];
    const phone = this.userProfile.phone.match(/[0-9]{10}/g)[0];

    this.profileForm.patchValue({
      firstName: this.userProfile.first_name,
      lastName: this.userProfile.last_name,
      documentType: this.userProfile.type_document,
      document: this.userProfile.document,
      email: this.userProfile.email,
      callCode: callCode,
      phone: phone,

    });

    this.profileForm.updateValueAndValidity();
    this.showProfileForm = true;
  }

    /**
   * Performs user modifications reques to PANA's API
   * Hides edition form and updates both list of users and focused
   * user when success.
   */
  editUser() {
    if (!this.profileForm.valid){
      return;      
    }
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);
    const requestBody = Object.assign({}, this.profileForm.value);

    requestBody.call_code = requestBody.callCode;
    requestBody.first_name = requestBody.firstName;
    requestBody.last_name = requestBody.lastName;
    requestBody.type_document = requestBody.documentType;
    this.api.editProfile(requestBody)
      .subscribe(
        data => {
          this.user.setProfile(data.user);
          this.userProfile = this.user.getProfile();
          this.cancelEdit();
          this.loaderService.showLoader(false);
        },
        error => {
          const messageObject = error.error.errors;
          this.existingDocument = messageObject.hasOwnProperty('document');
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );
  }

   /**
   * Sets focused user and shows edition form
   * @param user - Object with selected user data from users table*/
   
  setAndEdit(user: any) {
    this.showForm();
  }

  /**
   * Resets and hides user edition form
   */
  cancelEdit() {
    this.profileForm.reset();
    this.errorMessage = undefined;
    this.showProfileForm = false;
  }


    /**
   * Initializes a new user edition form and sets its validators
   */
  getProfileForm() {
    const profileForm = new MyProfileForm;
    this.profileForm = this.formBuilder.group(
      profileForm,
      {
        validator: [
          this.invalidPhone,
          this.invalidIdentification
        ]
      }
    );
    Object.keys(this.profileForm.controls).forEach(field => {
      this.profileForm.get(field).setValidators(
        profileForm.getValidators(field)
      );
    });
  }

    /**
   * Class' form validator: checks wheter a given venezuelan phone number
   * is valid or not
   * @param profileForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidPhone(profileForm: FormGroup) {
    const vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
    const vzlanPhone = profileForm.controls.callCode.value === '+58';
    if (vzlanPhone && !vzlaPhoneRegex.test(profileForm.controls.phone.value)) {
      return { 'invalid-phone': true };
    }
  }

  /**
   * Class' form validator: checks wheter a given venezuelan ID number
   * is valid or not
   * @param registrationForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidIdentification(registrationForm: FormGroup) {
    const idCardRegex = /^[0-9]{7,9}$/;
    const pasRegex = /^[a-zA-Z0-9]{5,10}$/;
    let documentValue: string;
    documentValue = registrationForm.controls.document.value;
    let isValid: boolean;
    switch (registrationForm.controls.documentType.value) {
      case 'V':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'E':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'P':
        isValid = pasRegex.test(documentValue);
        break;
      default:
        isValid = false;
        break;
    }
    return isValid ? null : { 'invalid-identification': true };
  }

  /**
   * Sets subscription class variable value from an active subscription within
   * current user's profile.
   */
  private setSubscription(): void {
    this.subscription = this.userProfile.subscriptions.find(
      function (subscription: any): boolean {
        return subscription.status === SubscriptionsConstant.SUBS_ACTIVE;
      }
    );
  }

    /**
   * Sets familyGroup class variable value from an active subscription within
   * current user's profile.
   */
  private setFamilyGroup(): void {
    this.familyGroup = this.userProfile.roles.find(
      function (role: UserRol) {
        const team = role.team;
        return team ? team.type_label === SubscriptionsConstant.FAMILY_GROUP : false;
      }
    );
  }

   /**
   * Sets businessTeam class variable value from an active subscription within
   * current user's profile.
   */
  private setBusinessTeam(): void {
    this.businessTeam = this.userProfile.roles.find(
      function (role: UserRol) {
        const team = role.team;
        return team ? team.type_label === SubscriptionsConstant.BUSINESS : false;
      }
    );
  }


  get f() { return this.profileForm.controls; }

}

