<ng-template #createUserModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-template [ngIf]="!dataValidatedFromServer" [ngIfElse]="successfullyValidated">
      <div class="content">
        <form [formGroup]="form">
          <div class="row m-0 w-100">
            <!-- First Name -->
            <div class="col-12 col-sm-6 p-0 pr-sm-1">
              <mat-form-field class="small w-100">
                <input matInput appAutofocus id="first_name" type="text" formControlName="first_name" placeholder="Nombre" [maxlength]="24">
                <mat-error *ngIf="f.first_name.invalid && (f.first_name.dirty || f.first_name.touched)">
                  <div *ngIf="f.first_name.errors.required">
                    Por favor, introduce el nombre del empleado
                  </div>
                  <div *ngIf="f.first_name.errors.pattern">
                    Por favor, introduce un nombre válido 
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Last Name -->
            <div class="col-12 col-sm-6 p-0 pl-sm-1">
              <mat-form-field class="small w-100">
                <input matInput id="last_name" type="text" formControlName="last_name" placeholder="Apellido" [maxlength]="24">
                <mat-error *ngIf="f.last_name.invalid && (f.last_name.dirty || f.last_name.touched)">
                  <div *ngIf="f.last_name.errors.required">
                    Por favor, introduce el apellido del empleado
                  </div>
                  <div *ngIf="f.last_name.errors.pattern">
                    Por favor, introduce un nombre válido 
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Email -->
            <div class="col-12 mt-sm-3 p-0">
              <mat-form-field class="small w-100">
                <input matInput id="email" type="text" formControlName="email" placeholder="Email" [maxlength]="64">
                <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
                  <div *ngIf="f.email.errors.required">
                    Por favor, introduce el email del empleado
                  </div>
                  <div *ngIf="f.email.errors.pattern">
                    Por favor, introduce un email válido
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <!--ID -->
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pr-sm-1 d-flex justify-content-start">
              <mat-form-field class="small id-type-select pr-1">
                <mat-select id="type_document" formControlName="type_document">
                  <mat-option *ngFor="let t of idTypes" [value]="t">
                    {{t}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="small flex-grow-1 document-field">
                <input matInput id="document" type="text" formControlName="document" placeholder="Documento" [maxlength]="10" 
                  [errorStateMatcher]="matcher">
              </mat-form-field>
            </div>
            <!-- Phone -->
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pl-sm-1 d-flex justify-content-start">
              <int-phone-prefix class="phone-prefix small" [defaultCountry]="'ve'" [locale]="'es'" 
                formControlName="callCode">
              </int-phone-prefix>
              <mat-form-field class="small flex-grow-1 phone-field">
                <input matInput id="phone" type="text" formControlName="phone" placeholder="Teléfono" [maxLength]="10"
                  [errorStateMatcher]="matcher">
              </mat-form-field>
            </div>
            <!-- ID -->
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pr-sm-1 d-flex justify-content-start text-left" style="margin-top: -20px !important;">
              <div class="small flex-grow-1 document-field" style="font-size: 0.8rem! important;">
                <mat-error *ngIf="f.document.invalid && (f.document.dirty || f.document.touched)">
                  <div *ngIf="f.document.errors.required">
                    Por favor, introduce la identificación del empleado
                  </div>
                  <div *ngIf="f.document.errors.pattern">
                    Por favor, introduce una identificación válida
                  </div>
                </mat-error>
                <mat-error *ngIf="form.hasError('invalid-identification') && (f.document.dirty || f.document.touched) && !f.document.invalid">
                  <div>
                    Introduce una identificación válida
                  </div>
                </mat-error>
              </div>
            </div>
            <!-- Phone -->
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pl-sm-1 d-flex justify-content-start text-left" style="margin-top: -20px !important;">
              <div class="small flex-grow-1 phone-field" style="font-size: 0.8rem! important;">
                <mat-error *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)">
                  <div *ngIf="f.phone.errors.required">
                    Por favor, introduce el teléfono celular del empleado
                  </div>
                  <div *ngIf="f.phone.errors.pattern">
                    Por favor, introduce un teléfono celular válido
                  </div>
                </mat-error>
                <mat-error *ngIf="form.hasError('invalid-phone') && (f.phone.dirty || f.phone.touched) && !f.phone.invalid">
                  <div>
                    Por favor, introduce un teléfono celular válido
                  </div>
                </mat-error>
              </div>
            </div>
            <!-- Department -->
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pr-sm-1">
              <mat-form-field class="small w-100">
                <mat-select id="department" formControlName="department">
                  <mat-option value="">Sin especificar</mat-option>
                  <mat-option *ngFor="let d of departments" [value]="d.name">
                    {{d.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 mt-sm-3 p-0 pl-sm-1">
              <mat-form-field class="small w-100">
                <mat-select id="role" formControlName="role" placeholder="Perfil">
                  <mat-option *ngFor="let r of roles" [value]="r.display_name">
                    {{r.display_name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.role.invalid && (f.role.dirty || f.role.touched)">
                  <div *ngIf="f.role.errors.required">
                    Por favor, selecciona el perfil del empleado
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-inline-block margin-road align-top mx-sm-2" alt="">
          <p class="text-green mt-1 text-center" [innerHtml]="errorMessage"></p>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="form.invalid" (click)="validateUser()">
          AGREGAR
        </button>
      </div>
    </ng-template>
    <ng-template #successfullyValidated>
      <ng-template [ngIf]="!serverResponse" [ngIfElse]="responseStatus">
        <div class="content">
          <div class="text-center">
            <p>Vas a suscribir a:</p>
            <h2 class="my-4">{{f.first_name.value + ' ' + f.last_name.value}}</h2>
            <p class="m-0">{{f.email.value}}</p>
            <p class="m-0">{{f.phone.value}}</p>
            <p class="m-0">{{f.type_document.value + ' ' + f.document.value}}</p>
            <p class="m-0">{{f.department.value}}</p>
            <p class="m-0">{{f.role.value}}</p>
          </div>
          <div class="text-center my-3">
            <p class="text-green" [innerHtml]="warningFromServer"></p>
          </div>
          <div class="text-center">
            <p>
              {{f.first_name.value}} quedará suscrito inmediatamente y esta suscripción se agregará a tu próximo ciclo
              de facturación
            </p>
          </div>
        </div>
        <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
          <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="backToForm()">
            <div>
              {{ 'Cancelar' | uppercase }}
            </div>
          </div>
          <button class="btn btn-pana-gradient w-100 mx-3" (click)="registerUser()">
            {{ 'Ok' | uppercase }}
          </button>
        </div>
      </ng-template>
      <ng-template #responseStatus>
        <div class="content">
          <div class="text-center" *ngIf="!errorMessage">
            <img src="/assets/check-green.svg" width="50" height="50" >
            <h2 class="margin-text">¡Excelente!</h2>
            <p>Se suscribió al usuario exitosamente. Lo verás en tu próxima factura</p>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
            <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
            <p class="text-pink mt-1 text-center">
              {{ errorMessage }}
            </p>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-pana-gradient w-100 mx-3" (click)="closeModal()">
            {{ 'Cerrar' | uppercase }}
          </button>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>

<div class="btn w-100 btn-pana-gradient-dark" [ngClass]="btnXS ? 'btn-xs m-0' : ''" (click)="openModal(createUserModal)">
  <div>
    {{ 'agregar usuario' | uppercase }}
  </div>
</div>