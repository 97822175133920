import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { StringsConstant } from 'src/app/constants/strings.constant';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.css']
})
export class CreateDepartmentComponent implements OnInit {
  @Input() businessId: number;
  @Output() refresh = new EventEmitter<boolean>();

  modalRef: NgbModalRef;
  departmentName: string;
  errorMessage: string;
  success: boolean;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.clearName();
  }

  openModal(createDepartmentModal) {
    this.clearName();
    this.modalRef = this.modalService.open(
      createDepartmentModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal(refresh?: boolean) {
    this.clearName();
    this.success = false;
    this.modalRef.close();
    if (refresh) {
      this.refresh.emit(true);
    }
  }

  createDepartment() {
    this.loaderService.showLoader(true);
    this.api.createDepartment(this.businessId, this.departmentName).subscribe(
      data => {
        this.success = true;
        this.loaderService.showLoader(false);
      },
      error => {
        this.loaderService.showLoader(false);
        if (error.status === 400) {
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        }
      }
    );
  }

  private clearName(): void {
    this.departmentName = '';
    this.errorMessage = undefined;
  }

}
