/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./corporative-contact-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../business-contact/business-contact.component.ngfactory";
import * as i3 from "../business-contact/business-contact.component";
import * as i4 from "../../services/api.service";
import * as i5 from "@angular/common";
import * as i6 from "./corporative-contact-modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var styles_CorporativeContactModalComponent = [i0.styles];
var RenderType_CorporativeContactModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CorporativeContactModalComponent, data: {} });
export { RenderType_CorporativeContactModalComponent as RenderType_CorporativeContactModalComponent };
function View_CorporativeContactModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-business-contact", [["class", "d-flex flex-column flex-grow-1"]], null, [[null, "showMeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showMeChange" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BusinessContactComponent_0, i2.RenderType_BusinessContactComponent)), i1.ɵdid(2, 114688, null, 0, i3.BusinessContactComponent, [i4.ApiService], { title: [0, "title"] }, { showMeChange: "showMeChange" })], function (_ck, _v) { var currVal_0 = "Buena idea"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CorporativeContactModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.UpperCasePipe, []), (_l()(), i1.ɵand(0, [["corporativeContactModal", 2]], null, 0, null, View_CorporativeContactModalComponent_1)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-pana-gradient w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCorpContactModal(i1.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", "\n"])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "cont\u00E1ctanos")); _ck(_v, 3, 0, currVal_0); }); }
export function View_CorporativeContactModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-corporative-contact-modal", [], null, null, null, View_CorporativeContactModalComponent_0, RenderType_CorporativeContactModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.CorporativeContactModalComponent, [i7.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CorporativeContactModalComponentNgFactory = i1.ɵccf("app-corporative-contact-modal", i6.CorporativeContactModalComponent, View_CorporativeContactModalComponent_Host_0, {}, {}, []);
export { CorporativeContactModalComponentNgFactory as CorporativeContactModalComponentNgFactory };
