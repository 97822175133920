<nav class="navbar-dark p-0">
<div class="row padd m-0 bg-pana-dark">
    <div class="col p-0 m-0 d-flex justify-content-start align-items-center" *ngIf="showToggleSidebarButton">
      <button class="navbar-toggler mx-2 border-0" type="button" (click)="toggleSidebar()">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
    <div
      class="p-0 m-0 d-flex justify-content-center align-items-center"
      [ngClass]="!showToggleSidebarButton && !showUserButton() ? 'col-12' : 'col-3 col-lg-2'"
    >
      <a class="navbar-brand clickable m-0 p-0 float-left" (click)="goHome()">
        <img src="/assets/logo-pana.png" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      </a>
    </div>
    <div class="col p-0 m-0 d-flex justify-content-end align-items-center">
      <div *ngIf="showUserButton()" [ngClass]="showToggleSidebarButton ? 'mx-3' : 'mx-5'" ngbDropdown placement="bottom-right">
        <button
          id="userInfo"
          class="btn btn-dropdown border-0 d-flex align-items-center"
          [ngClass]="{'active-profile': isActiveProfileView()}"
          ngbDropdownToggle
          (click)="hideSidebar()"
          [disabled]="navigationDisabled"
        >
          <div class="btn-pana-gradient-dark btn-profile-circle my-0">
            <div class="d-flex align-items-center justify-content-center">
              {{ getUserInitials() }}
            </div>
          </div>
          <img src="/assets/dropdown-arrow.png" width="15" height="15" class="d-flex ml-2" alt="">

        </button>
        <div ngbDropdownMenu aria-labelledby="userInfo" [ngClass]="showToggleSidebarButton ? 'mobile-user-info pana-card' : 'user-info pana-card'">
          <div class="row m-0 px-4 py-4">
            <div class="col-md-4 col-12 p-0 pr-md-4 d-flex align-items-center justify-content-center profile-picture">
              {{ getUserInitials() }}
            </div>
            <div class="col-md-8 col-12 pl-md-0">
              <div class="row">
                <div class="col-12 text-center text-md-left">
                  <h4>{{ getUserFullName() }}</h4>
                </div>
                <div class="col-12 text-center text-md-left">
                  <span>{{ user.getProfile().email || "Sin correo asociado" }}</span>
                </div>
                <div class="col-12 text-center text-md-left">
                  <span>{{ user.getProfile().phone || "Sin teléfono asociado" }}</span>
                </div>
                <div class="col-12 my-2 text-center text-md-left">
                  <span>{{ user.getProfile().subs_status_label }}</span>
                </div>
                <div class="col-md-6 col-12 my-2 mt-md-3 text-center">
                  <button class="btn btn-pana-gradient w-95 m-0" [routerLink]="['/account']">
                    {{ 'Mi Cuenta' | uppercase }}
                  </button>
                </div>
                <div class="col-md-6 col-12 my-2 mt-md-3 text-center">
                  <div class="btn btn-pana-gradient-dark w-100 m-0" (click)="logout()">
                    <div>
                      {{  'Cerrar Sesión' | uppercase  }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
