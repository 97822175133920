import { Injectable } from '@angular/core';
import { UserProfile } from '../interfaces/user-profile.interface';
import { SubscriptionsConstant } from '../constants/subscriptions.constant';
import { UserStatus } from '../constants/user-status.constant';
import { UserRol } from '../interfaces/user-rol.interface';

@Injectable({
  providedIn: 'root'
})
export class User {
  constructor () {}

  getActiveSubscription(user: UserProfile): Object {
    return user.subscriptions.find((subscription) => {
      return subscription.status === SubscriptionsConstant.SUBS_ACTIVE;
    });
  }

  getRole(user: UserProfile, teamId: number): UserRol {
    return user.roles.find((role) => {
      return role.team && role.team.id === teamId;
    });
  }

  hasDepartment(user: UserProfile, teamId: number): boolean {
    return this.getRole(user, teamId).department !== undefined;
  }

  getFullName(user: UserProfile): string {
    return user.first_name + ' ' + user.last_name;
  }

  getUserDependentRole(user: UserProfile, supervisor: UserProfile): UserRol {
    return user.roles.find((role) => {
      return role.team && this.belongsToTeam(supervisor, role.team.id);
    });
  }

  belongsToTeam(user: UserProfile, teamId: number): boolean {
    return user.roles.some((role) => {
      return role.team && role.team.id === teamId;
    });
  }

  isPreregistered(user: UserProfile) {
    return user.status === UserStatus.PREREGISTERED;
  }
}
