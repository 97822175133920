import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  modalRef: NgbModalRef;
  errorMessage: string;
  success: boolean;
  matcher = new CustomErrorStateMatcher();

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.success = false;
    this.changePasswordForm = this.formBuilder.group({
        oldPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.minLength(8)
        ]),
        passwordConfirmation: new FormControl('', [
          Validators.required
        ])
      },
      {
        validator: this.mismatchingPasswords
      }
    );
    this.cleanMessages();
  }

  get f() { return this.changePasswordForm.controls; }

  openModal(changePasswordModal): void {
    this.cleanMessages();
    this.changePasswordForm.reset();
    this.modalRef = this.modalService.open(
      changePasswordModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal() {
    this.cleanMessages();
    this.changePasswordForm.reset();
    this.modalRef.close();
  }

  onSubmit() {
    this.cleanMessages();
    const result = Object.assign({}, this.changePasswordForm.value);
    const requestBody = {
      old_password: result.oldPassword,
      password: result.newPassword
    };

    this.loaderService.showLoader(true);
    this.api.changePassword(requestBody).subscribe(
      data => {
        this.success = true;
        this.changePasswordForm.reset();
        this.loaderService.showLoader(false);

      },
      error => {
        if (error.status === 422) {
          this.errorMessage = error.error.errors.old_password;
          this.loaderService.showLoader(false);
        }
      }
    );
  }

  cleanMessages() {
    this.errorMessage = undefined;
    this.success = false;
  }

  private mismatchingPasswords(changePasswordForm: FormGroup) {
    const pass1 = changePasswordForm.controls.newPassword.value;
    const pass2 = changePasswordForm.controls.passwordConfirmation.value;

    return pass1 === pass2 ? null : { 'missmatching-pass': true };
  }
}
