
import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';

declare var Stripe: any;

@Component({
  selector: 'app-add-credit-card',
  templateUrl: './add-credit-card.component.html',
  styleUrls: ['./add-credit-card.component.css']
})

export class AddCreditCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() businessId: number;
  @Input() paymentMethods: Array<any>;
  @Output() paymentMethodsChange = new EventEmitter<Array<any>>();

  addCreditCardForm: FormGroup;

  stripe: any;
  cardInput: any;
  stripeErrorMessage: string;
  errorMessage: string;
  modalRef: NgbModalRef;
  cardInputFocused = false;
  cardInputValid = false;
  serverResponse: boolean;
  hideCardInputs: boolean;
  showCardInput = false;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.errorMessage = undefined;
    this.stripeErrorMessage = undefined;
  }

  ngAfterViewInit() {
    const style = {
      base: {
        iconColor: '#FFF',
        color: '#FFF',
        '::placeholder': {
          color: '#999',
        },
      },
    };
    this.stripe = Stripe(environment.tokenStripe, { locale: 'es' });
    const elements = this.stripe.elements();
    this.cardInput = elements.create('card', {
      style: style,
    });
  }

  openModal(addCreditCardModal) {
    this.errorMessage = undefined;
    this.stripeErrorMessage = undefined;
    this.addCreditCardForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Zñáééióú.\s]*$'),
        Validators.minLength(5)
      ]),
      // phone: new FormControl('', [
      //   Validators.required,
      //   Validators.pattern('^[0-9]+$'),
      //   Validators.minLength(10)
      // ]),
      // callCode: new FormControl('+58', [
      //   Validators.required,
      // ]),
    });
    this.modalRef = this.modalService.open(
      addCreditCardModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2',
        beforeDismiss: this.closeModal.bind(this)
      }
    );
    this.cardInput.mount('#card-element');
    this.cardInput.addEventListener('change', event => {
      if (event.error) {
        this.stripeErrorMessage = event.error.message;
      } else {
        this.stripeErrorMessage = '';
        this.errorMessage = '';
      }

      if (event.complete) {
        this.cardInputValid = true;
      } else {
        this.cardInputValid = false;
      }
    });
    this.cardInput.addEventListener('focus', () => {
      this.cardInputFocused = true;
    });
    this.cardInput.addEventListener('blur', () => {
      this.cardInputFocused = false;
    });
    this.cardInput.on('ready', () => {
      this.showCardInput = true;
      this.cardInput.focus();
    });
  }

  closeModal() {
    this.clearForm();
    this.serverResponse = false;
    this.errorMessage = undefined;
    this.hideCardInputs = false;
    this.modalRef.close();
  }

  private clearForm(): void {
    this.addCreditCardForm.reset();
    this.errorMessage = undefined;
  }

  addPaymentMethod() {
    const billingDetails = {
      name: this.addCreditCardForm.controls.name.value,
      // phone: `${this.addCreditCardForm.controls.callCode.value}${this.addCreditCardForm.controls.phone.value}`
    };
    const metadata = {
      default: true,
      // callCode: this.addCreditCardForm.controls.callCode.value,
      // phone: this.addCreditCardForm.controls.phone.value
    };
    this.loaderService.showLoader(true);
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardInput,
      billing_details: billingDetails,
      metadata: metadata
    })
    .then(result => {
      this.paymentMethods.map(paymentMethod => {
        delete paymentMethod.metadata.default;
      });
      this.paymentMethods.push(result.paymentMethod);
      this.serverResponse = true;
      this.hideCardInputs = true;
      this.loaderService.showLoader(false);
      // this.closeModal();
      console.log(result);
    })
    .catch(error => {
      console.log(error);
      this.loaderService.showLoader(false);
      this.serverResponse = false;
      this.errorMessage = error;
      this.hideCardInputs = true;
    });
  }

  ngOnDestroy() {
    // this.cardInput.removeEventListener('change', event);
    this.cardInput.destroy();
  }

  get f() { return this.addCreditCardForm.controls; }
}

