<ng-template #massLoadModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-template [ngIf]="!success" [ngIfElse]="successfullySubscribed">
      <div class="content">
        <div class="text-center">
          <p class="m-0">Vas a suscribir</p>
          <h2 *ngIf="validUsers.length === 1" class = "mar-top">{{ validUsers.length }} usuario</h2>
          <h2 *ngIf="validUsers.length > 1" class = "mar-top">{{ validUsers.length }} usuarios</h2>
          <p class="m-0" *ngIf="invalidUsers.length === 1">Al mismo tiempo, podrás descargar un archivo Excel con el caso con error</p>
          <p class="m-0" *ngIf="invalidUsers.length === 1">Esta suscripcion se agregará a tu próxima factura</p>
          <p class="m-0" *ngIf="invalidUsers.length > 1">Al mismo tiempo, podrás descargar un archivo Excel con los casos con errores</p>
          <p class="m-0 " *ngIf="invalidUsers.length > 1">Estas suscripciones se agregarán a tu próxima factura</p>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center .marg-top" *ngIf="errorMessage">
          <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-pink mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="massSubscription()">
          SUSCRIBIR
        </button>
      </div>
    </ng-template>
    <ng-template #successfullySubscribed>
      <div class="content">
        <div class="text-center">
          <p>¡Excelente!</p>
          <h2 class="m-0" *ngIf="validUsers.length === 1">Suscribiste a {{ validUsers.length }} usuario. Lo verás en tu próxima factura</h2>
          <h2 class="m-0" *ngIf="validUsers.length > 1">Suscribiste a {{ validUsers.length }} usuarios. Los verás en tu próxima factura</h2>
        </div>
      </div>
      <div class="text-center">
        <button type="button" class="btn btn-pana-gradient w-100 mx-3" (click)="downloadInvalid()" *ngIf="invalidUsers.length > 0">
          DESCARGAR ERRORES
        </button>
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CERRAR
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
<button class="btn btn-pana-gradient w-100" (click)="openModal(massLoadModal)" [disabled]="validUsers.length === 0" *ngIf="validUsers.length === 1">
  SUSCRIBIR {{ validUsers.length }} USUARIO
</button>
<button class="btn btn-pana-gradient w-100" (click)="openModal(massLoadModal)" [disabled]="validUsers.length === 0" *ngIf="validUsers.length > 1">
  SUSCRIBIR {{ validUsers.length }} USUARIOS
</button>
