import { Component, OnInit, Input} from '@angular/core';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-set-favorite-card',
  templateUrl: './set-favorite-card.component.html',
  styleUrls: ['./set-favorite-card.component.css']
})
export class SetFavoriteCardComponent implements OnInit {
  @Input() paymentMethod: any;
  @Input() paymentMethods: Array<any>;

  setFavoriteCardForm: FormGroup;
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal
    ) {
   }

  ngOnInit() {
  }

  openModal(setFavoriteCardModal): void {
    this.modalRef = this.modalService.open(
      setFavoriteCardModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  setFavorite(paymentMethod) {
    this.paymentMethods.forEach(card => {
      if (card.id === paymentMethod.id) {
        card.metadata.default = true;
      } else {
        delete card.metadata.default;
      }
    });
    this.closeModal();
  }

  closeModal() {
    this.modalRef.close();
  }

}
