<div class="d-flex flex-column h-100 align-items-center vertical-scroll position-absolute w-100">
  <a [routerLink]="['/login']">
    <img src="/assets/logo-principal.png" height="90" width="74" class="d-inline-block align-top mt-5" alt="">
  </a>

  <div class="text-center mb-4 mt-5">
    <h1 class="font-light">Bienvenido a PANA</h1>
    <h5 class="font-light">Regístrate con tus datos</h5>
  </div>
  <div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
      <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      <p class="text-pink mt-1 text-center">
        {{ errorMessage }}
      </p>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="existingDocument">
      <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      <p class="text-pink mt-1 text-center">
        El documento de identidad ya se encuentra registrado
      </p>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="existingEmail">
      <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      <p class="text-pink mt-1 text-center">
        El correo electrónico ya se encuentra registrado
      </p>
    </div>
  </div>
  <div>
    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" class="form-group text-center">
      <div class="row m-0">
        <!-- Email -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-10 offset-md-1 col-lg-8 offset-lg-2 d-flex justify-content-start"
          *ngIf="!emailFromLogin">
          <mat-form-field class="w-100">
            <input matInput appAutofocus type="text" formControlName="email" placeholder="Email" [maxlength]="40">
            <mat-error *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)">
              <div *ngIf="f.email.errors.required">
                Por favor, introduce tu correo electrónico
              </div>
              <div *ngIf="f.email.errors.pattern">
                Por favor, introduce un correo electrónico válido
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-10 offset-md-1 col-lg-8 offset-lg-2 d-flex justify-content-start"
          *ngIf="emailFromLogin">
          <h3>{{ emailFromLoginStr }}</h3>
        </div>
        <!-- First Name -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-4 offset-lg-2 d-flex justify-content-start">
          <mat-form-field class="w-100">
            <input matInput type="text" formControlName="firstName" placeholder="Nombre" [maxlength]="24">
            <mat-error *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)">
              <div *ngIf="f.firstName.errors.required">
                Por favor, introduce tu nombre
              </div>
              <div *ngIf="f.firstName.errors.pattern">
                Por favor, introduce un nombre válido
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Last Name -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-0 col-lg-4 d-flex justify-content-start">
          <mat-form-field class="w-100">
            <input matInput type="text" formControlName="lastName" [maxlength]="24"  placeholder="Apellido">
            <mat-error *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)">
              <div *ngIf="f.lastName.errors.required">
                Por favor, introduce tu apellido
              </div>
              <div *ngIf="f.lastName.errors.pattern">
                Por favor, introduce un apellido válido
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- ID -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-4 offset-lg-2 d-flex justify-content-start">
          <mat-form-field class="w-100 id-type-select mr-2" > 
            <mat-select formControlName="documentType">
              <mat-option *ngFor="let idType of idTypes" [value]="idType">
                  {{idType}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex-grow-1" id="id-errormessage">
            <input matInput type="text" [maxlength]="10" formControlName="document" placeholder="Documento de identidad"
              [errorStateMatcher]="matcher">
            <mat-error *ngIf="f.document.invalid && (f.document.dirty || f.document.touched)">
                <div *ngIf="f.document.errors.required">
                  Por favor, introduce tu identificación
                </div>
              </mat-error>
              <mat-error *ngIf="registrationForm.hasError('invalid-identification') && (f.document.dirty || f.document.touched) && !f.document.invalid">
                <div>
                  Introduce una identificación válida
                </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Phone -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-0 col-lg-4 d-flex justify-content-start">
          <int-phone-prefix class="phone-prefix max-width flex-grow-1 mar-num" [defaultCountry]="'ve'" [locale]="'es'" [maxLength]="5"
            formControlName="callCode"></int-phone-prefix>
          <mat-form-field class="w-100 flex-grow-1" id="phone-errormessage">
            <input matInput formControlName="phone" maxlength="10" placeholder="Teléfono celular" [errorStateMatcher]="matcher">
            <mat-error *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" >
              <div *ngIf="f.phone.errors.required">
                Por favor, introduce tu teléfono celular
              </div>
              <div *ngIf="f.phone.errors.pattern">
                Por favor, introduce un teléfono celular válido
              </div>
            </mat-error>
            <mat-error *ngIf="registrationForm.hasError('invalid-phone') && (f.phone.dirty || f.phone.touched) && !f.phone.invalid">
              <div>
                Por favor, introduce un teléfono celular válido
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Password -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-4 offset-lg-2 d-flex justify-content-start">
          <mat-form-field class="w-100">
            <input matInput type="password" formControlName="password" placeholder="Contraseña" [maxlength]="32">
            <mat-error *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"> 
              <div *ngIf="f.password.errors.minlength">
                Tu contraseña debe tener al menos 8 caracteres
              </div>
              <div *ngIf="f.password.errors.required">
                Por favor, introduce tu contraseña
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Confirm Password -->
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-0 col-lg-4 d-flex justify-content-start">
          <mat-form-field class="w-100">
            <input matInput type="password" formControlName="password2" placeholder="Confirmación de Contraseña"
              [errorStateMatcher]="matcher" [maxlength]="32"> 
            <mat-error *ngIf="registrationForm.hasError('missmatching-pass') && (f.password2.dirty || f.password2.touched)">
              <div>
                Tu contraseña no coincide
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="enableEmailField()" *ngIf="emailFromLogin">
          <div>
            CAMBIAR CORREO
          </div>
        </div>
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!registrationForm.valid">
          ACEPTAR
        </button>
      </div>
    </form>
  </div>
</div>
