import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-business-contact',
  templateUrl: './business-contact.component.html',
  styleUrls: ['./business-contact.component.css']
})

export class BusinessContactComponent implements OnInit {
  @Input() title: string;
  @Input() showMe: boolean;
  @Output() showMeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  businessEmail: string;
  errorMessage: string;
  serverError: string;
  sending: boolean;
  success: boolean;
  // tslint:disable-next-line:max-line-length
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.sending = false;
    this.success = false;
  }

  contactBusiness() {
    this.api.contactBusiness(this.businessEmail)
      .subscribe(
        data => {
          this.success = true;
          this.sending = false;
        },
        error => {
          this.serverError = StringsConstant.UNKNOWN_ERROR;
          this.sending = false;
        }
      );
  }

  sendRequest() {
    this.errorMessage = undefined;
    if (this.emailRegex.test(this.businessEmail)) {
      this.sending = true;
      this.contactBusiness();
    } else {
      this.errorMessage = StringsConstant.INVALID_EMAIL;
    }
  }

  cancel() {
    this.showMe = false;
    this.showMeChange.emit(this.showMe);
  }

  ok() {
    this.cancel();
    location.reload(true);
  }
}
