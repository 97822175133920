<div class="d-flex flex-column h-100 vertical-scroll position-absolute w-100">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3>Mi Cuenta</h3>
  </div>

   <!-- Server error messages section -->
  <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
      <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      <p class="text-pink mt-1 text-center">
        El documento de identidad ya se encuentra registrado
      </p>
  </div>

  <!-- User profile data-->
  <div class="px-3 px-md-5 pb-4">
    <div class="card pana-card">
      <div class="card-body">

        <form [formGroup]="profileForm">
          <div class="d-flex align-items-center justify-content-between flex-grow-1" >
            <!-- First Name & Last Name --> 
            <h4 class="font-light m-0"  *ngIf = "!showProfileForm " [ngClass]="{'padded': showProfileForm }">{{ userProfile.first_name + ' ' + userProfile.last_name }}</h4>               
            <!-- First Name-->
            <div *ngIf="showProfileForm" >   
              <h6 class = "text-gray">Nombre</h6>
              <mat-form-field class = "small w-100" *ngIf="showProfileForm ">
                <input matInput type="text" formControlName="firstName" [maxlength]="24">
                <mat-error *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)">
                  <div *ngIf="f.firstName.errors.required">
                    Por favor, introduce tu nombr
                  </div>
                  <div *ngIf="f.firstName.errors.pattern">
                    Por favor, introduce un nombre válido 
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <!-- Last Name -->
            <div  class="w-41" *ngIf="showProfileForm">   
              <h6 class = "text-gray">Apellido</h6>
              <mat-form-field class = "small w-100" *ngIf="showProfileForm ">
                <input matInput type="text" formControlName="lastName" [maxlength]="24">
                <mat-error *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)">
                  <div *ngIf="f.lastName.errors.required">
                    Por favor, introduce tu apellido 
                  </div>
                  <div *ngIf="f.lastName.errors.pattern">
                    Por favor, introduce un nombre válido 
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="d-flex align-items-center  mb-4">
              <!-- Edit -->
              <div *ngIf="!showProfileForm" class="text-right">
                <img class="clickable" src="/assets/Pencil.svg" height="25" width="25" (click)="showForm()" matTooltip="Editar" matTooltipPosition="above" />
              </div>
              <div *ngIf="showProfileForm" class="text-right">
                <!-- Undo-->
                <img class="clickable" src="/assets/Revert.svg" name="undo" height="25" width="25" class="clickable margin-revert" (click)="cancelEdit()" matTooltip="Deshacer cambios" matTooltipPosition="above">
                <!-- Save changes -->
                <img class="clickable" src="/assets/Check.svg" name="check" height="25" width="25"  class="clickable" (click)="editUser()" matTooltip="Guardar cambios" matTooltipPosition="above">
              </div> 
            </div>
          </div>
          <div class="d-flex align-items-start justify-content-between flex-grow-1 mar-2 ">
            <!-- Email-->
            <div>
              <h6 class="text-gray">Email</h6>
              <h6 class="m-0">{{ userProfile.email || 'No disponible' }}</h6>
            </div>
            <!-- Phone -->
            <div class="w-15">
              <h6 class="text-gray">Teléfono Celular</h6>
              <h6 class="m-0" *ngIf="!showProfileForm ">{{ userProfile.phone || 'No disponible' }}</h6>
              <div class="d-flex justify-content-start" *ngIf="showProfileForm " >
                <int-phone-prefix
                  class="phone-prefix small"
                  [defaultCountry]="'ve'"
                  [locale]="'es'"
                  [maxLength]="5"
                  formControlName="callCode"></int-phone-prefix>
                <mat-form-field class="small flex-grow-1  phone-field" id="phone-errormessage1"> 
                  <input matInput type="text" [maxlength]="10" formControlName="phone" [errorStateMatcher]="matcher">
                  <mat-error class="d-flex" *ngIf="profileForm.controls.phone.invalid && (profileForm.controls.phone.dirty || profileForm.controls.phone.touched)">
                    <div *ngIf="profileForm.controls.phone.errors.required">
                      Por favor, introduce tu teléfono celular
                    </div>
                    <div *ngIf="profileForm.controls.phone.errors.pattern">
                      Por favor, introduce un teléfono celular válido
                    </div>
                  </mat-error>
                  <mat-error *ngIf="profileForm.hasError('invalid-phone') && (profileForm.controls.phone.dirty || profileForm.controls.phone.touched) && !profileForm.controls.phone.invalid">
                    <div>
                      Por favor, introduce un teléfono celular válido
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- ID -->
            <div class="w-31">
              <h6 class="text-gray">Documento de identidad</h6>
              <h6 class="m-0" *ngIf = "!showProfileForm " [ngClass]="{'padded': showProfileForm }">{{ userProfile.type_document + '-' + userProfile.document }}</h6>
              <mat-form-field class = "w-20 id-type-select mr-2 small" *ngIf = "showProfileForm ">
                <mat-select formControlName="documentType">
                  <mat-option *ngFor="let idType of idTypes" [value]="idType">
                    {{idType}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="w-60 small" *ngIf="showProfileForm  " id="id-errormessage1">
                <input matInput type="text" [maxlength]="10" formControlName="document" [errorStateMatcher]="matcher">
                <mat-error *ngIf="f.document.invalid && (f.document.dirty || f.document.touched)">
                  <div *ngIf="f.document.errors.required">
                    Por favor, introduce tu identificación
                  </div>
                  <div *ngIf="f.document.errors.pattern">
                    Por favor, introduce una identificación válida
                  </div>
                </mat-error>
                <mat-error *ngIf="profileForm.hasError('invalid-identification') && (f.document.dirty || f.document.touched) && !f.document.invalid">
                  <div>
                    Por favor, Introduce una identificación válida
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="text-center d-flex">
            <app-change-password class="flex-grow-1"></app-change-password>
          </div>
        </form>  
      </div>
    </div>
  </div>

  <!-- Subscription data-->
  <div class="px-3 px-md-5 pb-4">
    <div class="card pana-card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h4 class="font-light m-0">Mi Suscripción</h4>
        </div>
        <ng-template [ngIf]="subscription" [ngIfElse]="noSubscriptions">
          <div class="row m-0 mb-5">
            <div class="col-12 col-sm-4 p-0 pr-sm-3">
              <h6 class="text-gray">Estado de Suscripción</h6>
              <h6 class="m-0">{{ getSubscriptionStatus() }}</h6>
            </div>
            <div class="col-12 col-sm-4 p-0 px-sm-3 mt-3 mt-sm-0">
              <h6 class="text-gray" *ngIf="!businessTeam">Vencimiento de Suscripción</h6>
              <h6 class="m-0" *ngIf="!businessTeam">{{ subscription.expires_at | date:'d-MMM-y':'-0400':'es-VE' | replace : '.' : '' }}</h6>
            </div>
            <div class="col-12 col-sm-4 p-0 pl-sm-3 mt-3 mt-sm-0">
              <h6 class="text-gray">Servicio Suscrito</h6>
              <h6 class="m-0">{{ subscription.service_name || 'No disponible' }}</h6>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-pana-gradient w-100" *ngIf="!businessTeam && false">
              EXTENDER SUSCRIPCIÓN
            </button>
          </div>
        </ng-template>
        <ng-template #noSubscriptions>
          <div class="text-center my-5">
            <h6>No tienes una suscripción activa</h6>
          </div>
          <div class="wtext-center">
            <button class="btn btn-pana-gradient w-100 m-0" *ngIf="false">
              CONSEGUIR SUSCRIPCIÓN
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Teams data -->
  <div class="px-3 px-md-5 pb-4">
    <div class="row m-0">
      <div class="col-12 col-sm-6 p-0 pr-sm-3">
        <!-- Family Group data -->
        <div class="card pana-card h-100">
          <div class="card-body h-100">
            <div class="d-flex flex-column h-100">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <h4 class="font-light m-0">Mi Grupo Familiar</h4>
              </div>
              <div class="flex-grow-1" [ngClass]="{'d-flex align-items-center justify-content-center': !familyGroup}">
                <ng-template [ngIf]="familyGroup" [ngIfElse]="noFamily">
                  <div>
                    <h5>{{ familyGroup.team.display_name }}</h5>
                  </div>
                  <div>
                    <h6 class="text-gray">ID</h6>
                    <h6 class="m-0">{{ familyGroup.team.id }}</h6>
                  </div>
                </ng-template>
                <ng-template #noFamily>
                  <div class="text-center">
                    <h6 class="mb-4">¿Estás interesado en suscribir a tu familia?</h6>
                    <h6>PRÓXIMAMENTE...</h6>
                  </div>
                </ng-template>
              </div>
              <div class="w-100 text-center">
                <div class="btn btn-pana-gradient-dark w-100" *ngIf="familyGroup">
                  <div>
                    {{ 'ir a mi grupo familiar' | uppercase }}
                  </div>
                </div>
                <button class="btn btn-pana-gradient w-100" *ngIf="!familyGroup && false">
                  {{ 'crear grupo familiar' | uppercase }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 p-0 pl-sm-3 mt-4 mt-sm-0">
        <!-- Business team data -->
        <div class="card pana-card h-100">
          <div class="card-body h-100">
            <div class="d-flex flex-column h-100">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <h4 class="font-light m-0">Mi Empresa</h4>
              </div>
              <div class="flex-grow-1" [ngClass]="{'d-flex align-items-center justify-content-center': !businessTeam}">
                <ng-template [ngIf]="businessTeam" [ngIfElse]="noBusiness">
                  <div>
                    <h5>{{ businessTeam.team.name | titlecase }}</h5>
                  </div>
                  <div>
                    <h6 class="text-gray">Perfil</h6>
                    <h6 class="m-0">{{ businessTeam.display_name }}</h6>
                  </div>
                  <div class="mt-3">
                    <h6 class="text-gray">Área</h6>
                    <h6 class="m-0">{{ (userProfile.department || 'N/A') }}</h6>
                  </div>
                </ng-template>
                <ng-template #noBusiness>
                  <h6 class="text-center">
                    No perteneces a ninguna empresa
                    <br>
                    ¿Estás interesado en PANA Corporativo?
                  </h6>
                </ng-template>
              </div>
              <div class="w-100 text-center">
                <div class="btn btn-pana-gradient-dark w-100" [routerLink]="['/my-business']" *ngIf="businessTeam">
                  <div>
                    {{ 'ir a mi empresa' | uppercase }}
                  </div>
                </div>
                <app-corporative-contact-modal *ngIf="!businessTeam"></app-corporative-contact-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
