import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { LoaderService } from '../../services/loader.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { LoginModel } from '../../models/login.model';
import { NavigationService } from '../../services/navigation.service';

import { StringsConstant } from '../../constants/strings.constant';
import { CustomErrorStatePassword } from 'src/app/utils/custom-error-state-password';

const STATUS_INACTIVE = 0;
const STATUS_ACTIVE = 1;
const STATUS_BLOCKED = 2;
const STATUS_PREREGISTERED = 3;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // this value turn between left/right side.
  isLeftVisible = true;
  // --------------------
  loginForm: FormGroup;
  returnUrl: string;
  fromRegister: boolean;
  fromRegisterMessage: string;
  errorMessage: string;
  temporaryPassMessage: string;
  inPasswordStep: boolean;
  isPreregistered: boolean;
  matcher = new CustomErrorStatePassword(this);
  emailInputShown = true;
  passwordInputShown = false;
  loginFirstStep = true;
  loginSecondStep = false;

  // gutty
  requestEmailSubmit = 'submit';
  requestPasswordSubmit = 'button';

  constructor(
    public globalData: GlobalDataService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private user: UserService,
    private loaderService: LoaderService,
    private navigationService: NavigationService
  ) {
    this.showLoginNextStep = this.showLoginNextStep.bind(this);
  }

  ngOnInit() {
    this.isPreregistered = false;
    this.inPasswordStep = false;
    this.loginForm = this.createFormGroup(this.formBuilder);
    this.fromRegisterMessage = StringsConstant.SUCCESSFUL_REGISTER;
    if (this.user.isLogged()) {
      this.router.navigate(['']);
    } else {
      // This isn't mandatory since error.interceptor helper cleans localstorage
      // data when 401 HTTP Errors are raised from API. We do it nonetheless.
      this.user.logout();
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/service';
      this.fromRegister = this.route.snapshot.queryParams.hasOwnProperty('fromRegister');
    }
  }

  /**
   * Performs login request to PANA's API from form values.
   * Sets credentials and user's profile if success.
   */
  requestLogin(): void {
    const result: LoginModel = Object.assign({}, this.loginForm.value);
    this.api.login(result)
      .subscribe(
        data => {
          const accessToken = data.access_token;
          const username = result.username;
          const tempPass = this.loginForm.value.password;
          this.navigationService.params = {tempPass: tempPass};
          this.user.setCredentials(username, accessToken);
          this.requestProfile();
        },
        error => {
          if (error.status === 401) {
            this.errorMessage = StringsConstant.INVALID_CREDENTIALS;
          } else {
            this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          }
          this.loaderService.showLoader(false);
        }
      );
  }

  temPassLogin(): void {
    const result: LoginModel = Object.assign({}, this.loginForm.value);
    this.api.login(result)
      .subscribe(
        data => {
          const accessToken = data.access_token;
          const username = result.username;
          const tempPass = this.loginForm.value.password;
          this.navigationService.params = {tempPass: tempPass};
          this.user.setCredentials(username, accessToken);
          this.requestProfile();
        },
        error => {
          if (error.status === 401) {
            this.errorMessage = StringsConstant.INVALID_TEMPASS;
          } else {
            this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          }
          this.loaderService.showLoader(false);
        }
      );
  }

  /**
   * Performs temporary password request to PANA's API.
   */
  requestTemporaryPassword(): void {
    this.temporaryPassMessage = null;
    this.loaderService.showLoader(true);
    const userEmail = this.loginForm.value.username;
    this.api.sendTemporaryPassword(userEmail).subscribe(
      data => {
        this.temporaryPassMessage = data.message;
        this.loaderService.showLoader(false);
      },
      () => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  /**
   * Performs app credentials request to PANA's API.
   * Sets app token and request email status when success.
   */
  requestAppCredentials() {
    this.loaderService.showLoader(true);
    this.api.appAccessToken()
      .subscribe(
        data => {
          const appToken = data.access_token;
          this.user.setAppToken(appToken);
          this.requestStatus();
        },
        () => {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );
  }

  /**
   * Resets login form and goes to email step.
   */
  clearEmail() {
    this.showLoginNextStep();
    this.loginForm.reset();
    this.inPasswordStep = false;
    this.isLeftVisible = true;
    this.temporaryPassMessage = null;
  }

  /**
   * Creates login form from a LoginModel instance and sets
   * its validators functions.
   * @param formBuilder
   */
  private createFormGroup(formBuilder: FormBuilder) {
    // tslint:disable-next-line:max-line-length
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let formGroup: FormGroup;
    formGroup = formBuilder.group(new LoginModel);
    formGroup.controls['username'].setValidators([
      Validators.required,
      Validators.pattern(emailRegex)
    ]);
    formGroup.controls['password'].setValidators([
      Validators.required
    ]);
    return formGroup;
  }

  /**
   * Performs profile request to PANA's API.
   * Sets the profile and navigates to return URL if the current
   * user has a previously setted password. Navigates to set-password
   * section otherwise.
   */
  private requestProfile(): void {
    this.api.getProfile().subscribe(
      data => {
        this.user.setProfile(data);
        this.loaderService.showLoader(false);
        if (this.isPreregistered) {
          this.router.navigate(['/set-password']);
        } else {
          this.navigationService.params = undefined;
          this.router.navigate([this.returnUrl]);
        }
      },
      () => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  /**
   * Performs user status request to PANA's API.
   * Navigates to User Registration section if the specified email
   * is not in PANA's database. Shows password step otherwise.
   */
  private requestStatus() {
    this.isLeftVisible = false;
    const userEmail = this.loginForm.value.username;
    const emailObject = { email: userEmail };
    this.errorMessage = undefined;
    this.api.userStatus(emailObject).subscribe(
      data => {
        switch (data.id) {
          case STATUS_ACTIVE:
            this.inPasswordStep = true;
            this.isPreregistered = false;
            this.showLoginNextStep();
            break;
          case STATUS_INACTIVE:
            this.navigationService.params = { unregisteredEmail: userEmail };
            this.router.navigate(['/register']);
            break;
          case STATUS_PREREGISTERED:
            this.inPasswordStep = true;
            this.isPreregistered = true;
            this.temporaryPassMessage = null;
            this.showLoginNextStep();
            break;
          case STATUS_BLOCKED:
            this.errorMessage = StringsConstant.BLOCKED_USER;
            break;
        }
        this.loaderService.showLoader(false);
      },
      () => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  showLoginNextStep(){
    if(this.loginFirstStep){
      this.loginFirstStep = false;
      this.requestEmailSubmit = 'button';
    }else{
      this.loginFirstStep = true;
      this.requestEmailSubmit = 'submit';
    }

    if(this.loginSecondStep){
      this.loginSecondStep = false;
      this.requestPasswordSubmit = 'button';
    }else{
      this.loginSecondStep = true;
      this.requestPasswordSubmit = 'submit';
    }

    this.emailInputShown = !this.emailInputShown;
    this.passwordInputShown = !this.passwordInputShown;
  }

  /**
   * Determines if the current error message, if any,
   * is a wrong password or wrong temporary password error.
   */
  isAuthError() {
    return this.errorMessage === StringsConstant.INVALID_CREDENTIALS;
  }
}
