<ng-template #deleteDepartmentModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <div class="content">
      <div class="text-center mb-3">
        <p>
          ¡Hey!
          <br>
          Vas a eliminar el área
        </p>
      </div>
      <div class="text-center mb-3">
        <h2>{{ departmentName | titlecase }}</h2>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
        <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
        <p class="text-pink mt-1 text-center">
          {{ errorMessage }}
        </p>
      </div>
    </div>
    <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
      <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
        <div>
          CANCELAR
        </div>
      </div>
      <button type="submit" class="btn btn-pana-gradient w-100 mx-3" (click)="removeDepartment()">
        ELIMINAR
      </button>
    </div>
  </div>
</ng-template>

<img src="/assets/close-light.svg" height="20" width="20" name="times" size="lg" class="clickable" (click)="openModal(deleteDepartmentModal)" matTooltip="Eliminar" matTooltipPosition="above">