import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var NavigationService = /** @class */ (function () {
    function NavigationService() {
        // Navigation disable values
        this.disableNavigationSource = new BehaviorSubject(false);
        this.currentDisablingStatus$ = this.disableNavigationSource.asObservable();
    }
    /**
     * Updates navigation disability status
     * @param disable - Boolean: Whether site navigation should disabled or not
     */
    NavigationService.prototype.disableStatus = function (disable) {
        this.disableNavigationSource.next(disable);
    };
    Object.defineProperty(NavigationService.prototype, "params", {
        /**
         * Gets navigation params object
         * @returns - Object with navigation params values
         */
        get: function () {
            return this._params;
        },
        /**
         * Sets navigation params object
         * @param params - Object with navigation params values
         */
        set: function (params) {
            this._params = params;
        },
        enumerable: true,
        configurable: true
    });
    NavigationService.ngInjectableDef = i0.defineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}());
export { NavigationService };
