import { OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '../../services/user.service';
import { SubscriptionsConstant } from '../../constants/subscriptions.constant';
import { ProfileEdit as MyProfileForm } from '../../forms/corporative/my-profile.form';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoaderService } from '../../services/loader.service';
import { ApiService } from '../../services/api.service';
import { StringsConstant } from '../../constants/strings.constant';
import { User } from 'src/app/utils/user.utils';
import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
var MyAccountComponent = /** @class */ (function () {
    function MyAccountComponent(user, loaderService, api, userUtils, router, formBuilder) {
        this.user = user;
        this.loaderService = loaderService;
        this.api = api;
        this.userUtils = userUtils;
        this.router = router;
        this.formBuilder = formBuilder;
        this.matcher = new CustomErrorStateMatcher();
    }
    MyAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userProfile = this.user.getProfile();
        this.setSubscription();
        this.setFamilyGroup();
        this.setBusinessTeam();
        this.idTypes = ['V', 'E', 'P'];
        this.existingDocument = false;
        this.navigationSubscription = this.router.events.subscribe(function (e) {
            /**
             * If it is a NavigationEnd event, re-initalize the component.
             * This is useful when the current section is clicked on at the sidebar.
             */
            if (e instanceof NavigationEnd) {
                _this.initialize();
            }
        });
        this.initialize();
    };
    MyAccountComponent.prototype.ngOnDestroy = function () {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    };
    /**
    * Initializes class' variables values
    * Sets businessTeam if specified. Fetch it otherwise (when not a SuperAdmin)
    */
    MyAccountComponent.prototype.initialize = function () {
        this.businessTeam = undefined;
        this.focusedUser = undefined;
        this.getProfileForm();
    };
    /**
     * Evaluates a subscription status and returns its display name.
     * @return string - Subscription status display name.
     */
    MyAccountComponent.prototype.getSubscriptionStatus = function () {
        switch (this.subscription.status) {
            case SubscriptionsConstant.SUBS_INACTIVE:
                return 'Inactiva';
            case SubscriptionsConstant.SUBS_ACTIVE:
                return 'Activa';
            case SubscriptionsConstant.SUBS_SUSPENDED:
                return 'Suspendida';
            case SubscriptionsConstant.SUBS_CREATED:
                return 'Creada';
            default:
                return 'No disponible';
        }
    };
    MyAccountComponent.prototype.setFocusedUser = function (user) {
        this.cancelEdit();
        this.focusedUser = user;
    };
    /**
    * Determines if user from users table is selected for details
    * @param user - Object with user data
    */
    MyAccountComponent.prototype.isFocused = function (user) {
        return this.focusedUser && this.focusedUser.email === user.email;
    };
    /**
   * Shows user data edition form, initializing and fetching required data
   */
    MyAccountComponent.prototype.showForm = function () {
        this.errorMessage = undefined;
        this.idTypes = ['V', 'E', 'P'];
        var callCode = this.userProfile.phone.match(/\+[0-9]+/g)[0];
        var phone = this.userProfile.phone.match(/[0-9]{10}/g)[0];
        this.profileForm.patchValue({
            firstName: this.userProfile.first_name,
            lastName: this.userProfile.last_name,
            documentType: this.userProfile.type_document,
            document: this.userProfile.document,
            email: this.userProfile.email,
            callCode: callCode,
            phone: phone,
        });
        this.profileForm.updateValueAndValidity();
        this.showProfileForm = true;
    };
    /**
   * Performs user modifications reques to PANA's API
   * Hides edition form and updates both list of users and focused
   * user when success.
   */
    MyAccountComponent.prototype.editUser = function () {
        var _this = this;
        if (!this.profileForm.valid) {
            return;
        }
        this.errorMessage = undefined;
        this.loaderService.showLoader(true);
        var requestBody = Object.assign({}, this.profileForm.value);
        requestBody.call_code = requestBody.callCode;
        requestBody.first_name = requestBody.firstName;
        requestBody.last_name = requestBody.lastName;
        requestBody.type_document = requestBody.documentType;
        this.api.editProfile(requestBody)
            .subscribe(function (data) {
            _this.user.setProfile(data.user);
            _this.userProfile = _this.user.getProfile();
            _this.cancelEdit();
            _this.loaderService.showLoader(false);
        }, function (error) {
            var messageObject = error.error.errors;
            _this.existingDocument = messageObject.hasOwnProperty('document');
            _this.errorMessage = StringsConstant.UNKNOWN_ERROR;
            _this.loaderService.showLoader(false);
        });
    };
    /**
    * Sets focused user and shows edition form
    * @param user - Object with selected user data from users table*/
    MyAccountComponent.prototype.setAndEdit = function (user) {
        this.showForm();
    };
    /**
     * Resets and hides user edition form
     */
    MyAccountComponent.prototype.cancelEdit = function () {
        this.profileForm.reset();
        this.errorMessage = undefined;
        this.showProfileForm = false;
    };
    /**
   * Initializes a new user edition form and sets its validators
   */
    MyAccountComponent.prototype.getProfileForm = function () {
        var _this = this;
        var profileForm = new MyProfileForm;
        this.profileForm = this.formBuilder.group(profileForm, {
            validator: [
                this.invalidPhone,
                this.invalidIdentification
            ]
        });
        Object.keys(this.profileForm.controls).forEach(function (field) {
            _this.profileForm.get(field).setValidators(profileForm.getValidators(field));
        });
    };
    /**
   * Class' form validator: checks wheter a given venezuelan phone number
   * is valid or not
   * @param profileForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
    MyAccountComponent.prototype.invalidPhone = function (profileForm) {
        var vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
        var vzlanPhone = profileForm.controls.callCode.value === '+58';
        if (vzlanPhone && !vzlaPhoneRegex.test(profileForm.controls.phone.value)) {
            return { 'invalid-phone': true };
        }
    };
    /**
     * Class' form validator: checks wheter a given venezuelan ID number
     * is valid or not
     * @param registrationForm - Registration form instance
     * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
     */
    MyAccountComponent.prototype.invalidIdentification = function (registrationForm) {
        var idCardRegex = /^[0-9]{7,9}$/;
        var pasRegex = /^[a-zA-Z0-9]{5,10}$/;
        var documentValue;
        documentValue = registrationForm.controls.document.value;
        var isValid;
        switch (registrationForm.controls.documentType.value) {
            case 'V':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'E':
                isValid = idCardRegex.test(documentValue);
                break;
            case 'P':
                isValid = pasRegex.test(documentValue);
                break;
            default:
                isValid = false;
                break;
        }
        return isValid ? null : { 'invalid-identification': true };
    };
    /**
     * Sets subscription class variable value from an active subscription within
     * current user's profile.
     */
    MyAccountComponent.prototype.setSubscription = function () {
        this.subscription = this.userProfile.subscriptions.find(function (subscription) {
            return subscription.status === SubscriptionsConstant.SUBS_ACTIVE;
        });
    };
    /**
   * Sets familyGroup class variable value from an active subscription within
   * current user's profile.
   */
    MyAccountComponent.prototype.setFamilyGroup = function () {
        this.familyGroup = this.userProfile.roles.find(function (role) {
            var team = role.team;
            return team ? team.type_label === SubscriptionsConstant.FAMILY_GROUP : false;
        });
    };
    /**
    * Sets businessTeam class variable value from an active subscription within
    * current user's profile.
    */
    MyAccountComponent.prototype.setBusinessTeam = function () {
        this.businessTeam = this.userProfile.roles.find(function (role) {
            var team = role.team;
            return team ? team.type_label === SubscriptionsConstant.BUSINESS : false;
        });
    };
    Object.defineProperty(MyAccountComponent.prototype, "f", {
        get: function () { return this.profileForm.controls; },
        enumerable: true,
        configurable: true
    });
    return MyAccountComponent;
}());
export { MyAccountComponent };
