import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { StringsConstant } from 'src/app/constants/strings.constant';

@Component({
  selector: 'app-modify-department',
  templateUrl: './modify-department.component.html',
  styleUrls: ['./modify-department.component.css']
})
export class ModifyDepartmentComponent implements OnInit {
  @Input() businessId: number;
  @Input() departmentName: string;
  @Input() departmentId: number;
  @Output() refresh = new EventEmitter<boolean>();

  modalRef: NgbModalRef;
  newDepartmentName: string;
  errorMessage: string;
  showConfirmation: boolean;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
  }

  openModal(modifyDepartmentModal) {
    this.modalRef = this.modalService.open(
      modifyDepartmentModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal() {
    this.clearName();
    this.showConfirmation = false;
    this.modalRef.close();
  }

  modifyDepartment() {
    this.loaderService.showLoader(true);
    this.api.modifyDepartment(this.businessId, this.departmentId, this.newDepartmentName)
    .subscribe(
      data => {
        this.closeModal();
        this.loaderService.showLoader(false);
        this.refresh.emit(true);
      },
      error => {
        this.loaderService.showLoader(false);
        if (error.status === 400) {
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        }
      }
    );
  }

  private clearName(): void {
    this.newDepartmentName = '';
    this.errorMessage = undefined;
  }

}
