import { Pipe, PipeTransform } from '@angular/core';
import { UserProfile } from '../interfaces/user-profile.interface';
import { User } from '../utils/user.utils';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

  constructor(private userUtils: User) {}

  transform(users: Array<UserProfile>, searchString: string, businessId: number): any {
    if (!searchString) { return users; }

    searchString = searchString.toLowerCase();

    const filteredUsers = users.filter(user => {
      const first_name = user.first_name.toLowerCase().includes(searchString);
      const last_name = user.last_name.toLowerCase().includes(searchString);
      const document = (user.type_document + '-' + user.document).toLowerCase()
                       .includes(searchString);
      const email = user.email.toLowerCase().includes(searchString);
      const phone = user.phone.toLowerCase().includes(searchString);
      const role = this.userUtils.getRole(user, businessId).display_name
                   .toLowerCase().includes(searchString);
      const department = (this.userUtils.getRole(user, businessId).department || 'N/A')
                         .toLowerCase().includes(searchString);

      return (first_name || last_name || document || email || phone || department || role );
    });

    if (filteredUsers.length < 1) { return [-1]; }

    return filteredUsers;
  }

}
