import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { User } from '../../utils/user.utils';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-unsubscribe-business-user',
  templateUrl: './unsubscribe-business-user.component.html',
  styleUrls: ['./unsubscribe-business-user.component.css']
})
export class UnsubscribeBusinessUserComponent implements OnInit {
  @Input() companyId: number;
  @Input() size: number;
  @Input() clear: boolean;
  @Input() user: UserProfile;
  @Input() focusedUser: UserProfile;
  @Input() users: UserProfile[];
  @Output() focusedUserChange: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();
  @Output() usersChange: EventEmitter<UserProfile[]> = new EventEmitter<UserProfile[]>();
  @Output() btnClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalRef: NgbModalRef;
  userRole: any;
  errorMessage: string;
  deleted: boolean;

  iconSrc = '/assets/Cross.svg';

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private userUtils: User,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    this.deleted = false;
    this.userRole = this.userUtils.getRole(this.user, this.companyId);
    if (this.clear) {
      this.iconSrc = '/assets/Cross-light.svg';
    }
  }

  openModal(unsubscribeUserModal) {
    this.btnClick.emit(true);

    this.modalRef = this.modalService.open(
      unsubscribeUserModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal() {
    this.modalRef.close();
    this.deleted = false;
  }

  unsubscribeUser() {
    this.errorMessage = undefined;
    this.loader.showLoader(true);
    this.api.unsubscribeBusinessUser(this.companyId, this.user.id).subscribe(
      data => {
        this.users = data.clients;
        this.usersChange.emit(this.users);
        this.focusedUser = undefined;
        this.focusedUserChange.emit(this.focusedUser);
        this.deleted = true;
        this.loader.showLoader(false);
      },
      () => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loader.showLoader(false);
      }
    );
  }
}
