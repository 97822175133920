import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// Angular takes care of swapping the environment file for the correct one.
import { environment } from '../../environments/environment';
import { LoginModel } from '../models/login.model';

import { UserProfile } from '../interfaces/user-profile.interface';
import { Transaction } from '../interfaces/transaction.interface';
import { map, tap, last, catchError } from 'rxjs/operators';
import { isString, isArray, isObject } from 'util';
import { LoaderService } from './loader.service';
import { Invoice } from '../utils/invoice';

const httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  /* DO NOT SUBSCRIBE HERE. SUBSCRIBE IN COMPONENTS. IF YOU DON'T KNOW HOW
  *  TO SUBSCRIBE FROM COMPONENTS, THEN FIND A WAY. :)
  */

  private API_ROOT = environment.apiRoot;

  private getEndpointURL(endpointURL: string): string {
    return this.API_ROOT + endpointURL;
  }

  /**
   * POST: checks users' registration status
   * @param emailObject - Object with users' email
   */
  userStatus(emailObject: any): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v2/users/status');
    const appToken = 'Bearer ' + localStorage.getItem('appToken');
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': appToken
      })
    };
    return this.http.post<any>(endpointURL, emailObject, options);
  }

  /**
   * POST: performs authentication with the API
   * @param loginModel - LoginModel class (username and password object)
   */
  login(loginModel: LoginModel): Observable<any> {
    const loginOptions = {
      'grant_type': 'password',
      'client_id': '2',
      'client_secret': 'muwi4Ic13GQqxiNdwLnbsSrydnf6cWydnHNY74zY'
    };
    const endpointURL = this.getEndpointURL('oauth/token');
    const body = Object.assign(loginModel, loginOptions);
    return this.http.post<any>(endpointURL, body, httpOptions);
  }

  /**
   * POST: performs app authentication with the API
   */
  appAccessToken(): Observable<any> {
    const appTokenOptions = {
      'grant_type': 'client_credentials',
      'client_id': '2',
      'client_secret': 'muwi4Ic13GQqxiNdwLnbsSrydnf6cWydnHNY74zY'
    };
    const endpointURL = this.getEndpointURL('oauth/token');
    return this.http.post<any>(endpointURL, appTokenOptions, httpOptions);
  }

  getProfile(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v3/profile');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * POST: requests the API for a password reset
   * @param recoveryEmail - string (email for reset password steps to be sent)
   */
  forgotPass(recoveryEmail: string): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v2/reset_password');
    const appToken = 'Bearer ' + localStorage.getItem('appToken');
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': appToken
      })
    };
    const body = {'email': recoveryEmail};
    return this.http.post<any>(endpointURL, body, options);
  }

  /**
   * POST: Sends email with temporary password for preregistered
   * @param preregisteredEmail - string (preregistered user's email)
  */
 sendTemporaryPassword(preregisteredEmail: string): Observable<any> {
   const endpointURL = this.getEndpointURL('api/v2/users/tmp-password/send');
   const appToken = 'Bearer ' + localStorage.getItem('appToken');
   const options = {
     headers: new HttpHeaders({
       'Accept': 'application/json',
       'Content-Type': 'application/json',
       'Authorization': appToken
     })
   };
   const body = {'email': preregisteredEmail };
   return this.http.post<any>(endpointURL, body, options);
 }

  /**
   * POST: requests the API for a password reset
   * @param businessEmail - string (email for PANA business contact)
   */
  contactBusiness(businessEmail: string): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/contact');
    const body = {'email': businessEmail};
    return this.http.post<any>(endpointURL, body, httpOptions);
  }

  /**
   * POST: performs authentication with the API
   * @param userRegisterBody - Object with new user's necessary data
   */
  register(userRegisterBody: Object): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v2/register');
    const appToken = 'Bearer ' + localStorage.getItem('appToken');
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': appToken
      })
    };
    return this.http.post<any>(endpointURL, userRegisterBody, options);
  }

  /**
   * POST: requests companion service
   * @param serviceRequestObject - Object with a service request data
   */
  requestService(serviceRequestObject: Object): Observable<any> {
    const endpointURL = this.getEndpointURL('api/petition/create');
    return this.http.post<any>(endpointURL, serviceRequestObject, httpOptions);
  }

  /**
  * GET: requests for predefined cancellation reasons
  */
  cancellationReasons(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/reasons');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * POST: cancels companion service
   * @param serviceCancellationObject - Object with a service cancellation data
   */
  cancelService(serviceCancellationObject: Object, requestId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/petition/' + requestId + '/cancel');
    return this.http.post<any>(endpointURL, serviceCancellationObject, httpOptions);
  }

  /**
  * GET: requests for an active service request (if exists)
  */
  getActiveRequest(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/petition/active');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
  * GET: requests data for all members in a team
  * @param teamId - Team's id
  */
  getTeamMembers(teamId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + teamId + '/users');
    return this.http.get<Array<UserProfile>>(endpointURL, httpOptions);
  }

  getDependentMembers(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/third-party/users');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
 * POST: requests companion service for an employee
 * @param serviceRequestObject - Object with a service request data
 */
  createDependentRequest(serviceRequestObject: Object): Observable<any> {
    const endpointURL = this.getEndpointURL('api/third-party/petition/create');
    return this.http.post<any>(endpointURL, serviceRequestObject, httpOptions);
  }

  /**
   * POST: cancels companion service
   * @param serviceCancellationObject - Object with a service cancellation data
   */
  cancelDependentRequest(serviceCancellationObject: Object, requestId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/third-party/petition/' + requestId + '/cancel');
    return this.http.post<any>(endpointURL, serviceCancellationObject, httpOptions);
  }

  /**
  * GET: requests for active service requests (if they exist) related to the user
  */
  getDependentActiveRequest(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/third-party/petitions/active');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * PATCH: cancels companion service
   * @param passwordChangeObject - Object with password change data
   */
  changePassword(passwordChangeObject: Object): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v2/profile/password');
    return this.http.patch<any>(endpointURL, passwordChangeObject, httpOptions);
  }

  /**
   * GET: gets all roles for an corporative user business id
   */
  getBusinessRoles(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/roles');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * GET: gets all departaments for an specific business id
   * @param businessId - Business id
   */
  getBusinessDepartments(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * POST: creates a new department for a given business
   * @param businessId - number (business id)
   * @param departmentName - string (department's name)
   */
  createDepartment(businessId: number, departmentName: string): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/create');
    const body = { 'name': departmentName };
    return this.http.post<any>(endpointURL, body, httpOptions);
  }

  /**
   * PUT: creates a new department for a given business
   * @param businessId - number (business id)
   * @param departmentId - number (department id)
   * @param departmentName - string (department's new name)
   */
  modifyDepartment(businessId: number, departmentId: number, departmentName: string): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/' + departmentId);
    const body = { 'name': departmentName };
    return this.http.put<any>(endpointURL, body, httpOptions);
  }

  /**
   * DELETE: creates a new department for a given business
   * @param businessId - number (business id)
   * @param departmentId - number (department id)
   */
  removeDepartment(businessId: number, departmentId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/' + departmentId);
    return this.http.delete<any>(endpointURL, httpOptions);
  }

  /**
   * POST: Validate if user data is OK for business register
   * @param businessId - number (business id)
   * @param requestBody - Object (Contains new business user necessary data)
   */
  validateBusinessRegister(businessId: number, requestBody): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/validate');
    return this.http.post<any>(endpointURL, requestBody, httpOptions);
  }

  /**
   * POST: Validate if user data is OK for mass business register
   * @param businessId - number (business id)
   * @param requestBody - Object (Contains new business user necessary data)
   */
  validateMassBusinessRegister(businessId: number, requestBody): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/mass/validate');
    return this.http.post<any>(endpointURL, requestBody);
  }

  /**
   * POST: Register new user in business team
   * @param businessId - number (business id)
   * @param requestBody - Object (Contains new business user necessary data)
   */
  businessRegister(businessId: number, requestBody): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users');
    return this.http.post<any>(endpointURL, requestBody, httpOptions);
  }

  /**
  * PATCH: unsubscribe a user from its PANA business subscription
  * @param businessId: business' id
  */
  editBusinessUser(businessId: number, userId: number, requestBody: any): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/' + userId);
    return this.http.patch<any>(endpointURL, requestBody, httpOptions);
  }

  /**
  * PATCH: unsubscribe a user from its PANA business subscription
  * @param businessId: business' id
  */
  editProfile(requestBody: any): Observable<any> {
    const endpointURL = this.getEndpointURL('api/v2/profile');
    return this.http.put<any>(endpointURL, requestBody, httpOptions);
  }

  /**
   * PATCH: unsubscribe a user from its PANA business subscription
   * @param businessId: business' id
   * @param userId: user's id
   */
  unsubscribeBusinessUser(businessId: number, userId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/unsubscribe/' + userId);
    return this.http.patch<any>(endpointURL, httpOptions);
  }

  /**
   * GET: gets all companies subscribed to PANA
   */
  getCompanies(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/companies');
    return this.http.get<any>(endpointURL, httpOptions);
  }

  /**
   * GET: gets stream of csv file with corporative users
   * @param businessId - number (business id)
   */
  downloadCorpUSersFile(businessId: number): Observable<Blob> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/download');
    return this.http.get(endpointURL, { responseType: 'blob' });
  }

  /**
   * GET: gets stream of xlsx file with mass load format sample
   */
  downloadMassLoadSample(): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/files/samples/bulk-load');
    const req = new HttpRequest('GET', endpointURL, {
      reportProgress: true,
      responseType: 'blob'
    });
    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event)),
      tap(message => {
            this.showProgress(message);
          }),
      last(), // return last (completed) message to caller
      catchError(this.handleError())
    );
  }

  /**
   * GET: Gets stream of pdf file with the details of the given invoice of the given
   * business
   *
   * @param businessId Business' id
   * @param invoiceId Invoice's id
   * @param handlers Object with callbacks to handle http download progress events
   *                 and reports
   */
  downloadBusinessInvoiceDetailFile(
    businessId: number,
    invoiceId: number,
    handlers: { downloadEvent: Function, reportMessage: Function }
  ): Observable<any> {
    const endpointURL = this.getEndpointURL(`api/business/${businessId}/invoices/${invoiceId}/pdf`);
    const req = new HttpRequest('GET', endpointURL, {
      reportProgress: true,
      responseType: 'blob'
    });
    return this.http.request(req).pipe(
      map(event => handlers.downloadEvent(event)),
      tap(report => {
        handlers.reportMessage(report, this.loaderService);
      }),
      last(),
      catchError(response => {
        return throwError(response);
      })
    );
  }
  /**
   * GET: Gets stream of pdf file with the details of the given invoice of the given
   * business
   *
   * @param businessId Business' id
   * @param invoiceId Invoice's id
   * @param handlers Object with callbacks to handle http download progress events
   *                 and reports
   */
  downloadCurrentBusinessInvoiceSummaryFile(
    businessId: number,
    handlers: { downloadEvent: Function, reportMessage: Function }
  ): Observable<any> {
    const endpointURL = this.getEndpointURL(`api/business/${businessId}/invoices/current-summary/pdf`);
    const req = new HttpRequest('GET', endpointURL, {
      reportProgress: true,
      responseType: 'blob'
    });
    return this.http.request(req).pipe(
      map(event => handlers.downloadEvent(event)),
      tap(report => {
        handlers.reportMessage(report, this.loaderService);
      }),
      last(),
      catchError(response => {
        return throwError(response);
      })
    );
  }

  // TODO: Move auxiliar functions to the bottom of the file.

  // TODO: Verify if the function is necessary. If it is, document the function
  // and set the function as private
  handleError(): any {
    return;
  }

  // TODO: Verify if the function can be reached outside the class. If it cannot
  // be reached, set the function as private
  showProgress(message) {
    if (isArray(message)) {
      this.loaderService.showLoaderProgressbar(true, message[0], message[1]);
    }
  }

  // TODO: Change function's name to be more mnemonic
  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return [`Descargando archivo...`, 0];

      case HttpEventType.DownloadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        return [`Progreso de la descarga: ${percentDone}%`, percentDone];

      case HttpEventType.Response:
        this.loaderService.showLoaderProgressbar(false, 'Done!', 100);
        return event.body;

      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  /**
   * POST: performs massive subscription for a business team
   * @param businessId: business' id
   * @param validUsers: array of valid users
   */
  massSubscription(businessId: number, validUsers: any[]): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/mass/create');
    return this.http.post<any>(endpointURL, validUsers, httpOptions);
  }

  /**
   * POST: generates xlsx file with invalid users after mass subscription
   * @param invalidUsers: array of invalid users
   */
  downloadInvalidXLSX(invalidUsers: any[]): Observable<any> {
    // TODO: Changes endpoint uri to eliminate the word csv
    const endpointURL = this.getEndpointURL('api/business/users/build-csv');
    return this.http.post(endpointURL, invalidUsers, { responseType: 'blob' });
  }

  /**
   * GET: Gets the business invoices history of the given business
   *
   * @param businessId Business' id
   */
  getInvoicesHistory(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/invoices');
    return this.http.get<any>(endpointURL, httpOptions)
    .pipe(
      map(response => {
        // TODO: Create interface for invoices
        return response.data;
      }),
      catchError(response => {
        return throwError(response);
      })
    );
  }

  /**
   * GET: Gets the billing resume of the current cycle of the given business
   *
   * @param businessId Business' id
   */
  getCurrentInvoiceSummary(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/invoices/current-summary');
    return this.http.get<any>(endpointURL, httpOptions)
    .pipe(
      map(response => {
        return response.data;
      }),
      catchError(response => {
        return throwError(response);
      })
    );
  }

  getInvoices(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
    return this.http.get<any>(endpointURL, httpOptions)
    .pipe(
      map(response => {
        return response.data;
      }),
      catchError(response => {
        return throwError(response);
      })
    );
  }

  getBusinessPaymentMethods(businessId: number): Observable<any> {
    // const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
    return new Observable((observer) => {
      observer.next([]);
    });
    // return this.http.get<any>(endpointURL, httpOptions)
    // .pipe(
    //   map(response => {
    //     return response.data;
    //   }),
    //   catchError(response => {
    //     return throwError(response);
    //   })
    // );
  }

  updateBusinessPaymentMethods(businessId: number, paymentMethod, requestBody): Observable<any> {
    // const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
    return new Observable((observer) => {
      paymentMethod.billing_details.name = requestBody.name;
      observer.next([]);
    });
    // return this.http.get<any>(endpointURL, httpOptions)
    // .pipe(
    //   map(response => {
    //     return response.data;
    //   }),
    //   catchError(response => {
    //     return throwError(response);
    //   })
    // );
  }

  getInvoicesAdmin(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoicesForAdmin');
    return this.http.get<Array<Transaction>>(endpointURL, httpOptions);
  }

  getRenovations(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getRenovation');
    return this.http.get<Array<any>>(endpointURL, httpOptions);
  }

  getSubscribe(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getSubscribe');
    return this.http.get<Array<any>>(endpointURL, httpOptions);
  }

  getUnsuscribe(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getUnsubscribe');
    return this.http.get<Array<any>>(endpointURL, httpOptions);
  }

  getTotalCurrentMonth(businessId: number): Observable<any> {
    const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getTotalCurrentMonth');
    return this.http.get<Array<any>>(endpointURL, httpOptions);
  }

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }


}
