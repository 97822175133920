import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NavigationService } from './services/navigation.service';
import { LoaderService } from './services/loader.service';
import { LoaderConstant } from './constants/loader.constant';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations: [ ]
})
export class AppComponent implements OnInit {
  sidebarBlackList = ['/login', '/register', '/set-password', '/loading'];
  toggleSidebar: boolean;
  isExpanded = false;
  navigationDisabled = false;
  showLoader: boolean;
  showLoaderProgressbar: boolean;
  messageLoaderProgress;
  percentLoaderProgress;
  loaderConfig: any;

  @HostListener('window:resize')
  onResize() {
    this.toggleSidebar = window.innerWidth < 768;
  }

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private loaderService: LoaderService,
  ) {
    this.onResize();
    this.navigationService.currentDisablingStatus$.subscribe(
      status => this.navigationDisabled = status
    );
  }

  ngOnInit() {
    this.loaderService.showLoaderStatus$.subscribe(
      status => {
        window.scrollTo(0, 0);
        this.showLoader = status;
        if (this.showLoader) {
          document.body.classList.add('disable-scroll');
          /** request started */
        } else {
          document.body.classList.remove('disable-scroll');
        }
      }
    );
    this.loaderService.showLoaderStatusProgressbar$.subscribe(
      status => {
        window.scrollTo(0, 0);
        this.showLoaderProgressbar = status;
        if (this.showLoaderProgressbar) {
          document.body.classList.add('disable-scroll');
          this.messageLoaderProgress = this.loaderService.loaderMessage;
          this.percentLoaderProgress = this.loaderService.percentLoader;
        } else {
          document.body.classList.remove('disable-scroll');
        }
      }
    );
    this.loaderConfig = LoaderConstant.CONFIG;
  }

  hideSidebar(): boolean {
    const currentURL = this.router.url;
    const blackListRegex = new RegExp(this.sidebarBlackList.join('|'));
    return blackListRegex.test(currentURL);
  }
}
