<div class="d-flex flex-column h-100 vertical-scroll position-absolute w-100">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3>Gestionar Empleados / Carga Masiva</h3>
  </div>

  <ng-template [ngIf]="!validated" [ngIfElse]="validationStep">
    <!-- Server error messages section -->
    <div class="d-flex flex-column justify-content-center align-items-center my-5" *ngIf="errorMessage">
      <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-block" alt="">
      <p class="text-green mt-1 mb-3 text-center">
        {{ errorMessage }}
      </p>
    </div>

    <!-- Upload xlsx file section -->
    <div class="px-3 px-md-5 pb-4">
      <div class="card pana-card">
        <div class="card-body py-5">
          <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" class="w-100">
            <h3 class="text-center font-light mb-4">Importar archivo de empleados</h3>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <h6 class="text-gray w-100 text-center mb-3">1. Descarga el archivo excel y rellénalo</h6>
              <div class="btn btn-pana-gradient-dark w-100 m-0" (click)="getSample()">
                <div>
                  DESCARGA EL EXCEL
                </div>
              </div>
              <h6 class="text-gray w-100 text-center mt-5 mb-3">2. A continuación monta el .xlsx generado</h6>
              <label class="btn-upload text-center" *ngIf="!uploadedFilename">
                <input id="usersXLSX" type="file" (change)="onFileChange($event)">
                <div class="btn btn-pana-gradient-dark w-100 m-0">
                  <div>
                    SUBIR XLSX
                  </div>
                </div>
              </label>
              <h6 class="font-light text-green" *ngIf="uploadedFilename">
                {{uploadedFilename}}
                <fa name="times" size="md" (click)="clearForm()" class="clickable"></fa>
              </h6>
              <div class="text-center mt-5 w-100">
                <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="userManagement()">
                  <div>
                    CANCELAR
                  </div>
                </div>
                <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!uploadedFilename">
                  IMPORTAR
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #validationStep>
    <div class="px-3 px-md-5 pb-4">
      <h4 class="font-light mb-4" *ngIf="validUsers.length === 1">Importados correctamente: {{ validUsers.length }} usuario</h4>
      <h4 class="font-light mb-4" *ngIf="validUsers.length > 1 || validUsers.length === 0">Importados correctamente: {{ validUsers.length }} usuarios</h4>
      <div class="w-100 mt-3 horizontal-scroll">
        <table class="table table-bordered table-hover text-center">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Cédula</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Área</th>
              <th>Perfil</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            <tr *ngIf="validUsers.length === 0">
              <th colspan="8">No existen usuarios válidos</th>
            </tr>
            <ng-container *ngFor="let valid of validUsers">
              <tr>
                <td [innerHtml]="valid.first_name"></td>
                <td [innerHtml]="valid.last_name"></td>
                <td [innerHtml]="valid.type_document + '-' + valid.document"></td>
                <td [innerHtml]="valid.email"></td>
                <td [innerHtml]="valid.phone"></td>
                <td [innerHtml]="valid.department || 'N/A'"></td>
                <td [innerHtml]="valid.role"></td>
                <td [innerHtml]="getComments(valid)"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <h4 class="font-light my-4" *ngIf="invalidUsers.length === 0 || invalidUsers.length > 1">No importados: {{ invalidUsers.length }} usuarios</h4>
      <h4 class="font-light my-4" *ngIf="invalidUsers.length === 1 ">No importados: {{ invalidUsers.length }} usuario</h4>
      <div class="w-100 mt-3 horizontal-scroll">
        <table class="table table-bordered table-hover text-center">
          <thead class="thead-dark">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Cédula</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Área</th>
              <th>Perfil</th>
              <th>Comentarios</th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            <tr *ngIf="invalidUsers.length === 0">
              <th colspan="8">No existen usuarios inválidos</th>
            </tr>
            <ng-container *ngFor="let invalid of invalidUsers">
              <tr>
                <td [innerHtml]="invalid.first_name"></td>
                <td [innerHtml]="invalid.last_name"></td>
                <td [innerHtml]="invalid.type_document + '-' + invalid.document"></td>
                <td [innerHtml]="invalid.email"></td>
                <td [innerHtml]="invalid.phone"></td>
                <td [innerHtml]="invalid.department || 'N/A'"></td>
                <td [innerHtml]="invalid.role"></td>
                <td [innerHtml]="getComments(invalid)"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="mt-5 w-100 text-center">
        <app-generic-confirmation-modal [modalTitle]="'¿Seguro que deseas cancelar todo el proceso?'" [modalText]="getCancelModalText()"
          [buttonText]="'Cancelar todo el proceso'" [confirmText]="'Entiendo'" [cancelText]="'CANCELAR'"
          [onConfirm]="userManagement.bind(this)" class="w-100 mx-3 d-inline-block" [btnDark]="true">
        </app-generic-confirmation-modal>
        <app-mass-load-modal *ngIf="validUsers.length > 0" [businessTeam]="businessTeam" [validUsers]="validUsers" [invalidUsers]="invalidUsers" class="w-100 mx-3 d-inline-block">
        </app-mass-load-modal>
      </div>
    </div>
  </ng-template>
</div>
