import { Component, OnInit } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-corporative-contact-modal',
  templateUrl: './corporative-contact-modal.component.html',
  styleUrls: ['./corporative-contact-modal.component.css']
})
export class CorporativeContactModalComponent implements OnInit {
  corpContactModal: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openCorpContactModal(modal): void {
    this.corpContactModal = this.modalService.open(
      modal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal() {
    this.corpContactModal.close();
  }
}
