import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { NavigationService } from '../../services/navigation.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { User } from 'src/app/utils/user.utils';
import { UserProfile } from 'src/app/interfaces/user-profile.interface';
import { UserRol } from 'src/app/interfaces/user-rol.interface';

@Component({
  selector: 'app-employees-dropdown',
  templateUrl: './employees-dropdown.component.html',
  styleUrls: ['./employees-dropdown.component.css']
})
export class EmployeesDropdownComponent implements OnInit {
  @Input() teamId: number;
  @Input() pending = false;
  @Input() selectedEmployee: any;
  @Output() selectedEmployeeChange: EventEmitter<any> = new EventEmitter<any>();

  employeesList: Array<any>;
  criteria = '';
  showDropDown: boolean;
  expandedInfo: boolean;
  selectedIndex: number;
  serverError: boolean;

  constructor(
    public globalData: GlobalDataService,
    public userUtils: User,
    private api: ApiService,
    private user: UserService,
    private navigationService: NavigationService
  ) {
    this.expandedInfo = false;
  }

  ngOnInit() {
    if (this.teamId) {
      this.requestEmployees();
    } else {
      this.requestAllDependents();
    }
  }

  showEmployee(employee: any) {
    const filterValue = this.criteria.toLowerCase();
    const fullName = employee.first_name + ' ' + employee.last_name;
    const includesFullName = fullName.toLowerCase().includes(filterValue);
    const includesDocument = employee.document && employee.document.includes(filterValue);
    const includesPhone = employee.phone && employee.phone.includes(filterValue);
    const includesEmail = employee.email && employee.email.includes(filterValue);
    return includesDocument || includesFullName || includesPhone || includesEmail;
  }

  noMatchResults(): boolean {
    return this.employeesList && !this.employeesList.some((employee) => {
      return this.showEmployee(employee);
    });
  }

  showDropdown(): void {
    this.showDropDown = true;
  }

  toogleDropDown(): void {
    this.showDropDown = !this.showDropDown;
  }

  updateSelected(employee: any): void {
    this.showDropDown = false;
    this.selectedEmployee = employee;
    this.criteria = this.getFullName(employee);
    this.selectedEmployeeChange.emit(this.selectedEmployee);
  }

  getFullName(employee: UserProfile): string {
    return this.userUtils.getFullName(employee);
  }

  getDependentRole(employee: UserProfile): UserRol {
    return this.userUtils.getUserDependentRole(employee, this.user.getProfile());
  }

  checkHighlight(employee: any): boolean {
    if (this.selectedEmployee) {
      return this.selectedEmployee.id === employee.id;
    } else {
      return false;
    }
  }

  clean(): void {
    this.criteria = '';
    this.selectedEmployee = undefined;
    this.selectedEmployeeChange.emit(this.selectedEmployee);
  }

  changeExpansion(): void {
    this.expandedInfo = !this.expandedInfo;
  }

  itsMe(): boolean {
    return this.selectedEmployee.id === this.user.getId();
  }

  private requestEmployees() {
    this.api.getTeamMembers(this.teamId).subscribe(
      data => {
        this.employeesList = data.data;
      },
      error => {
        console.log(error);
        this.serverError = true;
      }
    );
  }

  private requestAllDependents() {
    this.api.getDependentMembers().subscribe(
      data => {
        this.employeesList = data.data;
        const currentUserProfile = this.user.getProfile();
        this.employeesList.unshift(currentUserProfile);
        if (!this.selectedEmployee) {
          if (this.navigationService.params) {
            this.selectedEmployee = this.navigationService.params.user;
            this.navigationService.params = undefined;
          } else {
            this.selectedEmployee = currentUserProfile;
          }
          this.selectedEmployeeChange.emit(this.selectedEmployee);
        }
      },
      error => {
        console.log(error);
        this.serverError = true;
      }
    );
  }

}
