import { Component, OnInit, OnDestroy } from '@angular/core';

import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-my-business',
  templateUrl: './my-business.component.html',
  styleUrls: ['./my-business.component.css']
})
export class MyBusinessComponent implements OnInit, OnDestroy {
  businessTeam: any;
  departmentsLoaded: boolean;
  departments: any[];
  isAdmin: boolean;
  isSuperAdmin: boolean;
  filterCriteria = '';
  navigationSubscription: Subscription;

  constructor(
    private user: UserService,
    private api: ApiService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      /**
       * If it is a NavigationEnd event, re-initalize the component.
       * This is useful when the current section is clicked on at the sidebar.
       */
      if (e instanceof NavigationEnd) {
        this.initialize();
      }
    });
    this.initialize();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Initalizes class variables values.
   */
  initialize() {
    this.businessTeam = undefined;
    this.isAdmin = this.user.isAdmin();
    this.isSuperAdmin = this.user.isSuperAdmin();
    if (!this.isSuperAdmin) {
      setTimeout(() => {
        this.setBusinessTeam();
      });
    }
  }

  /**
   * Refreshes company's departments list.
   * @param refresh - boolean: whether refresh or not.
   */
  onRefresh(refresh: boolean) {
    if (refresh) {
      this.getDepartments();
    }
  }

  /**
   * Sets businessTeam class variable value with the selected company.
   * @param selectedCompany - Object: company data.
   */
  onCompanyUpdated(selectedCompany: any) {
    this.businessTeam = selectedCompany;
    this.getDepartments();
  }

  /**
   * Determines whether a specific department should be shown at the
   * departments list or not.
   * @param department - Object: department data.
   */
  showDepartment(department: any) {
    return department.name.toLowerCase().includes(this.filterCriteria.toLowerCase());
  }

  noMatchResults() {
    return !this.departments.some((department) => {
      return this.showDepartment(department);
    });
  }

  /**
   * Sets businessTeam class variable value from current user's list of
   * subscriptions.
   */
  private setBusinessTeam(): void {
    const businessRole = this.user.getBusinessSubscription();
    this.businessTeam = businessRole.team;
    this.getDepartments();
  }

  /**
   * Performs company's departments request to PANA's API.
   */
  private getDepartments(): void {
    this.loaderService.showLoader(true);

    this.api.getBusinessDepartments(this.businessTeam.id).subscribe(
      data => {
        this.departments = data.data;
        this.departmentsLoaded = true;
        this.loaderService.showLoader(false);
      },
      error => {
        this.loaderService.showLoader(false);
      }
    );
  }

}
