<ng-template #genericInfoModal>
  <div *ngIf="minimizable" class="text-right">
    <button type="button" class="minimizable-toggle" aria-label="Close" (click)="closeModal()">
      Minimizar
    </button>
  </div>
  <div class="modal-body">
    <div class="content">
      <div class="text-center">
        <h2 *ngIf="!minimizable">{{ modalTitle }}</h2>
        <p *ngIf="minimizable">{{ modalTitle }}</p>
      </div>
      <div class="text-center" [innerHtml]="modalText"></div>
    </div>
  </div>
</ng-template>

<button *ngIf="!minimizable" class="btn btn-dark" (click)="openModal(genericInfoModal)">
  {{ buttonText | uppercase }}
</button>

<div *ngIf="minimizable && isMinimized" class="py-sm-2 minimized-info pana-card">
  <div class="text-right">
    <button type="button" class="minimizable-toggle" aria-label="Close" (click)="openModal(genericInfoModal)">
      Maximizar
    </button>
  </div>
  <div>
    <h4>{{ modalTitle }}</h4>
    <div class="minimized-text m-0 p-2" [innerHtml]="infoCardText"></div>
  </div>
</div>