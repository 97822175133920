import * as i0 from "@angular/core";
var LoadingScreenService = /** @class */ (function () {
    function LoadingScreenService() {
        this.visible = false;
    }
    LoadingScreenService.prototype.enable = function () {
        this.visible = true;
    };
    LoadingScreenService.prototype.disable = function () {
        this.visible = false;
    };
    LoadingScreenService.prototype.isVisible = function () {
        return this.visible;
    };
    LoadingScreenService.ngInjectableDef = i0.defineInjectable({ factory: function LoadingScreenService_Factory() { return new LoadingScreenService(); }, token: LoadingScreenService, providedIn: "root" });
    return LoadingScreenService;
}());
export { LoadingScreenService };
