import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // User is logged in, so proceed with the request
      return true;
    }
    // User is not logged in, so redirect to login page with the intended URL
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
