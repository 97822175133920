import { Injectable } from '@angular/core';

const mobileLimit = 768;
const smBreak = 578;
const mdBreak = 768;
const lgBreak = 992;
const xlBreak = 1200;

const btnSmBreak = 544;

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  constructor() { }

  /**
   * Returns screen's outer width trough window provider
   * @returns - number: Screen's outer width in pixels
   */
  getWidth(): number {
    return window.outerWidth;
  }

  getInnerWidth(): number {
    return window.innerWidth;
  }

  /**
   * Checks if content should be rendered as a mobile site
   * based purely on screen's width
   * @returns - boolean: whether it's mobile sized or not.
   */
  isMobile(): boolean {
    return this.getWidth() < mobileLimit;
  }

  isMobileInner(): boolean {
    return this.getInnerWidth() < mobileLimit;
  }

  ltSm(): boolean {
    return this.getInnerWidth() < smBreak;
  }

  ltMd(): boolean {
    return this.getInnerWidth() < mdBreak;
  }

  ltLg(): boolean {
    return this.getInnerWidth() < lgBreak;
  }

  ltXl(): boolean {
    return this.getInnerWidth() < xlBreak;
  }

  ltBtnBreak(): boolean {
    return this.getInnerWidth() < btnSmBreak;
  }
}
