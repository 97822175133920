/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-info-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./generic-info-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var styles_GenericInfoModalComponent = [i0.styles];
var RenderType_GenericInfoModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericInfoModalComponent, data: {} });
export { RenderType_GenericInfoModalComponent as RenderType_GenericInfoModalComponent };
function View_GenericInfoModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "minimizable-toggle"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Minimizar "]))], null, null); }
function View_GenericInfoModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_GenericInfoModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 1, 0, currVal_0); }); }
function View_GenericInfoModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericInfoModalComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericInfoModalComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericInfoModalComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "text-center"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minimizable; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.minimizable; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.minimizable; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.modalText; _ck(_v, 9, 0, currVal_3); }); }
function View_GenericInfoModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-dark"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v.parent, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.buttonText)); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericInfoModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "py-sm-2 minimized-info pana-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "minimizable-toggle"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v.parent, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Maximizar "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "minimized-text m-0 p-2"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.infoCardText; _ck(_v, 7, 0, currVal_1); }); }
export function View_GenericInfoModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵand(0, [["genericInfoModal", 2]], null, 0, null, View_GenericInfoModalComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericInfoModalComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericInfoModalComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.minimizable; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.minimizable && _co.isMinimized); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GenericInfoModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-info-modal", [], null, null, null, View_GenericInfoModalComponent_0, RenderType_GenericInfoModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericInfoModalComponent, [i4.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericInfoModalComponentNgFactory = i1.ɵccf("app-generic-info-modal", i3.GenericInfoModalComponent, View_GenericInfoModalComponent_Host_0, { modalTitle: "modalTitle", modalText: "modalText", infoCardText: "infoCardText", buttonText: "buttonText", minimizable: "minimizable" }, {}, []);
export { GenericInfoModalComponentNgFactory as GenericInfoModalComponentNgFactory };
