import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {

  private visible = false;

  constructor() { }

  enable() {
    this.visible = true;
  }

  disable() {
    this.visible = false;
  }

  isVisible() {
    return this.visible;
  }
}
