import { SubscriptionsConstant } from '../constants/subscriptions.constant';
import { UserStatus } from '../constants/user-status.constant';
import * as i0 from "@angular/core";
var User = /** @class */ (function () {
    function User() {
    }
    User.prototype.getActiveSubscription = function (user) {
        return user.subscriptions.find(function (subscription) {
            return subscription.status === SubscriptionsConstant.SUBS_ACTIVE;
        });
    };
    User.prototype.getRole = function (user, teamId) {
        return user.roles.find(function (role) {
            return role.team && role.team.id === teamId;
        });
    };
    User.prototype.hasDepartment = function (user, teamId) {
        return this.getRole(user, teamId).department !== undefined;
    };
    User.prototype.getFullName = function (user) {
        return user.first_name + ' ' + user.last_name;
    };
    User.prototype.getUserDependentRole = function (user, supervisor) {
        var _this = this;
        return user.roles.find(function (role) {
            return role.team && _this.belongsToTeam(supervisor, role.team.id);
        });
    };
    User.prototype.belongsToTeam = function (user, teamId) {
        return user.roles.some(function (role) {
            return role.team && role.team.id === teamId;
        });
    };
    User.prototype.isPreregistered = function (user) {
        return user.status === UserStatus.PREREGISTERED;
    };
    User.ngInjectableDef = i0.defineInjectable({ factory: function User_Factory() { return new User(); }, token: User, providedIn: "root" });
    return User;
}());
export { User };
