import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeVE from '@angular/common/locales/es-VE';
import { registerLocaleData } from '@angular/common';
// Http request handling library
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// HTML Forms library
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Angular Bootstrap main module
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Angular Google Maps module
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
// Routing handling library
import { AppRoutingModule } from './app-routing.module';
// International phone codes picker
import { InternationalPhoneModule } from 'ng4-intl-phone';
// Font awesome
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';

// Loading Spinner
import { NgxLoadingModule } from 'ngx-loading';
// Paginator
import { MatPaginatorModule } from '@angular/material/paginator';
import {NgxPaginationModule} from 'ngx-pagination';
// Phone-number
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InternationalPhoneNumberModule} from 'ngx-international-phone-number';

import { NgxPnInputModule } from 'ngx-pn-input';


import { AppComponent } from './app.component';
// Helpers
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AuthInterceptor } from './_helpers/auth.interceptor';

// Sections
import { LoginComponent } from './sections/login/login.component';
import { UserRegistrationComponent } from './sections/user-registration/user-registration.component';
import { DashboardComponent } from './sections/dashboard/dashboard.component';
import { SetPasswordComponent } from './sections/set-password/set-password.component';
import { MyAccountComponent } from './sections/my-account/my-account.component';
import { UserManagementComponent } from './sections/user-management/user-management.component';
import { MyBusinessComponent } from './sections/my-business/my-business.component';
import { MassLoadComponent } from './sections/mass-load/mass-load.component';
import { FamilyGroupComponent } from './sections/family-group/family-group.component';
// Components
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { RegisterModalComponent } from './components/register-modal/register-modal.component';
import { BusinessContactComponent } from './components/business-contact/business-contact.component';
import { GenericConfirmationModalComponent } from './components/generic-confirmation-modal/generic-confirmation-modal.component';
import { GenericInfoModalComponent } from './components/generic-info-modal/generic-info-modal.component';
import { ServiceCancellationModalComponent } from './components/service-cancellation-modal/service-cancellation-modal.component';
import { EmployeesDropdownComponent } from './components/employees-dropdown/employees-dropdown.component';
import { ServiceMapComponent } from './components/service-map/service-map.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateDepartmentComponent } from './components/create-department/create-department.component';
import { ModifyDepartmentComponent } from './components/modify-department/modify-department.component';
import { RemoveDepartmentComponent } from './components/remove-department/remove-department.component';
import { BusinessDropdownComponent } from './components/business-dropdown/business-dropdown.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { UnsubscribeBusinessUserComponent } from './components/unsubscribe-business-user/unsubscribe-business-user.component';
import { MassLoadModalComponent } from './components/mass-load-modal/mass-load-modal.component';
import { UserFilterPipe } from './pipes/user-filter.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ReplacePipe } from './pipes/replace.pipe';
import { CorporativeContactModalComponent } from './components/corporative-contact-modal/corporative-contact-modal.component';
import { InvoicesComponent } from './sections/invoices/invoices.component';
import { StringMapPipe } from './pipes/string-map.pipe';
import { LoadingComponent } from './sections/loading/loading.component';
import { AddCreditCardComponent } from './components/add-credit-card/add-credit-card.component';
import { EditCreditCardComponent } from './components/edit-credit-card/edit-credit-card.component';
import { SetFavoriteCardComponent } from './components/set-favorite-card/set-favorite-card.component';
// import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';

registerLocaleData(localeVE, 'es-VE');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    RegisterModalComponent,
    BusinessContactComponent,
    UserRegistrationComponent,
    GenericConfirmationModalComponent,
    GenericInfoModalComponent,
    ServiceCancellationModalComponent,
    EmployeesDropdownComponent,
    ServiceMapComponent,
    SidebarComponent,
    NavbarComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    MyBusinessComponent,
    CreateDepartmentComponent,
    ModifyDepartmentComponent,
    RemoveDepartmentComponent,
    BusinessDropdownComponent,
    SetPasswordComponent,
    UserManagementComponent,
    UserFilterPipe,
    HighlightPipe,
    CreateUserComponent,
    UnsubscribeBusinessUserComponent,
    MassLoadComponent,
    MassLoadModalComponent,
    GooglePlacesDirective,
    AutofocusDirective,
    ReplacePipe,
    CorporativeContactModalComponent,
    FamilyGroupComponent,
    InvoicesComponent,
    StringMapPipe,
    LoadingComponent,
    AddCreditCardComponent,
    EditCreditCardComponent,
    SetFavoriteCardComponent,
    // ConfirmRegistrationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPaginationModule,
    NgbModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8fCHgPUBCPd1yeopzzD-5FOEnHiiDLn8'
    }),
    AgmSnazzyInfoWindowModule,
    InternationalPhoneModule,
    AngularFontAwesomeModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressBarModule,
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule,
    NgxLoadingModule.forRoot({}),
    InternationalPhoneNumberModule,
    NgxPnInputModule,
  ],
  providers: [
    UserFilterPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-VE' }
  ],
  bootstrap: [AppComponent],
  // entryComponents: [
  //   AddCreditCardComponent,
  //   EditCreditCardComponent,
  // ]
})
export class AppModule { }
