import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { StringsConstant } from 'src/app/constants/strings.constant';

@Component({
  selector: 'app-remove-department',
  templateUrl: './remove-department.component.html',
  styleUrls: ['./remove-department.component.css']
})
export class RemoveDepartmentComponent implements OnInit {
  @Input() businessId: number;
  @Input() departmentId: number;
  @Input() departmentName: string;
  @Output() refresh = new EventEmitter<boolean>();

  modalRef: NgbModalRef;
  errorMessage: string;
  failed: boolean;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
  }

  openModal(deleteDepartmentModal) {
    this.modalRef = this.modalService.open(
      deleteDepartmentModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  closeModal() {
    this.clear();
    this.failed = false;
    this.modalRef.close();
  }

  removeDepartment() {
    this.loaderService.showLoader(true);
    this.api.removeDepartment(this.businessId, this.departmentId)
      .subscribe(
        data => {
          this.closeModal();
          this.loaderService.showLoader(false);
          this.refresh.emit(true);
        },
        error => {
          this.failed = true;
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );
  }

  private clear(): void {
    this.errorMessage = undefined;
  }
}
