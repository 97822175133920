import { Component, OnInit, Input } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';
import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-mass-load-modal',
  templateUrl: './mass-load-modal.component.html',
  styleUrls: ['./mass-load-modal.component.css']
})
export class MassLoadModalComponent implements OnInit {
  @Input() businessTeam: any;
  @Input() validUsers: any[];
  @Input() invalidUsers: any[];

  modalRef: NgbModalRef;
  success: boolean;
  errorMessage: string;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private loaderService: LoaderService,
    private navService: NavigationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.success = false;
  }

  openModal(massLoadModal): void {
    this.modalRef = this.modalService.open(
      massLoadModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        keyboard: false,
        backdrop: 'static',
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  massSubscription(): void {
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);
    this.api.massSubscription(this.businessTeam.id, this.validUsers).subscribe(
      response => {
        this.success = true;
        this.loaderService.showLoader(false);
      },
      error => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
 }

  downloadInvalid(): void {
    this.errorMessage = undefined;
    this.loaderService.showLoader(true);
    this.api.downloadInvalidXLSX(this.invalidUsers).subscribe(
      response => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'UsuariosInvalidos.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);

        this.closeModal();
        this.loaderService.showLoader(false);
      },
      error => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }

  closeModal() {
    if (this.success) {
      this.navService.params = { businessTeam: this.businessTeam };
      this.router.navigate(['/user-management']);
    }
    this.modalRef.close();
  }

}
