<div *ngIf="!selectedEmployee">
  <div class="drop-down">
    <input type="text"
      class="form-control input-pana-darker dropdown-input input-pana-busqueda"
      [ngClass]="{'dropdown-input-expanded': showDropDown}"
      [(ngModel)]="criteria"
      (click)="toogleDropDown()"
      (input)="showDropdown()"
      placeholder="Buscar empleado">
  </div>
  <div class="dropdown-container disable-scroll" *ngIf="showDropDown">
    <div class="vertical-scroll">
      <div *ngFor="let employee of employeesList">
        <div
          class="dropdown-element px-3 d-flex align-items-center justify-content-between"
          [ngClass]="{ highlight: checkHighlight(employee) }"
          *ngIf="showEmployee(employee)"
          (click)="updateSelected(employee)"
        >
          <div>
            <span class="my-0">
              {{ getFullName(employee) }}
            </span>
            <br>
            <small *ngIf="getDependentRole(employee)">
              {{ (getDependentRole(employee).department || '') | titlecase }}
            </small>
          </div>
          <p class="my-0" *ngIf="getDependentRole(employee)">
            {{ getDependentRole(employee).team.name | uppercase }}
          </p>
        </div>
      </div>
      <div 
        *ngIf="noMatchResults()"
        class="dropdown-no-match px-3 d-flex align-items-center justify-content-center"
      >
        <span class="text-center default-cursor">
          No hay ningun usuario que coincida con su busqueda
        </span>
      </div>
      <div *ngIf="serverError" class="dropdown-no-match px-3 d-flex align-items-center justify-content-center">
        <span class="text-center default-cursor">
          No se ha podido obtener ningún empleado del servidor
        </span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="selectedEmployee" class="selected-info pana-card" [ngClass]="!itsMe() ? 'pt-0 pt-sm-0 pb-2 pb-sm-3' : 'py-2 py-sm-3'">
  <div *ngIf="!itsMe() || globalData.ltSm()" class="w-100 close-button text-right">
    <button *ngIf="!pending" type="button" aria-label="Close" (click)="clean()" class="">
      &times;
    </button>
  </div>
  <div class="row m-0 text-center px-2 px-sm-3">
    <div class="col-12 p-0 d-flex align-items-center justify-content-center">
      <h5 class="mb-0">{{ getFullName(selectedEmployee) }}</h5>
    </div>
    <div class="col-12 p-0 d-none d-sm-block minimized-text" [ngClass]="{'d-block': expandedInfo}">
      <p class="m-0 p-0">
        {{ selectedEmployee.email}}
      </p>
    </div>
    <div class="col-12 p-0 d-none d-sm-block minimized-text" [ngClass]="{'d-block': expandedInfo}">
      <p class="m-0 p-0">
        {{ selectedEmployee.document}}
      </p>
    </div>
    <div class="col-12 p-0 d-none d-sm-block minimized-text" [ngClass]="{'d-block': expandedInfo}">
      <p class="m-0 p-0">
        {{ selectedEmployee.phone }}
      </p>
    </div>
    <div *ngIf="itsMe()" class="col-12 p-0 d-none d-sm-block text-center mt-2">
      <div class="btn btn-pana-gradient-dark btn-xs w-100" (click)="clean()">
        <div>
          PEDIR PARA OTRA PERSONA
        </div>
      </div>
    </div>
    <div class="col-12 d-block d-sm-none text-center">
      <img 
        src="/assets/dropdown-arrow.png" 
        alt="" 
        [ngClass]="{'rotated': expandedInfo}"
        (click)="changeExpansion()"
        width="15" 
        height="15" 
      >
    </div>
  </div>
</div>