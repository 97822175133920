<ng-template #createDepartmentModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <ng-template [ngIf]="!success" [ngIfElse]="successfullyCreated">
      <div class="content">
        <div class="text-center mb-3">
          <p>Indica el nombre del área</p>
        </div>
        <div class="w-100 text-center">
          <form>
            <mat-form-field class="centered w-100">
              <input 
                matInput
                appAutofocus
                type="text" 
                placeholder="Nombre del Área" 
                [(ngModel)]="departmentName" 
                [ngModelOptions]="{standalone: true}" 
                id="departmentNameInput">
            </mat-form-field>
          </form>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="errorMessage">
          <img src="/assets/Road-Barrier.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
          <p class="text-green mt-1 text-center">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
          <div>
            CANCELAR
          </div>
        </div>
        <button type="submit" class="btn btn-pana-gradient w-100 mx-3" [disabled]="!departmentName" (click)="createDepartment()">
          ACEPTAR
        </button>
      </div>
    </ng-template>
    <ng-template #successfullyCreated>
      <div class="content">
        <div class="text-center mb-3">
          <p>¡Excelente!<br>Acabas de agregar el área</p>
        </div>
        <div class="text-center">
          <h2>{{ departmentName | uppercase }}</h2>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-pana-gradient w-100 mx-3" (click)="closeModal(true)">
          CERRAR
        </button>
      </div>
    </ng-template>

  </div>
</ng-template>

<div class="btn btn-pana-gradient-dark m-0 w-100" (click)="openModal(createDepartmentModal)">
  <div>
    {{ 'agregar área' | uppercase }}
  </div>
</div>