import { Component, OnInit, Input } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.css']
})
export class GenericConfirmationModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() modalText: string;
  @Input() buttonText: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() tooltipPosition: string;
  @Input() btnStyles: Object = {};
  @Input() btnDark = false;
  @Input() confirmText = 'Confirmar';
  @Input() onConfirm: Function;
  @Input() cancelText = 'Cancelar';
  @Input() isDisabled = false;

  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openModal(genericConfirmationModal): void {
    this.modalRef = this.modalService.open(
      genericConfirmationModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'offset-0 offset-md-3 offset-lg-2'
      }
    );
  }

  confirmModal() {
    this.onConfirm();
    this.modalRef.close();
  }

  closeModal() {
    this.modalRef.close();
  }

}
