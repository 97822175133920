/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-confirmation-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "./generic-confirmation-modal.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var styles_GenericConfirmationModalComponent = [i0.styles];
var RenderType_GenericConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericConfirmationModalComponent, data: {} });
export { RenderType_GenericConfirmationModalComponent as RenderType_GenericConfirmationModalComponent };
function View_GenericConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "btn btn-pana-gradient-dark w-100 mx-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-pana-gradient w-100 mx-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.modalText; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent, 0), _co.cancelText)); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 0), _co.confirmText)); _ck(_v, 14, 0, currVal_3); }); }
function View_GenericConfirmationModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-pana-gradient w-100"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v.parent, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, [" ", "\n"])), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-pana-gradient w-100"; var currVal_2 = _co.btnStyles; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.buttonText)); _ck(_v, 2, 0, currVal_3); }); }
function View_GenericConfirmationModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btn btn-pana-gradient-dark w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openModal(i1.ɵnov(_v.parent, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-pana-gradient-dark w-100"; var currVal_1 = _co.btnStyles; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.buttonText)); _ck(_v, 3, 0, currVal_2); }); }
function View_GenericConfirmationModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "img", [["class", "clickable text-gray"], ["height", "25"], ["src", "/assets/Download.svg"], ["width", "25"]], [[8, "name", 0]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openModal(i1.ɵnov(_v.parent, 1)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS]], { position: [0, "position"], message: [1, "message"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tooltipPosition; var currVal_2 = _co.tooltip; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 0, 0, currVal_0); }); }
export function View_GenericConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵand(0, [["genericCofirmationModal", 2]], null, 0, null, View_GenericConfirmationModalComponent_1)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericConfirmationModalComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericConfirmationModalComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericConfirmationModalComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.icon && !_co.btnDark); _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.icon && _co.btnDark); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_GenericConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-confirmation-modal", [], null, null, null, View_GenericConfirmationModalComponent_0, RenderType_GenericConfirmationModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.GenericConfirmationModalComponent, [i10.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericConfirmationModalComponentNgFactory = i1.ɵccf("app-generic-confirmation-modal", i9.GenericConfirmationModalComponent, View_GenericConfirmationModalComponent_Host_0, { modalTitle: "modalTitle", modalText: "modalText", buttonText: "buttonText", icon: "icon", tooltip: "tooltip", tooltipPosition: "tooltipPosition", btnStyles: "btnStyles", btnDark: "btnDark", confirmText: "confirmText", onConfirm: "onConfirm", cancelText: "cancelText", isDisabled: "isDisabled" }, {}, []);
export { GenericConfirmationModalComponentNgFactory as GenericConfirmationModalComponentNgFactory };
