import { OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
var EditCreditCardComponent = /** @class */ (function () {
    function EditCreditCardComponent(modalService, formBuilder) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.cardExpiryInputFocused = false;
        this.cardCvcInputFocused = false;
        this.cardExpiryInputValid = false;
        this.cardCvcInputValid = false;
        this.showCardInput = false;
    }
    EditCreditCardComponent.prototype.ngOnInit = function () {
        this.errorMessage = undefined;
        this.stripeExpiryErrorMessage = undefined;
        this.stripeCvcErrorMessage = undefined;
    };
    EditCreditCardComponent.prototype.ngAfterViewInit = function () {
        var style = {
            base: {
                iconColor: '#FFF',
                color: '#FFF',
                '::placeholder': {
                    color: '#999',
                },
            },
        };
        this.stripe = Stripe(environment.tokenStripe, { locale: 'es' });
        var elements = this.stripe.elements();
        this.cardExpiryInput = elements.create('cardExpiry', {
            style: style,
        });
        this.cardCvcInput = elements.create('cardCvc', {
            style: style,
        });
    };
    EditCreditCardComponent.prototype.openModal = function (editCreditCardModal) {
        var _this = this;
        this.errorMessage = undefined;
        this.stripeExpiryErrorMessage = undefined;
        this.stripeCvcErrorMessage = undefined;
        this.editCreditCardForm = this.formBuilder.group({
            name: new FormControl(this.paymentMethod.billing_details.name, [
                Validators.required,
                Validators.pattern('^[a-zA-Zñáééióú.\s]*$'),
                Validators.minLength(5)
            ]),
        });
        this.modalRef = this.modalService.open(editCreditCardModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            windowClass: 'offset-0 offset-md-3 offset-lg-2',
            beforeDismiss: this.closeModal.bind(this)
        });
        this.cardExpiryInput.mount('#card-expiry-element');
        this.cardCvcInput.mount('#card-cvc-element');
        this.cardExpiryInput.addEventListener('change', function (event) {
            if (event.error) {
                _this.stripeExpiryErrorMessage = event.error.message;
            }
            else {
                _this.stripeExpiryErrorMessage = '';
                _this.errorMessage = '';
            }
            if (event.complete) {
                _this.cardExpiryInputValid = true;
            }
            else {
                _this.cardExpiryInputValid = false;
            }
        });
        this.cardCvcInput.addEventListener('change', function (event) {
            if (event.error) {
                _this.stripeCvcErrorMessage = event.error.message;
            }
            else {
                _this.stripeCvcErrorMessage = '';
                _this.errorMessage = '';
            }
            if (event.complete) {
                _this.cardExpiryInputValid = true;
            }
            else {
                _this.cardExpiryInputValid = false;
            }
        });
        this.cardExpiryInput.addEventListener('focus', function () {
            _this.cardExpiryInputFocused = true;
        });
        this.cardExpiryInput.addEventListener('blur', function () {
            _this.cardExpiryInputFocused = false;
        });
        this.cardCvcInput.addEventListener('focus', function () {
            _this.cardCvcInputFocused = true;
        });
        this.cardCvcInput.addEventListener('blur', function () {
            _this.cardCvcInputFocused = false;
        });
        this.cardExpiryInput.on('ready', function () {
            _this.showCardInput = true;
            _this.cardExpiryInput.focus();
        });
    };
    EditCreditCardComponent.prototype.closeModal = function () {
        this.clearForm();
        this.errorMessage = undefined;
        this.modalRef.close();
        this.hideCardInputs = false;
    };
    EditCreditCardComponent.prototype.clearForm = function () {
        this.editCreditCardForm.reset();
        this.errorMessage = undefined;
    };
    EditCreditCardComponent.prototype.editPaymentMethod = function () {
        // this.serverResponse = false;
        // this.loaderService.showLoader(true);
        this.hideCardInputs = true;
        setTimeout(function () {
            this.loaderService.showLoader(false);
        }, 1000);
        this.closeModal();
    };
    EditCreditCardComponent.prototype.ngOnDestroy = function () {
        // this.cardExpiryInput.removeEventListener('change', event);
        this.cardExpiryInput.destroy();
    };
    Object.defineProperty(EditCreditCardComponent.prototype, "f", {
        get: function () { return this.editCreditCardForm.controls; },
        enumerable: true,
        configurable: true
    });
    return EditCreditCardComponent;
}());
export { EditCreditCardComponent };
