<ng-template #setFavoriteCardModal>
  <div class="modal-body">
      <div class="content">
        
        <div class="text-center">
          <img src="/assets/check-green.svg" width="50" height="50" >
        </div>
        <div class="text-center margin-text">
          <p> Se configuró la tarjeta </p>
        </div>
        <div class="text-center">
          <h3>
            {{ paymentMethod.card.brand | titlecase }} XXXX {{ paymentMethod.card.last4 }}
          </h3>
        </div>
        <div class="text-center mar-top">
          <p> como principal para los cobros mensuales </p>
          </div>
      </div>
      <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
        <div >
          <div class="btn btn-pana-gradient-dark w-100 mx-3" (click)="closeModal()">
            <div>
              CANCELAR
            </div>
          </div>
          <button type="submit" class="btn btn-pana-gradient w-100 mx-3" (click)="setFavorite(paymentMethod)">
            ACEPTAR
          </button>
        </div>
      </div>
  </div>
</ng-template>
<img class="clickable" src="/assets/Star.svg" height="20" width="20"  matTooltip="Principal"
  matTooltipPosition="above" (click)="openModal(setFavoriteCardModal)"/>