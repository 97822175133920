import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';

const HOME = '/service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() navigationDisabled: boolean;
  @Input() showToggleSidebarButton: boolean;
  @Input() sidebarIsExpanded: boolean;
  @Output() sidebarIsExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public user: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  getUserInitials(): string {
    const userProfile = this.user.getProfile();
    const firstName = userProfile.first_name || '';
    const lastName = userProfile.last_name || '';
    const initials = firstName.substring(0, 1) + lastName.substring(0, 1);
    return initials ? initials.toUpperCase() : 'Yo';
  }

  getUserFullName(): string {
    const userProfile = this.user.getProfile();
    const firstName = userProfile.first_name || '';
    const lastName = userProfile.last_name || '';
    return firstName + ' ' + lastName;
  }

  logout(): void {
    this.user.logout();
    this.router.navigate(['/login']);
  }

  toggleSidebar(): void {
    this.sidebarIsExpanded = !this.sidebarIsExpanded;
    this.sidebarIsExpandedChange.emit(this.sidebarIsExpanded);
  }

  hideSidebar(): void {
    this.sidebarIsExpanded = false;
    this.sidebarIsExpandedChange.emit(this.sidebarIsExpanded);
  }

  isActiveProfileView(): boolean {
    return this.router.url.includes('/account');
  }

  showUserButton(): boolean {
    // Debemos asegurarnos que el usuario esté loggeado y que la petición para
    // obtener su perfil se haya completado.
    return this.user.isLogged() && this.user.getProfile() !== undefined;
  }

  showBackButton(): boolean {
    // It only displays when in register section
    return this.router.url.includes('/register');
  }

  goHome(): void {
    if (this.user.isLogged()) {
      this.router.navigate([HOME]);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
