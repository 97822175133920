import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';
import { UserStatus } from '../constants/user-status.constant';

@Injectable({
  providedIn: 'root'
})
export class FullyRegisteredGuard implements CanActivate {
  constructor(
    private user: UserService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (UserStatus.PREREGISTERED === this.user.getProfile().status) {
      this.router.navigate(['/set-password'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}
