/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./remove-department.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/tooltip";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "./remove-department.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i11 from "../../services/api.service";
import * as i12 from "../../services/loader.service";
var styles_RemoveDepartmentComponent = [i0.styles];
var RenderType_RemoveDepartmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RemoveDepartmentComponent, data: {} });
export { RenderType_RemoveDepartmentComponent as RenderType_RemoveDepartmentComponent };
function View_RemoveDepartmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "d-inline-block align-top mx-sm-2"], ["height", "40"], ["src", "/assets/error-skull.svg"], ["width", "40"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "text-pink mt-1 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
function View_RemoveDepartmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A1Hey! "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Vas a eliminar el \u00E1rea "])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RemoveDepartmentComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "btn btn-pana-gradient-dark w-100 mx-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CANCELAR "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-pana-gradient w-100 mx-3"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeDepartment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" ELIMINAR "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorMessage; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _co.departmentName)); _ck(_v, 9, 0, currVal_0); }); }
export function View_RemoveDepartmentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), (_l()(), i1.ɵand(0, [["deleteDepartmentModal", 2]], null, 0, null, View_RemoveDepartmentComponent_1)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "img", [["class", "clickable"], ["height", "20"], ["matTooltip", "Eliminar"], ["matTooltipPosition", "above"], ["name", "times"], ["size", "lg"], ["src", "/assets/close-light.svg"], ["width", "20"]], null, [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openModal(i1.ɵnov(_v, 1)) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i3.MatTooltip, [i4.Overlay, i1.ElementRef, i5.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i7.AriaDescriber, i7.FocusMonitor, i3.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i8.Directionality], [2, i3.MAT_TOOLTIP_DEFAULT_OPTIONS]], { position: [0, "position"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = "above"; var currVal_1 = "Eliminar"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_RemoveDepartmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-remove-department", [], null, null, null, View_RemoveDepartmentComponent_0, RenderType_RemoveDepartmentComponent)), i1.ɵdid(1, 114688, null, 0, i9.RemoveDepartmentComponent, [i10.NgbModal, i11.ApiService, i12.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemoveDepartmentComponentNgFactory = i1.ɵccf("app-remove-department", i9.RemoveDepartmentComponent, View_RemoveDepartmentComponent_Host_0, { businessId: "businessId", departmentId: "departmentId", departmentName: "departmentName" }, { refresh: "refresh" }, []);
export { RemoveDepartmentComponentNgFactory as RemoveDepartmentComponentNgFactory };
