import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// Angular takes care of swapping the environment file for the correct one.
import { environment } from '../../environments/environment';
import { map, tap, last, catchError } from 'rxjs/operators';
import { isArray } from 'util';
import { LoaderService } from './loader.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
var httpOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    })
};
var ApiService = /** @class */ (function () {
    function ApiService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        /* DO NOT SUBSCRIBE HERE. SUBSCRIBE IN COMPONENTS. IF YOU DON'T KNOW HOW
        *  TO SUBSCRIBE FROM COMPONENTS, THEN FIND A WAY. :)
        */
        this.API_ROOT = environment.apiRoot;
    }
    ApiService.prototype.getEndpointURL = function (endpointURL) {
        return this.API_ROOT + endpointURL;
    };
    /**
     * POST: checks users' registration status
     * @param emailObject - Object with users' email
     */
    ApiService.prototype.userStatus = function (emailObject) {
        var endpointURL = this.getEndpointURL('api/v2/users/status');
        var appToken = 'Bearer ' + localStorage.getItem('appToken');
        var options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': appToken
            })
        };
        return this.http.post(endpointURL, emailObject, options);
    };
    /**
     * POST: performs authentication with the API
     * @param loginModel - LoginModel class (username and password object)
     */
    ApiService.prototype.login = function (loginModel) {
        var loginOptions = {
            'grant_type': 'password',
            'client_id': '2',
            'client_secret': 'muwi4Ic13GQqxiNdwLnbsSrydnf6cWydnHNY74zY'
        };
        var endpointURL = this.getEndpointURL('oauth/token');
        var body = Object.assign(loginModel, loginOptions);
        return this.http.post(endpointURL, body, httpOptions);
    };
    /**
     * POST: performs app authentication with the API
     */
    ApiService.prototype.appAccessToken = function () {
        var appTokenOptions = {
            'grant_type': 'client_credentials',
            'client_id': '2',
            'client_secret': 'muwi4Ic13GQqxiNdwLnbsSrydnf6cWydnHNY74zY'
        };
        var endpointURL = this.getEndpointURL('oauth/token');
        return this.http.post(endpointURL, appTokenOptions, httpOptions);
    };
    ApiService.prototype.getProfile = function () {
        var endpointURL = this.getEndpointURL('api/v3/profile');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * POST: requests the API for a password reset
     * @param recoveryEmail - string (email for reset password steps to be sent)
     */
    ApiService.prototype.forgotPass = function (recoveryEmail) {
        var endpointURL = this.getEndpointURL('api/v2/reset_password');
        var appToken = 'Bearer ' + localStorage.getItem('appToken');
        var options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': appToken
            })
        };
        var body = { 'email': recoveryEmail };
        return this.http.post(endpointURL, body, options);
    };
    /**
     * POST: Sends email with temporary password for preregistered
     * @param preregisteredEmail - string (preregistered user's email)
    */
    ApiService.prototype.sendTemporaryPassword = function (preregisteredEmail) {
        var endpointURL = this.getEndpointURL('api/v2/users/tmp-password/send');
        var appToken = 'Bearer ' + localStorage.getItem('appToken');
        var options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': appToken
            })
        };
        var body = { 'email': preregisteredEmail };
        return this.http.post(endpointURL, body, options);
    };
    /**
     * POST: requests the API for a password reset
     * @param businessEmail - string (email for PANA business contact)
     */
    ApiService.prototype.contactBusiness = function (businessEmail) {
        var endpointURL = this.getEndpointURL('api/business/contact');
        var body = { 'email': businessEmail };
        return this.http.post(endpointURL, body, httpOptions);
    };
    /**
     * POST: performs authentication with the API
     * @param userRegisterBody - Object with new user's necessary data
     */
    ApiService.prototype.register = function (userRegisterBody) {
        var endpointURL = this.getEndpointURL('api/v2/register');
        var appToken = 'Bearer ' + localStorage.getItem('appToken');
        var options = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': appToken
            })
        };
        return this.http.post(endpointURL, userRegisterBody, options);
    };
    /**
     * POST: requests companion service
     * @param serviceRequestObject - Object with a service request data
     */
    ApiService.prototype.requestService = function (serviceRequestObject) {
        var endpointURL = this.getEndpointURL('api/petition/create');
        return this.http.post(endpointURL, serviceRequestObject, httpOptions);
    };
    /**
    * GET: requests for predefined cancellation reasons
    */
    ApiService.prototype.cancellationReasons = function () {
        var endpointURL = this.getEndpointURL('api/reasons');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * POST: cancels companion service
     * @param serviceCancellationObject - Object with a service cancellation data
     */
    ApiService.prototype.cancelService = function (serviceCancellationObject, requestId) {
        var endpointURL = this.getEndpointURL('api/petition/' + requestId + '/cancel');
        return this.http.post(endpointURL, serviceCancellationObject, httpOptions);
    };
    /**
    * GET: requests for an active service request (if exists)
    */
    ApiService.prototype.getActiveRequest = function () {
        var endpointURL = this.getEndpointURL('api/petition/active');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
    * GET: requests data for all members in a team
    * @param teamId - Team's id
    */
    ApiService.prototype.getTeamMembers = function (teamId) {
        var endpointURL = this.getEndpointURL('api/business/' + teamId + '/users');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.prototype.getDependentMembers = function () {
        var endpointURL = this.getEndpointURL('api/third-party/users');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
   * POST: requests companion service for an employee
   * @param serviceRequestObject - Object with a service request data
   */
    ApiService.prototype.createDependentRequest = function (serviceRequestObject) {
        var endpointURL = this.getEndpointURL('api/third-party/petition/create');
        return this.http.post(endpointURL, serviceRequestObject, httpOptions);
    };
    /**
     * POST: cancels companion service
     * @param serviceCancellationObject - Object with a service cancellation data
     */
    ApiService.prototype.cancelDependentRequest = function (serviceCancellationObject, requestId) {
        var endpointURL = this.getEndpointURL('api/third-party/petition/' + requestId + '/cancel');
        return this.http.post(endpointURL, serviceCancellationObject, httpOptions);
    };
    /**
    * GET: requests for active service requests (if they exist) related to the user
    */
    ApiService.prototype.getDependentActiveRequest = function () {
        var endpointURL = this.getEndpointURL('api/third-party/petitions/active');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * PATCH: cancels companion service
     * @param passwordChangeObject - Object with password change data
     */
    ApiService.prototype.changePassword = function (passwordChangeObject) {
        var endpointURL = this.getEndpointURL('api/v2/profile/password');
        return this.http.patch(endpointURL, passwordChangeObject, httpOptions);
    };
    /**
     * GET: gets all roles for an corporative user business id
     */
    ApiService.prototype.getBusinessRoles = function () {
        var endpointURL = this.getEndpointURL('api/business/roles');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * GET: gets all departaments for an specific business id
     * @param businessId - Business id
     */
    ApiService.prototype.getBusinessDepartments = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * POST: creates a new department for a given business
     * @param businessId - number (business id)
     * @param departmentName - string (department's name)
     */
    ApiService.prototype.createDepartment = function (businessId, departmentName) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/create');
        var body = { 'name': departmentName };
        return this.http.post(endpointURL, body, httpOptions);
    };
    /**
     * PUT: creates a new department for a given business
     * @param businessId - number (business id)
     * @param departmentId - number (department id)
     * @param departmentName - string (department's new name)
     */
    ApiService.prototype.modifyDepartment = function (businessId, departmentId, departmentName) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/' + departmentId);
        var body = { 'name': departmentName };
        return this.http.put(endpointURL, body, httpOptions);
    };
    /**
     * DELETE: creates a new department for a given business
     * @param businessId - number (business id)
     * @param departmentId - number (department id)
     */
    ApiService.prototype.removeDepartment = function (businessId, departmentId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/departments/' + departmentId);
        return this.http.delete(endpointURL, httpOptions);
    };
    /**
     * POST: Validate if user data is OK for business register
     * @param businessId - number (business id)
     * @param requestBody - Object (Contains new business user necessary data)
     */
    ApiService.prototype.validateBusinessRegister = function (businessId, requestBody) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/validate');
        return this.http.post(endpointURL, requestBody, httpOptions);
    };
    /**
     * POST: Validate if user data is OK for mass business register
     * @param businessId - number (business id)
     * @param requestBody - Object (Contains new business user necessary data)
     */
    ApiService.prototype.validateMassBusinessRegister = function (businessId, requestBody) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/mass/validate');
        return this.http.post(endpointURL, requestBody);
    };
    /**
     * POST: Register new user in business team
     * @param businessId - number (business id)
     * @param requestBody - Object (Contains new business user necessary data)
     */
    ApiService.prototype.businessRegister = function (businessId, requestBody) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users');
        return this.http.post(endpointURL, requestBody, httpOptions);
    };
    /**
    * PATCH: unsubscribe a user from its PANA business subscription
    * @param businessId: business' id
    */
    ApiService.prototype.editBusinessUser = function (businessId, userId, requestBody) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/' + userId);
        return this.http.patch(endpointURL, requestBody, httpOptions);
    };
    /**
    * PATCH: unsubscribe a user from its PANA business subscription
    * @param businessId: business' id
    */
    ApiService.prototype.editProfile = function (requestBody) {
        var endpointURL = this.getEndpointURL('api/v2/profile');
        return this.http.put(endpointURL, requestBody, httpOptions);
    };
    /**
     * PATCH: unsubscribe a user from its PANA business subscription
     * @param businessId: business' id
     * @param userId: user's id
     */
    ApiService.prototype.unsubscribeBusinessUser = function (businessId, userId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/unsubscribe/' + userId);
        return this.http.patch(endpointURL, httpOptions);
    };
    /**
     * GET: gets all companies subscribed to PANA
     */
    ApiService.prototype.getCompanies = function () {
        var endpointURL = this.getEndpointURL('api/business/companies');
        return this.http.get(endpointURL, httpOptions);
    };
    /**
     * GET: gets stream of csv file with corporative users
     * @param businessId - number (business id)
     */
    ApiService.prototype.downloadCorpUSersFile = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/download');
        return this.http.get(endpointURL, { responseType: 'blob' });
    };
    /**
     * GET: gets stream of xlsx file with mass load format sample
     */
    ApiService.prototype.downloadMassLoadSample = function () {
        var _this = this;
        var endpointURL = this.getEndpointURL('api/business/files/samples/bulk-load');
        var req = new HttpRequest('GET', endpointURL, {
            reportProgress: true,
            responseType: 'blob'
        });
        return this.http.request(req).pipe(map(function (event) { return _this.getEventMessage(event); }), tap(function (message) {
            _this.showProgress(message);
        }), last(), // return last (completed) message to caller
        catchError(this.handleError()));
    };
    /**
     * GET: Gets stream of pdf file with the details of the given invoice of the given
     * business
     *
     * @param businessId Business' id
     * @param invoiceId Invoice's id
     * @param handlers Object with callbacks to handle http download progress events
     *                 and reports
     */
    ApiService.prototype.downloadBusinessInvoiceDetailFile = function (businessId, invoiceId, handlers) {
        var _this = this;
        var endpointURL = this.getEndpointURL("api/business/" + businessId + "/invoices/" + invoiceId + "/pdf");
        var req = new HttpRequest('GET', endpointURL, {
            reportProgress: true,
            responseType: 'blob'
        });
        return this.http.request(req).pipe(map(function (event) { return handlers.downloadEvent(event); }), tap(function (report) {
            handlers.reportMessage(report, _this.loaderService);
        }), last(), catchError(function (response) {
            return throwError(response);
        }));
    };
    /**
     * GET: Gets stream of pdf file with the details of the given invoice of the given
     * business
     *
     * @param businessId Business' id
     * @param invoiceId Invoice's id
     * @param handlers Object with callbacks to handle http download progress events
     *                 and reports
     */
    ApiService.prototype.downloadCurrentBusinessInvoiceSummaryFile = function (businessId, handlers) {
        var _this = this;
        var endpointURL = this.getEndpointURL("api/business/" + businessId + "/invoices/current-summary/pdf");
        var req = new HttpRequest('GET', endpointURL, {
            reportProgress: true,
            responseType: 'blob'
        });
        return this.http.request(req).pipe(map(function (event) { return handlers.downloadEvent(event); }), tap(function (report) {
            handlers.reportMessage(report, _this.loaderService);
        }), last(), catchError(function (response) {
            return throwError(response);
        }));
    };
    // TODO: Move auxiliar functions to the bottom of the file.
    // TODO: Verify if the function is necessary. If it is, document the function
    // and set the function as private
    ApiService.prototype.handleError = function () {
        return;
    };
    // TODO: Verify if the function can be reached outside the class. If it cannot
    // be reached, set the function as private
    ApiService.prototype.showProgress = function (message) {
        if (isArray(message)) {
            this.loaderService.showLoaderProgressbar(true, message[0], message[1]);
        }
    };
    // TODO: Change function's name to be more mnemonic
    ApiService.prototype.getEventMessage = function (event) {
        switch (event.type) {
            case HttpEventType.Sent:
                return ["Descargando archivo...", 0];
            case HttpEventType.DownloadProgress:
                // Compute and show the % done:
                var percentDone = Math.round(100 * event.loaded / event.total);
                return ["Progreso de la descarga: " + percentDone + "%", percentDone];
            case HttpEventType.Response:
                this.loaderService.showLoaderProgressbar(false, 'Done!', 100);
                return event.body;
            default:
                return "File surprising upload event: " + event.type + ".";
        }
    };
    /**
     * POST: performs massive subscription for a business team
     * @param businessId: business' id
     * @param validUsers: array of valid users
     */
    ApiService.prototype.massSubscription = function (businessId, validUsers) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/mass/create');
        return this.http.post(endpointURL, validUsers, httpOptions);
    };
    /**
     * POST: generates xlsx file with invalid users after mass subscription
     * @param invalidUsers: array of invalid users
     */
    ApiService.prototype.downloadInvalidXLSX = function (invalidUsers) {
        // TODO: Changes endpoint uri to eliminate the word csv
        var endpointURL = this.getEndpointURL('api/business/users/build-csv');
        return this.http.post(endpointURL, invalidUsers, { responseType: 'blob' });
    };
    /**
     * GET: Gets the business invoices history of the given business
     *
     * @param businessId Business' id
     */
    ApiService.prototype.getInvoicesHistory = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/invoices');
        return this.http.get(endpointURL, httpOptions)
            .pipe(map(function (response) {
            // TODO: Create interface for invoices
            return response.data;
        }), catchError(function (response) {
            return throwError(response);
        }));
    };
    /**
     * GET: Gets the billing resume of the current cycle of the given business
     *
     * @param businessId Business' id
     */
    ApiService.prototype.getCurrentInvoiceSummary = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/invoices/current-summary');
        return this.http.get(endpointURL, httpOptions)
            .pipe(map(function (response) {
            return response.data;
        }), catchError(function (response) {
            return throwError(response);
        }));
    };
    ApiService.prototype.getInvoices = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
        return this.http.get(endpointURL, httpOptions)
            .pipe(map(function (response) {
            return response.data;
        }), catchError(function (response) {
            return throwError(response);
        }));
    };
    ApiService.prototype.getBusinessPaymentMethods = function (businessId) {
        // const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
        return new Observable(function (observer) {
            observer.next([]);
        });
        // return this.http.get<any>(endpointURL, httpOptions)
        // .pipe(
        //   map(response => {
        //     return response.data;
        //   }),
        //   catchError(response => {
        //     return throwError(response);
        //   })
        // );
    };
    ApiService.prototype.updateBusinessPaymentMethods = function (businessId, paymentMethod, requestBody) {
        // const endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoices');
        return new Observable(function (observer) {
            paymentMethod.billing_details.name = requestBody.name;
            observer.next([]);
        });
        // return this.http.get<any>(endpointURL, httpOptions)
        // .pipe(
        //   map(response => {
        //     return response.data;
        //   }),
        //   catchError(response => {
        //     return throwError(response);
        //   })
        // );
    };
    ApiService.prototype.getInvoicesAdmin = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getInvoicesForAdmin');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.prototype.getRenovations = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getRenovation');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.prototype.getSubscribe = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getSubscribe');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.prototype.getUnsuscribe = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getUnsubscribe');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.prototype.getTotalCurrentMonth = function (businessId) {
        var endpointURL = this.getEndpointURL('api/business/' + businessId + '/users/getTotalCurrentMonth');
        return this.http.get(endpointURL, httpOptions);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.LoaderService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
