import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isFormInvalid = form && form.invalid;
    const isControlInvalid = control && control.invalid;
    const isControlTouched = control && control.touched;
    return !!((isFormInvalid || isControlInvalid) && isControlTouched);
  }
}
