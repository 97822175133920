import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-business-dropdown',
  templateUrl: './business-dropdown.component.html',
  styleUrls: ['./business-dropdown.component.css']
})
export class BusinessDropdownComponent implements OnInit {
  @Input() company: any;
  @Output() companyChange: EventEmitter<any> = new EventEmitter<any>();

  criteria = '';
  showDropDown: boolean;
  companies: any[];
  serverError: boolean;

  constructor(
    private api: ApiService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getCompanies();
    });
  }

  showCompany(company: any) {
    const filterValue = this.criteria.toLowerCase();
    const includesName = company.name.toLowerCase().includes(filterValue);
    const includesDocument = company.document && company.document.includes(filterValue);
    return includesDocument || includesName;
  }

  showDropdown(): void {
    this.showDropDown = true;
  }

  toogleDropDown(): void {
    this.showDropDown = !this.showDropDown;
  }

  selectCompany(company: any): void {
    this.showDropDown = false;
    this.company = company;
    this.companyChange.emit(this.company);
  }

  noMatchResults(): boolean {
    return this.companies && !this.companies.some((employee) => {
      return this.showCompany(employee);
    });
  }

  private getCompanies(): void {
    this.loaderService.showLoader(true);
    this.api.getCompanies().subscribe(
      data => {
        this.companies = data.companies;
        this.loaderService.showLoader(false);
      },
      error => {
        this.loaderService.showLoader(false);
        this.serverError = true;
      }
    );
  }

}
