export class UserRegistrationModel {
  firstName = '';
  lastName = '';
  email = '';
  documentType = 'V';
  document = '';
  callCode = '+58';
  phone = '';
  password = '';
  password2 = '';
}
