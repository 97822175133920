import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
 
import { UserService } from '../services/user.service';
 
@Injectable()

/* Intercepts every HTTP response. If server response is 404,
 * this interceptor performs logout and reloads the current section, which
 * triggers redirection to login (if section has a auth-guard)
 * @class ErrorInterceptor
 */
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        let isLoginState = this.router.url.includes('/login');
        if (err.status === 401 && !isLoginState) {
          // Auto logout if 401 response returned from api
          this.userService.logout();
          location.reload(true);
        }

        return throwError(err);
      })
    );
  }
}