<ng-container *ngIf="!success">
  <div class="content">
    <div class="text-center mb-2">
      <h2>{{ title || '¡Hola!' }}</h2>
    </div>
    <div class="text-center mb-3">
      <p>Si estás interesado en PANA para tu empresa, déjanos tu email para contactarte</p>
    </div>
    <div class="w-100 text-center">
      <form>
        <mat-form-field class="centered w-100">
          <input matInput appAutofocus type="text" id="businessEmail" [pattern]="emailRegex" [(ngModel)]="businessEmail" placeholder="Email" [ngModelOptions]="{standalone: true}">
          <mat-error *ngIf="errorMessage">
            <div>
              {{ errorMessage }}
            </div>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="serverError">
      <img src="/assets/error-skull.svg" width="40" height="40" class="d-inline-block align-top mx-sm-2" alt="">
      <p class="text-pink mt-1 text-center">
        {{ serverError }}
      </p>
    </div>
  </div>
  <div class="w-100 d-flex flex-column-reverse align-items-center d-sm-block text-sm-center justify-content-center">
    <div
      class="btn btn-pana-gradient-dark w-100 mx-3"
      (click)="cancel()"
      *ngIf="!successMessage"
    >
      <div>
        CANCELAR
      </div>
    </div>
    <button
      class="btn btn-pana-gradient w-100 mx-3"
      (click)="sendRequest()"
      *ngIf="!successMessage"
    >
      ACEPTAR
    </button>
  </div>
</ng-container>
<ng-container *ngIf="success">
  <div class="content">
    <div class="text-center mb-2">
      <h2>¡Gracias!</h2>
    </div>
    <div class="text-center">
      <p>Pronto sabrás más de nosotros</p>
    </div>
  </div>
  <div class="text-center">
    <button type="button" class="btn btn-pana-gradient w-100" (click)="ok()">
      ACEPTAR
    </button>
  </div>
</ng-container>
