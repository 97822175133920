import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { LoaderService } from '../../services/loader.service';
import { NavigationService } from '../../services/navigation.service';
import { UserRegistrationModel } from '../../models/user-registration.model';

import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.css']
})
export class UserRegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  idTypes: string[];
  callCodes: Object[];
  errorMessage: string;
  existingEmail: boolean;
  existingDocument: boolean;
  emailFromLogin: boolean;
  emailFromLoginStr: string;
  matcher = new CustomErrorStateMatcher();

  constructor(
    public globalData: GlobalDataService,
    private api: ApiService,
    private user: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private loaderService: LoaderService,
    private navigationService: NavigationService
  ) { }

  /**
   * Form's controls getter
   * @returns { [key: string]: AbstractControl; } - Form's controls hash
   */
  get f() { return this.registrationForm.controls; }

  ngOnInit() {
    /**
     * Form creation and class variables initialization
     */
    this.idTypes = ['V', 'E', 'P'];
    this.existingEmail = false;
    this.existingDocument = false;
    this.registrationForm = this.formBuilder.group(
      new UserRegistrationModel,
      {
        validator: [
          this.mismatchingPasswords,
          this.invalidIdentification,
          this.invalidPhone
        ]
      }
    );
    this.setFormControlsValidators();
    
    this.cleanErrors();

    /**
     * Checks whether section is activated via Login Section or not.
     * If so, patches email value to registration form instance and shows
     * the appropriate message.
     */
    this.emailFromLogin = false;
    if (this.navigationService.params) {
      if (this.navigationService.params.unregisteredEmail) {
        this.emailFromLoginStr = this.navigationService.params.unregisteredEmail;
        this.registrationForm.patchValue({
          email: this.navigationService.params.unregisteredEmail
        });
        this.navigationService.params = undefined;
        this.registrationForm.updateValueAndValidity();
        this.emailFromLogin = true;
      }
    }
  }

  /**
   * Triggers chain of PANA's API request to perform registration
   */
  onSubmit() {
    this.cleanErrors();
    /**
     * Performs registration request if appToken exists in local storage.
     * Performs app credentials request otherwise.
     */
    if (localStorage.getItem('appToken')) {
      this.requestRegister();
    } else {
      this.requestAppCredentials();
    }
  }

  /**
   * Enables email field in registration form
   */
  enableEmailField() {
    this.registrationForm.get('email').enable();
    this.emailFromLogin = false;
  }

  /**
   * Class' form validator: checks if both new password and its confirmation
   * are the same.
   * @param registrationForm - Registration form instance
   * @returns Object - null if passwords match, 'missmatching-pass' error otherwise.
   */
  private mismatchingPasswords(registrationForm: FormGroup) {
    const pass1 = registrationForm.controls.password.value;
    const pass2 = registrationForm.controls.password2.value;

    return pass1 === pass2 ? null : { 'missmatching-pass': true };
  }

  /**
   * Class' form validator: checks wheter a given venezuelan ID number
   * is valid or not
   * @param registrationForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidIdentification(registrationForm: FormGroup) {
    const idCardRegex = /^[0-9]{7,9}$/;
    const pasRegex = /^[a-zA-Z0-9]{5,10}$/;
    let documentValue: string;
    documentValue = registrationForm.controls.document.value;
    let isValid: boolean;
    switch (registrationForm.controls.documentType.value) {
      case 'V':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'E':
        isValid = idCardRegex.test(documentValue);
        break;
      case 'P':
        isValid = pasRegex.test(documentValue);
        break;
      default:
        isValid = false;
        break;
    }
    return isValid ? null : { 'invalid-identification': true };
  }

  /**
   * Class' form validator: checks wheter a given venezuelan phone number
   * is valid or not
   * @param registrationForm - Registration form instance
   * @returns Object - null if ID is valid, 'invalid-identification' error otherwise.
   */
  private invalidPhone(registrationForm: FormGroup) {
    const vzlaPhoneRegex = /^4(1[246]|2[46])[0-9]{7}$/;
    const vzlanPhone = registrationForm.controls.callCode.value === '+58';
    if (vzlanPhone && !vzlaPhoneRegex.test(registrationForm.controls.phone.value)) {
      return { 'invalid-phone': true };
    }
  }

  /**
   * Sets form controls validator functions
   */
  private setFormControlsValidators() {
    const nameRegex = /^[áéíóúÁÉÍÓÚñÑa-zA-Z,\.'\-\ ]+$/;
    // tslint:disable-next-line:max-line-length
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const callCodeRegex = /^\+[0-9]{1,4}$/;
    const phoneRegex = /^[0-9]{10}$/;

    Object.keys(this.registrationForm.controls).forEach(key => {
      /* We mark all form fields as required since all of them are required :) */
      const validators = [Validators.required];
      if (key === 'email') {
        validators.push(Validators.pattern(emailRegex));
      } else if (key === 'firstName' || key === 'lastName') {
        validators.push(Validators.pattern(nameRegex));
      } else if (key === 'phone') {
        validators.push(Validators.pattern(phoneRegex));
      } else if (key === 'callCode') {
        validators.push(Validators.pattern(callCodeRegex));
      } else if (key === 'password') {
        validators.push(Validators.minLength(8));
      }

      this.registrationForm.get(key).setValidators(validators);
    });
  }

  /**
   * Performs app credentials request to PANA's API.
   * Sets the app token and performs registration request when success.
   */
  private requestAppCredentials(): void {
    this.loaderService.showLoader(true);
    this.api.appAccessToken()
      .subscribe(
        data => {
          const appToken = data.access_token;
          this.user.setAppToken(appToken);
          this.requestRegister();
        },
        error => {
          this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );
  }

  /**
   * Creates request body object from registrarion form instance
   * @returns requestBody - Object with the required data to user registration request
   */
  private getRequestBody(): Object {
    const result: UserRegistrationModel = Object.assign({}, this.registrationForm.value);
    const requestBody: Object = {
      first_name: result.firstName,
      last_name: result.lastName,
      call_code: result.callCode,
      phone: result.phone,
      email: result.email,
      type_document: result.documentType,
      document: result.document,
      password: result.password
    };

    return requestBody;
  }

  /**
   * Performs user registration request to PANA's API.
   * Redirects to Login Section if succeeds, shows the appropriate
   * error message otherwise.
   */
  private requestRegister(): void {
    const requestBody = this.getRequestBody();
    this.api.register(requestBody)
      .subscribe(
        data => {
          this.router.navigate(['/login'], { queryParams: { fromRegister: 'true' } });
          this.loaderService.showLoader(false);
        },
        error => {
          if (error.status === 422) {
            console.log(error);
            const messageObject = error.error.errors;
            this.existingDocument = messageObject.hasOwnProperty('document');
            this.existingEmail = messageObject.hasOwnProperty('email');
          } else {
            this.errorMessage = StringsConstant.UNKNOWN_ERROR;
          }
          this.loaderService.showLoader(false);
        }
      );
  }

  /**
   * Makes sure to hide any error message that could be shown to the user.
   */
  private cleanErrors(): void {
    this.errorMessage = undefined;
    this.existingEmail = false;
    this.existingDocument = false;
  }
}
