<div class="d-flex flex-column position-absolute w-100 h-100 vertical-scroll grid">
  <!-- Section title -->
  <div class="pl-3 pl-md-5 my-4">
    <h3>Pagos y Recibos</h3>
  </div>

  <!-- Business team selection for superadmins -->
  <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center main-section" *ngIf="!businessTeam && isSuperAdminView">
    <div class="w-100 text-center">
      <h4 class="text-gray">Selecciona la empresa:</h4>
    </div>
    <div class="row pt-4 m-0 w-100">
      <div class="col-sm-6 offset-sm-3 col-10 offset-1 p-0">
        <app-business-dropdown [company]="businessTeam" (companyChange)="onCompanyUpdated($event)"></app-business-dropdown>
      </div>
    </div>
  </div>

  <!-- Card Table -->
  <div class="d-flex flex-column  justify-content-center align-items-center" *ngIf="businessTeam && paymentMethods.length === 0 && currentInvoiceSummary ">
    <p class="m-0">¿Desea agregar una tarjeta de crédito para domiciliar sus pagos?</p>
    <app-add-credit-card [businessId]="businessTeam.id" [(paymentMethods)]="paymentMethods"></app-add-credit-card>
  </div>

  <div class="w-100 justify-content-between align-items-center mb-4 px-3 px-md-5 " *ngIf="businessTeam  && paymentMethods.length > 0">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4 class="font-light mar-bot">Método de pago</h4>
        <p class="text-gray mar-bot"> Tarjetas internacionales de crédito / débito </p>
      </div>
      <app-add-credit-card [businessId]="businessTeam.id" [(paymentMethods)]="paymentMethods"></app-add-credit-card>
    </div>
  </div>
  <div class="col grid d-flex flex-column w-100 align-items-center align-self-center w-100 px-3 px-md-5 " style="min-height: auto" *ngIf="businessTeam && paymentMethods.length > 0">
    <div class="w-100 table-responsive" >
      <table class="table table-bordered table-hover" >
        <thead class="thead-dark">
          <tr>
            <th class="txt-left w-80">Tarjeta</th>
            <th class="text-center w-20">Acciones</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <ng-container *ngFor="let paymentMethod of paymentMethods; let i = index">
            <tr>
              <td class="text-left px-5">
                <div class="row">
                  <div class="text-white font-weight-bold col-3">
                    {{ paymentMethod.billing_details.name }} XXXX-{{ paymentMethod.card.last4 }}
                  </div>
                  <div class="col-9">
                    <small class="text-green" *ngIf="paymentMethod.metadata.default">
                      Principal
                    </small>
                  </div>
                </div>
                <small>
                  Expira {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}
                </small>
              </td>
              <td>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="d-block position-relative default-pm-checkbox">
                    <input type="radio" name="starcheck" id="stars1" >
                    <app-set-favorite-card  [(paymentMethods)]="paymentMethods"  [paymentMethod]="paymentMethod" ></app-set-favorite-card>
                    <!-- <span for="stars1" matTooltip="Principal" matTooltipPosition="above" (click)="setFavorite(paymentMethod)">
                      <img class="clickable" src="/assets/Star.svg" height="20" width="20" />
                    </span> -->
                  </div>
                  <app-edit-credit-card [businessId]="businessTeam.id" [paymentMethod]="paymentMethod"></app-edit-credit-card>
                  <img class="clickable" src="/assets/Cross-light.svg" height="20" width="20"  matTooltip="Eliminar"
                    matTooltipPosition="above" />
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <!-- Resumen -->
  <div class="px-3 px-md-5 pb-4"  *ngIf="businessTeam && currentInvoiceSummary">
    <div class="w-100 my-4">
      <div>
        <h4 class="font-light m-0 text-capitalize">
          Resumen - {{ getCurrentInvoicePeriodBeginDate() | date:"MMMM y" }}
        </h4>
        <p class="text-gray m-0">
          {{ getCurrentInvoicePeriodBeginDate() | date:"d MMM yy" | replace:'.':'' | lowercase }} a 
          {{ getCurrentInvoicePeriodEndDate() | date:"d MMM yy" | replace:'.':'' | lowercase }}
        </p>
      </div>
      <!-- <div class="text-right">
        <img class="clickable" src="/assets/Preview.svg" height="25" width="25" (click)="showCurrentInvoiceSummary()" matTooltip="Ver detalles"
        matTooltipPosition="above" />
        <img class="clickable ml-3" src="/assets/Download.svg" height="25" width="25" (click)="downloadCurrentInvoiceSummary()" matTooltip="Descargar detalle"
        matTooltipPosition="above" />
      </div> -->
    </div>
    <div class="d-flex pt-5 pl-2 pb-4 pana-card align-content-between" style="font-size:115%">
      <div class="col">
        <h6 class="text-gray mb-2">Nuevos Ingresos</h6>
        <ng-container *ngFor="let service of currentInvoiceSummary.newSubscriptions | keyvalue">
          <h6 class="mb-2">
            {{ service.value.length }}
            <ng-container 
              *ngIf="Object.keys(currentInvoiceSummary.newSubscriptions).length !== 1 ||
                    service.key !== ServiceNames.BASIC"
            >
              {{ service.key }}
            </ng-container>
          </h6>
        </ng-container>
        <ng-container *ngIf="Object.keys(currentInvoiceSummary.newSubscriptions).length === 0">
          <h6 class="mb-2">
            Ninguno
          </h6>
        </ng-container>
      </div>
      <div class="col">
        <h6 class="text-gray mb-2">Suscripciones Activas</h6>
        <ng-container *ngFor="let service of currentInvoiceSummary.renovations | keyvalue">
          <h6 class="mb-2">
            {{ service.value.length }}
            <ng-container 
              *ngIf="Object.keys(currentInvoiceSummary.renovations).length !== 1 ||
                    service.key !== ServiceNames.BASIC"
            >
              {{ service.key }}
            </ng-container>
          </h6>
        </ng-container>
        <ng-container *ngIf="Object.keys(currentInvoiceSummary.renovations).length === 0">
          <h6 class="mb-2">
            Ninguna
          </h6>
        </ng-container>
        <br/>
        <h6 class="text-gray mb-2">Total (al día de hoy)</h6>
        <h6 class="mb-2">{{ currentInvoiceSummary.todayTotalToBill | currency:'USD':'':'.2-2' }} USD</h6>
      </div>
      <div class="col">
        <h6 class="text-gray mb-2">Bajas</h6>
        <ng-container *ngFor="let service of currentInvoiceSummary.unsubscriptions | keyvalue">
          <h6 class="mb-2">
            {{ service.value.length }}
            <ng-container 
              *ngIf="Object.keys(currentInvoiceSummary.unsubscriptions).length !== 1 ||
                    service.key !== ServiceNames.BASIC"
            >
              {{ service.key }}
            </ng-container>
          </h6>
        </ng-container>
        <ng-container *ngIf="Object.keys(currentInvoiceSummary.unsubscriptions).length === 0">
          <h6 class="mb-2">
            Ninguna
          </h6>
        </ng-container>
        <br/>
        <h6 class="text-gray mb-2">Total (estimado a fin de mes)</h6>
        <h6 class="mb-2">{{ currentInvoiceSummary.predictedTotalToBill | currency:'USD':'':'.2-2' }} USD</h6>
      </div>
    </div>
  </div>

  <!-- Invoices sub-section title -->
  <div class="w-100 d-flex justify-content-between align-items-center my-4 px-3 px-md-5 " *ngIf="businessTeam && currentInvoiceSummary">
    <h4 class="font-light h-100">Histórico de recibos</h4>
  </div>
  <div class="grid d-flex flex-column w-100 align-items-center align-self-center w-100 px-3 px-md-5" style="min-height: auto" *ngIf="businessTeam && currentInvoiceSummary">
    <div class="w-100 table-responsive" >
      <table class="table table-bordered table-hover text-center" >
        <thead class="thead-dark">
          <tr>
            <th>Período</th>  
            <th>Monto total</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody class="tbody-dark">
          <ng-container *ngIf="!invoices">
            <tr *ngIf="invoices == -1">
              <th colspan="9">No hay ningún recibo que coincida con su búsqueda</th>
            </tr>
          </ng-container>
          <ng-container *ngIf="invoices">
            <ng-container *ngFor="let invoice of invoices; let i = index">
              <tr>
                <td class="text-capitalize">{{ getInvoicePeriodDate(invoice) | date:"MMMM y" }}</td>
                <td>{{ invoice.total | currency:'USD':'':'.2-2' }} USD</td>
                <td>{{ invoice.status | stringMap:InvoiceStatusMapping.KNOWN_BY_USERS }}</td>
                <td>
                  <img class="clickable mr-2" src="/assets/Preview.svg" height="20" width="20" matTooltip="Vista Previa" matTooltipPosition="above" (click)="showInvoice(invoice)" />
                  <img class="clickable ml-2" src="/assets/Download.svg" height="20" width="20" matTooltip="Descargar PDF" matTooltipPosition="above" (click)="downloadInvoice(invoice)"/>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
