import { Validators } from '@angular/forms';

export class UserEdit {
  firstName = '';
  lastName = '';
  documentType = 'V';
  document = '';
  email = '';
  callCode = '+58';
  phone = '';
  role = '';
  department = '';

  getValidators(field: string) {
    const phoneRegex = /^[0-9]{10}$/;
    const validators = [];
    const callCodeRegex = /^\+[0-9]{1,4}$/;
    const nameRegex = /^[áéíóúÁÉÍÓÚñÑa-zA-Z,\.'\-\ ]+$/;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (field !== 'department') {
      validators.push(Validators.required);
    }
    if (field === 'firstName' || field === 'lastName') {
      validators.push(Validators.pattern(nameRegex));
    }
    if (field === 'phone') {
      validators.push(Validators.pattern(phoneRegex));
    }
    if (field === 'callCode') {
      validators.push(Validators.pattern(callCodeRegex));
    }
    if (field === 'email') {
      validators.push(Validators.pattern(emailRegex));
    }

    return validators;
  }
}
