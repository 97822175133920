import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/api.service';
import { UserService } from '../../services/user.service';
import { LoaderService } from '../../services/loader.service';
import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  modalRef: NgbModalRef;
  errorMessage: string;
  serverError: string;
  successMessage: string;
  recoveryEmail: string;
  sending: boolean;
  emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private modalService: NgbModal,
    private api: ApiService,
    private user: UserService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.sending = false;
  }

  private clearEmailField(): void {
    this.recoveryEmail = '';
    this.errorMessage = undefined;
  }

  private requestAppCredentials(): void {
    this.loaderService.showLoader(true);
    this.api.appAccessToken()
      .subscribe(
        data => {
          const appToken = data.access_token;
          this.user.setAppToken(appToken);
          this.requestRecovery();
        },
        error => {
          this.serverError = StringsConstant.UNKNOWN_ERROR;
          this.loaderService.showLoader(false);
        }
      );
  }

  private requestRecovery(): void {
    this.api.forgotPass(this.recoveryEmail)
      .subscribe(
        data => {
          this.successMessage = StringsConstant.RESET_PASS;
          this.sending = false;
          this.loaderService.showLoader(false);
        },
        error => {
          if (error.status === 404) {
            this.serverError = StringsConstant.UNKNOWN_EMAIL;
          } else {
            this.serverError = StringsConstant.UNKNOWN_ERROR;
          }
          this.sending = false;
          this.loaderService.showLoader(false);
        }
      );
  }

  openModal(forgotPassModal) {
    this.modalRef = this.modalService.open(
      forgotPassModal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      }
    );
  }

  sendRequest() {
    this.errorMessage = undefined;
    if (this.emailRegex.test(this.recoveryEmail)) {
      this.sending = true;
      this.requestAppCredentials();
    } else {
      this.errorMessage = StringsConstant.INVALID_EMAIL;
    }
  }

  closeModal() {
    this.clearEmailField();
    this.modalRef.close();
    if (this.successMessage) {
      location.reload(true);
    }
  }
}
