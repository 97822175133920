import * as i0 from "@angular/core";
var mobileLimit = 768;
var smBreak = 578;
var mdBreak = 768;
var lgBreak = 992;
var xlBreak = 1200;
var btnSmBreak = 544;
var GlobalDataService = /** @class */ (function () {
    function GlobalDataService() {
    }
    /**
     * Returns screen's outer width trough window provider
     * @returns - number: Screen's outer width in pixels
     */
    GlobalDataService.prototype.getWidth = function () {
        return window.outerWidth;
    };
    GlobalDataService.prototype.getInnerWidth = function () {
        return window.innerWidth;
    };
    /**
     * Checks if content should be rendered as a mobile site
     * based purely on screen's width
     * @returns - boolean: whether it's mobile sized or not.
     */
    GlobalDataService.prototype.isMobile = function () {
        return this.getWidth() < mobileLimit;
    };
    GlobalDataService.prototype.isMobileInner = function () {
        return this.getInnerWidth() < mobileLimit;
    };
    GlobalDataService.prototype.ltSm = function () {
        return this.getInnerWidth() < smBreak;
    };
    GlobalDataService.prototype.ltMd = function () {
        return this.getInnerWidth() < mdBreak;
    };
    GlobalDataService.prototype.ltLg = function () {
        return this.getInnerWidth() < lgBreak;
    };
    GlobalDataService.prototype.ltXl = function () {
        return this.getInnerWidth() < xlBreak;
    };
    GlobalDataService.prototype.ltBtnBreak = function () {
        return this.getInnerWidth() < btnSmBreak;
    };
    GlobalDataService.ngInjectableDef = i0.defineInjectable({ factory: function GlobalDataService_Factory() { return new GlobalDataService(); }, token: GlobalDataService, providedIn: "root" });
    return GlobalDataService;
}());
export { GlobalDataService };
