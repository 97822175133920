import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { CustomErrorStateMatcher } from 'src/app/utils/custom-error-state-matcher';
import { NavigationService } from '../../services/navigation.service';
import { LoaderService } from '../../services/loader.service';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { GlobalDataService } from 'src/app/services/global-data.service';
import { UserProfile } from '../../interfaces/user-profile.interface';
import { StringsConstant } from '../../constants/strings.constant';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm: FormGroup
  tempPass: string;
  errorMessage: string;
  success: boolean;
  userProfile: UserProfile;
  matcher = new CustomErrorStateMatcher();

  constructor(
    public globalData: GlobalDataService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private navigationService: NavigationService,
    private loaderService: LoaderService,
    private router: Router,
    private user: UserService
  ) { }

  ngOnInit() {
    const userProfile = this.user.getProfile();
    /* Redirect if user is fully registered */
    if (userProfile.status_label !== 'Pre-Registrado') {
      this.router.navigate(['/service']);
    }
    /* Password setting form creation */
    this.setPasswordForm = this.formBuilder.group({
      oldPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      passwordConfirmation: new FormControl('', [
        Validators.required
      ])
    },
    {
      validator: this.mismatchingPasswords
    }
  );
  /**
   * Checks if request comes directly from login section
   * and gets the temporal password for further use
   */
  if (this.navigationService.params) {
    this.tempPass = this.navigationService.params.tempPass;
  }
  /* Sets oldPassword value if the temporal password exists */
  if (this.tempPass) {
    this.setPasswordForm.patchValue({
        oldPassword: this.tempPass
      });
      this.setPasswordForm.updateValueAndValidity();
    }
    this.cleanMessages();
  }

  /**
   * Form's controls getter
   * @returns { [key: string]: AbstractControl; } - Form's controls hash
   */
  get f() { return this.setPasswordForm.controls; }

  /**
   * Performs password setting request to PANA's API.
   * Requests user's profile data again if succeeds.
   */
  onSubmit() {
    this.cleanMessages();
    const result = Object.assign({}, this.setPasswordForm.value);
    const requestBody = {
      old_password: result.oldPassword,
      password: result.newPassword
    };

    this.loaderService.showLoader(true);
    this.api.changePassword(requestBody).subscribe(
      data => {
        this.setPasswordForm.reset();
        this.success = true;
        this.requestProfile();
      },
      error => {
        if (error.status === 422) {
          this.errorMessage = error.error.errors.old_password;
          this.loaderService.showLoader(false);
        }
      }
    );
  }

  /**
   * Cleans error and success messages
   */
  cleanMessages() {
    this.errorMessage = undefined;
    this.success = false;
  }

  logout(): void {
    this.user.logout();
    this.router.navigate(['/login']);
  }

  /**
   * Class' form validator: checks if both new password and its confirmation
   * are the same.
   * @param setPasswordForm - Password setting form instance
   * @returns Object - null if passwords match, 'missmatching-pass' error otherwise.
   */
  private mismatchingPasswords(setPasswordForm: FormGroup): any {
    const pass1 = setPasswordForm.controls.newPassword.value;
    const pass2 = setPasswordForm.controls.passwordConfirmation.value;

    return pass1 === pass2 ? null : { 'missmatching-pass': true };
  }

  /**
   * Performs profile request to PANA's API.
   * Sets profile when success.
   */
  private requestProfile(): void {
    this.api.getProfile().subscribe(
      data => {
        this.user.setProfile(data);
        this.loaderService.showLoader(false);
      },
      () => {
        this.errorMessage = StringsConstant.UNKNOWN_ERROR;
        this.loaderService.showLoader(false);
      }
    );
  }
}
